var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.$route.meta.istitlehide
        ? _c("div", { staticClass: "navbartitle" }, [
            !_vm.$route.meta.isTitleBack
              ? _c(
                  "div",
                  { staticClass: "navbartitleIcon", on: { click: _vm.back } },
                  [_c("i", { staticClass: "el-icon-arrow-left" })]
                )
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.$route.meta.title))]),
            _c("div"),
          ])
        : _vm._e(),
      _c("div", { staticClass: "perch" }),
      _c("router-view"),
      _vm.$route.meta.isNavBar
        ? _c(
            "div",
            { staticClass: "navbarBox" },
            _vm._l(_vm.navbarlist, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "navbaiItem",
                  class: _vm.$route.path == item.path ? "activeItem" : "",
                  on: {
                    click: function ($event) {
                      return _vm.gorouter(item.path)
                    },
                  },
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !(_vm.$route.path == item.path),
                        expression: "!($route.path==item.path)",
                      },
                    ],
                    attrs: { src: item.imgurl },
                  }),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.$route.path == item.path,
                        expression: "$route.path==item.path",
                      },
                    ],
                    attrs: { src: item.activeimg },
                  }),
                  _c("span", [_vm._v(_vm._s(item.name))]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }