<template>
    <div>

        <div class="editAddres">
            <div class="addressInp">

                <div class="addressInpItem">
                    <div class="addressInpItem_title">收货人</div>
                    <div class="addressInp_box">
                        <input type="text" placeholder="请输入收货人" v-model="from.contact">
                    </div>
                </div>
                <div class="addressInpItem">
                    <div class="addressInpItem_title">手机号</div>
                    <div class="addressInp_box">
                        <input type="text" placeholder="请输入手机号" v-model="from.telephone">
                    </div>
                </div>
                <div class="addressInpItem">
                    <div class="addressInpItem_title">所在地区</div>
                    <div class="addressInp_box" @click="selectaddress">
                        <div>
                            <p v-if="citylist.length==0">请选择完整的地址</p>
                            <p class="resAddress">
                                <span
                                v-for="item in citylist"
                                :key="item.value"
                                >{{ item.label }}</span>
                            </p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <div class="addressInpItem">
                    <div class="addressInpItem_title">详细地址</div>
                    <div class="addressInp_box">
                        <input type="text" placeholder="请输入详细地址" v-model="from.content">
                    </div>
                </div>

            </div>

            <div class="defaultbtn" @click="setdefault">
                <div>设置为默认地址</div>
                <div 
                    class="defaulticonNot"
                    :class = "from.isDefault==1?'defaulticon':''"
                ></div>
            </div>
        </div>

        <div class="saveBtn" @click="submit">保存</div>
        <PopSelectAddress 
            v-if="ispop" @clearPop = "clearPop"
            @changSelect = "changSelect"
        ></PopSelectAddress>
    </div>
</template>
<script>
import PopSelectAddress from "@/pages/verticalphone/common/popSelectAddress"
import { 
    addAddress,
    getaddressInfo
} from "@/utils/http.js";
export default {
    data() {
        return {
            from:{
                addressId:'0', //地址ID 新增操作为 0 不为0 则为更新操作
                contact:'', //联系人
                telephone:'',//手机号
                content:'',//地址详情
                provinceId:'',//省编号
                cityId:'',//市编号
                districtId:'',//区编号
                isDefault:'1'//是否为 默认地址 1是 0否
            },
            citylist:[],
            ispop:false,
            reg_tel:/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,

        }
    },
    components:{
        PopSelectAddress
    },
    created(){
        if(this.$route.query.addressId){
            this.from.addressId = this.$route.query.addressId;
            this.getAddressinfo()
        }
    },
    methods:{
        changSelect(arr){
            this.clearPop()
            this.citylist = arr;
            this.from.provinceId = this.citylist[0].value+'0000';
            this.from.cityId = this.citylist[1].value+'00';
            this.from.districtId = this.citylist[2].value;
        },
        clearPop(){
            this.ispop = false;
        },
        selectaddress(){
            this.ispop = true;
        },
        setdefault(){
            if(this.from.isDefault=='0'){
                this.from.isDefault ='1'
            }else if(this.from.isDefault=='1'){
                this.from.isDefault ='0'
            }
        },
        //保存地址
        async submit(){
            if(!this.from.contact)return this.$message({ message: '请输入联系人',type: 'warning'});
            if(!this.reg_tel.test(this.from.telephone))return this.$message({ message: '请输入正确的电话',type: 'warning'});
            if(!this.from.districtId) return this.$message({ message: '请选择完整的地址',type: 'warning'});
            if(!this.from.content) return this.$message({ message: '请选择完整的地址',type: 'warning'});
            
            let data = await addAddress(this.from)
            if(data.status==0){
            this.$message({
                message: `${this.$route.query.addressId?'修改成功':'添加成功'}`,
                type: 'success'
            });
                setTimeout(()=>{
                    this.$router.back()
                },1500)
            }else{
                this.$message.error(`${this.$route.query.addressId?'修改失败':'添加失败'}`);
            }
        },
        // 获取地址详情
        async getAddressinfo(){
            let {data,status} = await getaddressInfo({addressId:this.from.addressId});
            if(status!==0)return;
    
            for (const key in this.from) {
                this.from[key]=data.addressInfo[key];
            }
            this.citylist = [
                {
                    value:0,
                    label:data.addressInfo.provinceName
                },{
                    value:1,
                    label:data.addressInfo.cityName
                },{
                    value:2,
                    label:data.addressInfo.districtName
                }
            ]
            
        }
    }
}
</script>
<style lang="less" scoped>
.editAddres {
    width: 100%;
    height: 95vh;
    background-color: #3a3a47;
    padding: 60px 50px;
    font-size: 56px;
    font-weight: 800;
    color: #FFFFFF;

    >div{
        background-color: #1D2533;
        border-radius: 40px;
        margin-bottom: 60px;
        padding: 0px 60px;
    }

    .defaultbtn {
        height: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .defaulticonNot {
        width: 72px;
        height: 72px;
        border-radius: 50%;
        background-color: #fff;
    }
    .defaulticon {
        background: url(~@/assets/phone/iconres.png) no-repeat center center;
        background-size: 100% 100%;
    }

    .addressInpItem {
        display: flex;
        mini-height: 200px;
        .addressInpItem_title {
            flex: 1;
            line-height: 200px;
            color: #666666;
        }
        .addressInp_box {
            flex: 3;
            input{
                width: 100%;
                height: 100%;
                background-color: #ffffff00;
                border: none;
                outline: none;
                font-size: 56px;
                font-weight: 800;
                color: #FFFFFF;
            }
            >div{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #666666;
                .resAddress{
                    color: #FFFFFF;
                    span {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}
.saveBtn{
    position: fixed;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    width: 80%;
    height: 176px;
    background: #F88503;
    border-radius: 88px;
    font-size: 60px;
    font-weight: 800;
    color: #FFFFFF;
    text-align: center;
    line-height: 176px;
}
</style>