<template>
    <div 
        class="menuitem"
        :class="active?'activeitem':''"
        @click="activeclcik"
    >
        <span>{{ item.title }}</span>
        <i class="bottom_icon"></i>

        <div class="treeicon" v-if="active" @click.stop = "showSonMeun"> 
            <i  @click.stop  v-if="item.children.length!=0"></i>
        </div>
    </div>
</template>
<script>
export default {
    props:['item','active'],
    methods:{
        showSonMeun(e){
            this.$emit('onShowSonMeun',this.item)
        },
        activeclcik(){
            this.$emit('activeClcik',this.item.categoryId)
        }
    }
    
}
</script>
<style lang="less" scoped>
.menuitem {
    position: relative;
    display: inline-block;
    font-size: 56px;
    font-weight: 800;
    color: #999999;
    margin-right: 150px;
    height: 150px;
    line-height: 150px;
    .bottom_icon{
        display: none;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 272px;
        height: 12px;
        background: #F88503;
        border-radius: 6px;
    }

    .treeicon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        i{
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(100%,-50%);
            display: block;
            width: 48px;
            height: 24px;
            background: url('~@/assets/phone/icon_bottom.png') no-repeat center center;
            background-size: 100% 100%;
        }
    }
}
.activeitem{
    color: #F88503;
    .bottom_icon{
        display: block;
    }
}
    
</style>