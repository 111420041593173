var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "orderbox" },
      [
        _vm._l(_vm.addresslist, function (item) {
          return _c(
            "div",
            {
              key: item.addressId,
              staticClass: "orderAddres",
              on: { click: _vm.goAddress },
            },
            [
              _c("div", [
                item.isDefault ? _c("i", [_vm._v("默认")]) : _vm._e(),
                _c("p", [
                  _c("span", [_vm._v(_vm._s(item.provinceName))]),
                  _c("span", [_vm._v(_vm._s(item.cityName))]),
                  _c("span", [_vm._v(_vm._s(item.districtName))]),
                ]),
              ]),
              _c("div", [_c("p", [_vm._v(_vm._s(item.content))])]),
              _c("div", [
                _c("p", [
                  _c("span", [_vm._v(_vm._s(item.contact))]),
                  _c("span", [_vm._v(_vm._s(item.telephone))]),
                ]),
              ]),
              _vm._m(0, true),
            ]
          )
        }),
        _c("div", { staticClass: "orderinfo" }, [
          _c("div", { staticClass: "orderinfoimg" }, [
            _c("img", { attrs: { src: _vm.Oederinfo.image } }),
          ]),
          _c("div", { staticClass: "orderinfo_Comtent" }, [
            _c("div", { staticClass: "orderinfo_Comtent_Title" }, [
              _c("p", [_vm._v(_vm._s(_vm.Oederinfo.title))]),
            ]),
            _c("div", { staticClass: "orderinfo_gg" }, [
              _c("span", [_vm._v(_vm._s(_vm.Oederinfo.skuValue))]),
            ]),
            _c("div", { staticClass: "orderinfo_num_Price" }, [
              _c("div", { staticClass: "orderinfo_Price" }, [
                _c("span", [_vm._v("￥")]),
                _vm._v(_vm._s(_vm.Oederinfo.price)),
              ]),
              _c("div", { staticClass: "orderinfo_resnum" }, [
                _vm._v("x" + _vm._s(_vm.Oederinfo.number)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "Priceinfo" }, [
          _c("div", [
            _c("span", [_vm._v("商品总额")]),
            _c("span", { staticClass: "Priceinfo_num" }, [
              _vm._v("￥" + _vm._s(_vm.Oederinfo.totalPrice)),
            ]),
          ]),
          _c("div", [
            _c("span", [_vm._v("运费")]),
            _c("span", { staticClass: "Priceinfo_num" }, [
              _vm._v("￥" + _vm._s(_vm.Oederinfo.courierFee)),
            ]),
          ]),
        ]),
      ],
      2
    ),
    _c("div", { staticClass: "orderfrom" }, [
      _c("div", [
        _c("div", { staticClass: "orderfrom_zj" }, [
          _vm._v("\n                总计:\n                "),
          _c("span", [_vm._v("￥" + _vm._s(_vm.Oederinfo.disbursements))]),
        ]),
      ]),
      _c("div", { staticClass: "ordebtn", on: { click: _vm.onPay } }, [
        _vm._v("立即支付"),
      ]),
    ]),
    _vm.isCode
      ? _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "Popup_box",
            attrs: {
              "element-loading-text": "拼命加载中",
              "element-loading-spinner": "el-icon-loading",
              "element-loading-background": "rgba(0, 0, 0, 0.3)",
            },
          },
          [
            _vm.codeImgUrl
              ? _c("div", { staticClass: "code_box" }, [
                  _c("div", [_vm._v("支付宝扫码")]),
                  _c("div", { staticClass: "codeImgBox" }, [
                    _c("img", { attrs: { src: _vm.codeImgUrl } }),
                  ]),
                  _vm._m(1),
                  _c(
                    "div",
                    { staticClass: "clearIcon", on: { click: _vm.onCode } },
                    [_c("i", { staticClass: "el-icon-close" })]
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "rightIcon" }, [
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("p", [_vm._v("支付成功后，请关闭支付窗口")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }