var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "menuitem",
      class: _vm.active ? "activeitem" : "",
      on: { click: _vm.activeclcik },
    },
    [
      _c("span", [_vm._v(_vm._s(_vm.item.title))]),
      _c("i", { staticClass: "bottom_icon" }),
      _vm.active
        ? _c(
            "div",
            {
              staticClass: "treeicon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showSonMeun.apply(null, arguments)
                },
              },
            },
            [
              _vm.item.children.length != 0
                ? _c("i", {
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  })
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }