var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "addresslist" },
      _vm._l(_vm.addresslist, function (item) {
        return _c("div", { key: item.addressId, staticClass: "addressitem" }, [
          _c("div", { staticClass: "orderAddres" }, [
            _c("div", [
              item.isDefault == 1 ? _c("i", [_vm._v("默认")]) : _vm._e(),
              _c("p", [
                _c("span", [_vm._v(_vm._s(item.provinceName))]),
                _c("span", [_vm._v(_vm._s(item.cityName))]),
                _c("span", [_vm._v(_vm._s(item.districtName))]),
              ]),
            ]),
            _c("div", [_c("p", [_vm._v(" " + _vm._s(item.content))])]),
            _c("div", [
              _c("p", [
                _c("span", [_vm._v(_vm._s(item.contact))]),
                _c("span", [_vm._v(_vm._s(item.telephone))]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "addressBtnbox" }, [
            _c(
              "div",
              {
                staticClass: "defaultInp",
                on: {
                  click: function ($event) {
                    return _vm.setdefault(item)
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "defaultIcon",
                  class: item.isDefault == 1 ? "activeicon" : "",
                }),
                _c("div", [_vm._v("设为默认")]),
              ]
            ),
            _c("div", { staticClass: "addressBtn" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.deleteAddress(item)
                    },
                  },
                },
                [_vm._v("删除")]
              ),
              _c(
                "div",
                {
                  staticClass: "editbtn",
                  on: {
                    click: function ($event) {
                      return _vm.onaddAddress(item.addressId)
                    },
                  },
                },
                [_vm._v("修改")]
              ),
            ]),
          ]),
        ])
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "addAddressBtn",
        on: {
          click: function ($event) {
            return _vm.onaddAddress("")
          },
        },
      },
      [_vm._v("\n        新增地址\n    ")]
    ),
    _vm.isconfirm
      ? _c("div", { staticClass: "confirmview" }, [
          _c("div", { staticClass: "confirmbox" }, [
            _c("div", { staticClass: "confirmTitle" }, [
              _vm._v(
                "\n                删除后不可恢复,是否继续?\n            "
              ),
            ]),
            _c("div", { staticClass: "confirmBtn" }, [
              _c("div", { on: { click: _vm.onCancel } }, [_vm._v("取消")]),
              _c("div", { on: { click: _vm.onRes } }, [_vm._v("确认")]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }