<template >
    <div class="orderinfo_box">
  
      <div class="orderinfo_status">
        <div class="orderinfo_status_icon"></div>
        <p class="orderinfo_status_text">订单状态：{{ statustext[orderStatus] }}</p>
        <p>订单编号：{{ goodsinfo.orderSn }}</p>
        <p>收货地址：{{ goodsinfo.address }}</p>
      </div>
  
      <div class="orderinfo_list_view">
  
        <div class="orderinfo_list_table">
            <div class="info_img">
                <img :src="goodsinfo.image" alt="">
            </div>
            <div class="info_text">
            <p>{{ goodsinfo.title }}</p>
            <p><span>{{ goodsinfo.skuValue }}</span></p>
            <div>
                <span class="redtext">￥{{ goodsinfo.price }}</span>
                <span>数量：{{ goodsinfo.goodsNumber }}</span>
            </div>
            </div>
        </div>

      </div>
  
      <div class="orderinfo_list_foot">
        <div class="orderinfo_list_number">韵达快递 运单号:432691873134624</div>
        <div class="foot_Price">
          <p>快递费用：<span class="redtext">￥{{ goodsinfo.courierFee }}</span></p>
          <p>实付款：<span class="bigredtext">￥{{ goodsinfo.orderPayment }}</span></p>
        </div>
      </div>
  
    </div>
  </template>
  <script>
  
  import {
    getOrderDetails
  } from '@/utils/http.js'
  
  export default {
    // orderId
    data() {
      return {
        statustext:{
          0:'待支付',
          1:'订单取消',
          2:'待收货',
          3:'已收货',
          4:'退换货',
        },
        orderStatus:null,
        goodsinfo:{}
      }
    },
    created(){
      this.getdatainfo();
      this.orderStatus=this.$route.query.orderStatus;
    },
    methods:{
      async getdatainfo(){
        let res = await getOrderDetails({orderId:this.$route.query.orderId});
        if(res.status!=0)return;
        this.goodsinfo = res.data;
        console.log( this.goodsinfo);
  
        
      }
    }
  }
  </script>
  <style lang="less" scoped>
  p {
    margin: 0px;
  }
  .orderinfo_box {
    min-height: 656px;
    background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
    border-radius: 30px;
    border: 3px solid rgba(255,255,255,0.2);

    .orderinfo_status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: 500;
      color: #FFFFFF;
      padding: 43px 0px 39px;
      .orderinfo_status_icon {
        width: 50px;
        height: 50px;
        background: url('~@/assets/goodsOrder/resquer_icon.png') no-repeat center center;
        background-size: 100% 100%;
        margin-bottom: 16px;
      }
  
      .orderinfo_status_text {
        font-size: 20px;
        font-weight: 800;
        color: #D5A223;
        margin-bottom: 25px;
      }
  
      p {
        margin-top: 15px;
      }
  
    }
  
    .orderinfo_list_view {
      border-top: 1px solid #cccccc7d;
      border-bottom: 1px solid #cccccc7d;
      .orderinfo_list_table {
          display: flex;
          align-items: center;
          padding: 19px 43px;
          .info_img {
            width: 200px;
            height: 160px;
            border-radius: 14px;
            margin-right: 21px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .info_text {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 160px;
            font-size: 30px;
            font-weight: 800;
            line-height: 36px;
            color: #FFFFFF;
            p{
              span {
                font-size: 22px;
                font-weight: 500;
                color: #CCCCCC;
                line-height: 32px;
                padding: 5px 10px;
                background: #2B475A;
                border-radius: 5px;
              }
            }

            div {
              font-size: 22px;
              font-weight: 500;
              color: #FFFFFF;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .redtext{
                font-size: 30px;
                font-weight: 800;
                color: #FF0000;
              }
            }
          }
        }
    }
  
    .orderinfo_list_foot {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      color: #CCCCCC;
      margin-top:29px;
      padding: 0px 43px;
      .orderinfo_list_number{
        font-size: 24px;
        font-weight: 500;
        color: #FFFFFF;
      }
      .foot_Price {
        text-align: right;
      }
      
    }
  }
  .redtext{
    font-weight: 800;
    color: #FF0000;
  }
  .bigredtext{
    font-size: 30px;
    font-weight: 800;
    color: #FF0000;
  }
  </style>