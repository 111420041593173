var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.islogin
        ? _c("Login", { on: { isloginuser: _vm.isloginuser } })
        : _vm._e(),
      _c("div", [
        _c("div", { staticClass: "container" }, [
          _c(
            "div",
            { staticClass: "navbar" },
            [
              _c("NavBar", {
                ref: "NavBar",
                attrs: { artId: _vm.promas.artId },
                on: { gologin: _vm.gologin, Clicktree: _vm.onClicktree },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "container_main" }, [
            _c(
              "div",
              { staticClass: "goodslist" },
              _vm._l(_vm.goodslist, function (item) {
                return _c("goodsitem", {
                  key: item.goodsId,
                  attrs: { item: item },
                })
              }),
              1
            ),
            _vm.goodslist.length == 0
              ? _c("div", { staticClass: "notzhanpin" }, [
                  _c("span", [
                    _vm._v(
                      "\n                        暂无展品~~~\n                    "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.totalPage && _vm.goodslist.length
              ? _c(
                  "div",
                  { staticClass: "paging" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.promas.page,
                        layout: "prev, pager, next",
                        "page-count": _vm.totalPage,
                      },
                      on: { "current-change": _vm.changPage },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }