var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "orderView" }, [
      _c("div", { staticClass: "orderViewLeft" }, [
        _c(
          "div",
          { staticClass: "addressList" },
          _vm._l(_vm.addresslist, function (item, index) {
            return _c(
              "div",
              {
                key: item.addressId,
                staticClass: "addressListItem",
                class: _vm.activeindex == index ? "defaultItem" : "",
                on: {
                  click: function ($event) {
                    return _vm.onActiveAddress(item, index)
                  },
                },
              },
              [
                _c("div", { staticClass: "title" }, [
                  item.isDefault == 1
                    ? _c("div", { staticClass: "defaultIcon" }, [
                        _vm._v("默认"),
                      ])
                    : _vm._e(),
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.provinceName))]),
                    _vm._v(" \n                            "),
                    _c("span", [_vm._v(_vm._s(item.cityName))]),
                    _vm._v(" \n                            "),
                    _c("span", [_vm._v(_vm._s(item.districtName))]),
                  ]),
                ]),
                _c("div", { staticClass: "titlename" }, [
                  _c("p", [_vm._v(_vm._s(item.content))]),
                ]),
                _c("div", { staticClass: "titleinp" }, [
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.contact))]),
                    _c("span", [_vm._v(_vm._s(item.telephone))]),
                  ]),
                  _c("div"),
                ]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "addAddress", on: { click: _vm.goAddress } }, [
          _c("span", [_vm._v("新增收货地址")]),
          _c("i"),
        ]),
      ]),
      _c("div", { staticClass: "orderViewRight" }, [
        _c("div", {
          staticClass: "goback",
          on: {
            click: function ($event) {
              return _vm.$router.back()
            },
          },
        }),
        _c("div", { staticClass: "goodsimgtitle" }, [
          _c("div", { staticClass: "imgs" }, [
            _c("img", { attrs: { src: _vm.Oederinfo.image } }),
          ]),
          _c("div", { staticClass: "goodstitle" }, [
            _c("p", [_vm._v(_vm._s(_vm.Oederinfo.title))]),
            _c("p", [
              _c("span", [
                _c("i", [_vm._v("￥")]),
                _vm._v(_vm._s(_vm.Oederinfo.price)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "goodsorderinfo" }, [
          _c("div", { staticClass: "goodsorderinfoitem" }, [
            _c("div", [_vm._v("规格")]),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.Oederinfo.skuValue))])]),
          ]),
          _c("div", { staticClass: "goodsorderinfoitem" }, [
            _c("div", [_vm._v("数量")]),
            _c("div", [_c("span", [_vm._v(_vm._s(_vm.Oederinfo.number))])]),
          ]),
          _c("div", { staticClass: "goodsorderinfoitem" }, [
            _c("div", [_vm._v("运费")]),
            _c("div", [
              _c("span", { staticClass: "yufei" }, [
                _vm._v("￥" + _vm._s(_vm.Oederinfo.courierFee)),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "onlyview" }, [
          _c("div", { staticClass: "onlyviewje" }, [
            _vm._v(
              "\n                    总计：￥" +
                _vm._s(_vm.Oederinfo.disbursements) +
                "\n                "
            ),
          ]),
          _c("div", { staticClass: "onlyviewBtn", on: { click: _vm.onPay } }, [
            _vm._v("\n                    购买\n                "),
          ]),
        ]),
      ]),
    ]),
    _vm.isCode
      ? _c("div", { staticClass: "codeView" }, [
          _c("div", { staticClass: "codeimgview" }, [
            _c("p", { staticClass: "codeViewtitle" }, [_vm._v("支付宝扫码")]),
            _c("div", { staticClass: "codeimg" }, [
              _c("img", { attrs: { src: _vm.codeImgUrl, alt: "" } }),
            ]),
            _c("p", { staticClass: "codeViewtips" }, [
              _vm._v("支付成功后，请关闭支付窗口"),
            ]),
            _c("div", { staticClass: "clearBtn", on: { click: _vm.onCode } }, [
              _c("i", { staticClass: "el-icon-close" }),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }