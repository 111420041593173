<template>
    <div class="return_Goods_Box">
        <div class="goodsinfo_box">
            <div class="orderinfoimg">
                <img :src="goodsinfo.image" alt="">
            </div>
            <div class="orderinfo_Comtent">
                <div class="orderinfo_Comtent_Title"><p>{{ goodsinfo.title }}</p></div>
                <div class="orderinfo_gg">
                    <span>{{ goodsinfo.skuValue }}</span>
                </div>
                <div class="orderinfo_num_Price">
                    <div class="orderinfo_Price"><span>￥</span>{{ goodsinfo.price }}</div>
                    <div class="orderinfo_num"  >x{{ goodsinfo.goodsNumber }}</div>
                </div>
            </div>
        </div>
       
        <div class="Express_delivery_info">
            <div class="Express_delivery_title">请将需要退换的货物邮递到此地址</div>

            <div class="contentBox">
                <div class="content_row">
                    <div class="content_item">
                        <p class="content_title">收货人：</p>
                        <p class="content_name">{{  userAddressInfo.contact }}</p>
                    </div>
                    <div class="content_item">
                        <p class="content_title">电话：</p>
                        <p class="content_name">{{ userAddressInfo.telephone }}</p>
                    </div>
                </div>
                <div class="content_row">
                    <div class="content_item">
                        <p class="content_title">收货地址：</p>
                        <p class="content_name">{{ userAddressInfo.addressContent }}</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="return_num">
            <div>快递单号</div>
            <div class="return_num_inp">
                <input type="text" placeholder="点击输入快递单号" v-model="expressNumber">
            </div>
        </div>
      
        <div class="submitBtn" @click="submitfrom">
            提交
        </div>

    </div>
</template>
<script>

import {
  getOrderDetails,
  returnGoodsAddExpressNumber
} from '@/utils/http.js';
export default {
    data() {
        return {
            goodsinfo:{},
            userAddressInfo:{},
            expressNumber:''
        }
    },
    created(){
        this.getdatainfo()
        this.getGoodsAddExpressNumber()
    },
    methods:{
        async getdatainfo(){
            let res = await getOrderDetails({orderId:this.$route.query.orderId});
            if(res.status!=0)return;
            this.goodsinfo = res.data;
        },
        // 获取地址
        async getGoodsAddExpressNumber(){
            let param = {
            orderId:this.$route.query.orderId,
            };

            let res = await returnGoodsAddExpressNumber(param);
            if(res.status==0){
                this.userAddressInfo = res.data;
            }
        },
        submitfrom(){
          if(!this.expressNumber)return this.$message({message: '请输入快递单号',type: 'warning'});
          let data = {
            orderId:this.$route.query.orderId,
            expressNumber:this.expressNumber
          };
          returnGoodsAddExpressNumber(data).then((res)=>{
            if(res.status==0){
              this.$message({message: '提交成功',type: 'success'})
              setTimeout(()=>{
                this.$router.back()
              },500)
            }
          },()=>{
            this.$message({message: '提交失败',type: 'warning'})

          });
      },
    }
    
}
</script>
<style lang="less" scoped>
.return_Goods_Box {
    width: 100%;
    min-height: 96vh;
    background: #3a3a47;
    padding: 60px 50px;

    .goodsinfo_box{
        background-color: #1D2533;
        border-radius: 40px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        min-height: 520px;
        padding: 36px 40px;
            .orderinfoimg {
                width: 540px;
                height: 450px;
                background: #fff;
                margin-right: 60px;
                border-radius: 30px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cove;
                }
            }
            .orderinfo_Comtent {
                width: 50%;
                .orderinfo_Comtent_Title {
                    font-size: 56px;
                    font-weight: 800;
                    color: #FFFFFF;
                    margin-bottom: 50px;
                }
                .orderinfo_gg {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        padding: 10px 34px;
                        background-color: #303A4B;
                        margin-right: 30px;
                        margin-top: 30px;
                        border-radius: 15px;
                        font-size: 44px;
                        font-weight: 500;
                        color: #999999;
                    }

                }
            }

            .orderinfo_num_Price {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                .orderinfo_Price {
                    font-size: 100px;
                    font-weight: 800;
                    color: #FF0021;
                    span{
                        font-size: 44px;
                    }
                }
                .orderinfo_num {
                    font-size: 48px;
                    font-weight: 800;
                    color: #fff;
                }
            }
    }

    .Express_delivery_info{
        background-color: #1D2533;
        border-radius: 40px;
        .Express_delivery_title {
            height: 200px;
            border-bottom: 1px solid #cccccc54;
            text-align: center;
            line-height: 200px;
            font-size: 48px;
            font-weight: bold;
            color: #FFFFFF;
        }

        .contentBox {
            overflow: hidden;
            .content_row {
                display: flex;
                min-height: 300px;
                margin: 100px 0px;
                .content_item{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    font-size: 60px;
                    font-weight: bold;
                    color: #FFFFFF;
                    padding-left: 120px;
                    .content_title{
                        color: #999999;
                    }
                }
            }
        }
    }

    .return_num {
        font-size: 68px;
        font-weight: 800;
        color: #FFFFFF;
        >div:nth-child(1){
            margin: 60px 0px;
        }
        .return_num_inp {
            height: 200px;
            background-color: #1D2533;
            border-radius: 40px;
            input{
                width: 100%;
                height: 100%;
                text-indent: 60px;
                background-color: #00000000;
                border: none;
                outline: none;
                font-size: 48px;
                font-weight: 800;
                color: #ffffff;
                &::placeholder{
                    font-size: 48px;
                    font-weight: 800;
                    color: #666666;
                }
            }
        }
    }
  
    .submitBtn {
        margin: 100px auto;
        height: 176px;
        background: #F88503;
        border-radius: 88px;
        text-align: center;
        line-height: 176px;
        font-size: 60px;
        font-weight: 800;
        color: #FFFFFF;
    }
}
</style>