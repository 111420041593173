var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popUpView" }, [
    _c("div", { staticClass: "box" }, [
      _c("div", { staticClass: "box_head" }, [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "box_head_btn" }, [
          _c("div", { staticClass: "btn_no", on: { click: _vm.clearpPop } }, [
            _vm._v("取消"),
          ]),
          _c("div", { staticClass: "btn_yse", on: { click: _vm.onSureitem } }, [
            _vm._v("确定"),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "box_body" },
        [
          _c("el-tree", {
            attrs: { data: _vm.activeChildren, props: _vm.defaultProps },
            on: { "node-click": _vm.handleNodeClick },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }