<template>
    <div class="loginView">
        <div class="loginInp">
            <div class="title">登录</div>
            <div class="inpbox">
                <p>手机号</p>
                <input type="text" placeholder="点击此处输入手机号" v-model="params.mobile">
            </div>
            <div class="inpbox">
                <p>验证码</p>
                <input type="text" placeholder="点击此处输入验证码" v-model="params.verCode">
                <div class="yzmbtn" @click="getverCode">
                    {{ codeDate>60?'获取验证码':codeDate+'秒后在此获取' }}
                </div>
            </div>
            <div class="btn" @click="loginuser">登录</div>
            <div class="clearbtn" @click="clearinp"></div>
        </div>
    </div>
</template>
<script>
import {getsendLoginSms,doLogin} from "@/utils/http";
import { setToken } from '@/utils/auth' // get token from cookie

export default {
    data() {
        return {
            params:{
                // mobile:'19999999996',
                // verCode:'8888'
                mobile:'',
                verCode:''
            },
            codetime:null,
            codeDate:61,
            iscode:true,
        }
    },
    beforeDestroy(){
        if(this.codetime){
            clearInterval(this.codetime)
        }
    },
    methods:{
        loginuser(){
            if(!this.params.mobile)return this.$message({message: '请输入手机号码',type: 'warning'});
            if(!this.params.verCode)return this.$message({message: '请输入验证码',type: 'warning'});
            // mobile:'19999999996',
                // verCode:'8888'
            doLogin(this.params).then((res)=>{
                if(res.status==0){
                    setToken(res.data.token)
                    localStorage.setItem('usernum',res.data.token)
                    this.codeDate = 61;
                    this.iscode = true;
                    clearInterval(this.codetime)

                    this.$emit('isloginuser',1)
                }else{
                    this.$message({message: res.msg,type: 'warning'})
                }
            },(err)=>{
                this.$message({message: err.msg,type: 'warning'})
            })

        },

        clearinp(){
            this.$emit('isloginuser',0)
        },

        getverCode(){
            if(!this.iscode)return;
            this.iscode = false;

            if(!this.params.mobile)return this.$message({message: '请输入手机号码',type: 'warning'});
            getsendLoginSms({mobile:this.params.mobile}).then(()=>{
                this.verCodeCountdown();
            },(err)=>{
                this.iscode = true;
                this.$message({message: err.msg,type: 'warning'})
            })
        },
        verCodeCountdown(){
            this.codetime = setInterval(()=>{
                this.codeDate-=1
            },1000)
        },
    },
    watch:{
        codeDate(newval){
            if(newval<=0){
                this.codeDate = 61;
                this.iscode = true;
                clearInterval(this.codetime);
            }
        }
    }
    
}
</script>
<style lang="less" scoped>
.loginView {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000063;
    z-index: 999999;

    .loginInp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 600px;
        height: 630px;
        background: url('~@/assets/phone/loginBG.png') no-repeat center center;
        background-size: 100% 100%;
        color: #FFFFFF;

        .title{
            margin-top: 48px;
            margin-bottom: 34px;
            font-size: 36px;
            font-weight: 800;
        }

        .inpbox {
            position: relative;
            font-size: 28px;
            font-weight: 800;
            margin-bottom: 39px;
            p {
                margin-bottom: 19px;
            }

            input {
                width: 500px;
                height: 80px;
                background: #1F394D;
                border-radius: 50px;
                border: none;
                border: 2px solid #555555;
                font-size: 28px;
                color: #ffffff;
                padding: 0px 28px;
                font-weight: 500;

                &::placeholder{
                    font-size: 24px;
                    font-weight: 500;
                    color: #666666;
                }
            }

            .yzmbtn {
                position: absolute;
                right: 0px;
                top: 58%;
                height: 35px;
                padding: 0px 20px;
                font-size: 24px;
                font-weight: 500;
                color: #D5A223;
                border-left: #666666 solid 1px;
            }
        }

        .btn {
            width: 200px;
            height: 80px;
            background: #D5A223;
            border-radius: 40px ;
            font-size: 34px;
            font-weight: 800;
            text-align: center;
            line-height: 80px;
            margin-top: 10px;
        }

        .clearbtn{
            position: absolute;
            top: 25px;
            right: 25px;
            width: 50px;
            height: 50px;
            background: url('~@/assets/phone/icon04.png') no-repeat center center;
            background-size: 100% 100%;
        }
    }
}
    
</style>