<template>
    <div >
        <div class="orderbox">
            <div class="orderAddres" 
                v-for="(item) in addresslist"
                :key="item.addressId"
                @click="goAddress"
            >
                <div>
                    <i v-if="item.isDefault">默认</i>
                    <p>
                        <span>{{item.provinceName}}</span>
                        <span>{{item.cityName}}</span>
                        <span>{{item.districtName}}</span>
                    </p>
                </div>
                <div>
                    <p>{{item.content}}</p>
                </div>
                <div>
                    <p><span>{{item.contact}}</span><span>{{item.telephone}}</span></p>
                </div>
                <div class="rightIcon">
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>

            <div class="orderinfo">
                <div class="orderinfoimg">
                    <img :src="Oederinfo.image">
                </div>
                <div class="orderinfo_Comtent">
                    <div class="orderinfo_Comtent_Title"><p>{{ Oederinfo.title }}</p></div>
                    <div class="orderinfo_gg">
                        <span>{{ Oederinfo.skuValue }}</span>
                    </div>

                    <div class="orderinfo_num_Price">
                        <div class="orderinfo_Price"><span>￥</span>{{ Oederinfo.price }}</div>
                        <div class="orderinfo_resnum">x{{ Oederinfo.number }}</div>
                    </div>
                </div>
            </div>
            <div class="Priceinfo">
                <div>
                    <span>商品总额</span>
                    <span class="Priceinfo_num">￥{{ Oederinfo.totalPrice }}</span>
                </div>
               
                <div>
                    <span>运费</span>
                    <span class="Priceinfo_num">￥{{ Oederinfo.courierFee }}</span>
                </div>
            </div>
        </div>

        <div class="orderfrom">
            <div >
                <div class="orderfrom_zj">
                    总计:
                    <span>￥{{ Oederinfo.disbursements }}</span>
                </div>
                <!-- <div class="orderfrom_yh">
                    优惠:
                    <span>￥160.00</span>
                </div> -->
            </div>
            
            <div class="ordebtn" @click = 'onPay' >立即支付</div>
        </div>

        <div 
            class="Popup_box" 
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)"
            v-if="isCode"
        >

            <div class="code_box" v-if="codeImgUrl">
                <div>支付宝扫码</div>
                <div class="codeImgBox">
                    <img :src="codeImgUrl">
                </div>
                <div><p>支付成功后，请关闭支付窗口</p></div>
                <div class="clearIcon" @click="onCode">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div>
        
    </div>

</template>
<script>
import QRCode from "qrcode";

import { 
  buyGoods,
  checkPay,
  getAddressList,
  confirmBuyGoods
 } from "@/utils/http.js";
export default {
    data() {
        return {
            addresslist:[],
            Oederinfo:{},
            loading:false,
            codeImgUrl:'',
            count:0,
            timer:null,
            isCode:false
        }
    },
    created(){
        this.getAddressList();
        this.getOederinfo();
    },
    methods:{
        async getAddressList(){
            let {data,status} = await getAddressList();
            if(status!=0)return;
            
            if(localStorage.getItem('addressId')){
                this.addresslist = data.addressList.filter(item=>item.addressId==localStorage.getItem('addressId'));
            }else{
                this.addresslist = data.addressList.filter(item=>item.isDefault==1);
            }
        },
        goAddress(){
            this.$router.push({path:'/verphone/selectAddress',query:this.$route.query})

            // this.$router.push({path:'/verphone/address'})
        },
        getOederinfo(){
            confirmBuyGoods(this.$route.query).then(({status,data})=>{
                if(status!==0)return;
                this.Oederinfo = data;
            })
        },
        onPay(){
            this.isCode =true;
            this.loading =true;
            const codeCount = 0;

            let params = {
                addressId: this.addresslist[0].addressId,
                goodsId: this.Oederinfo.goodsId,
                number: this.Oederinfo.number,
                sid: this.addresslist[0].districtId,
                unique: this.Oederinfo.unique
            }
            buyGoods(params).then(({status,data})=>{
                if(status!==0)return;
                this.toCanvas(data.html)

                this.count = codeCount;
                this.timer = setInterval(() => {
                    this.callback(data.outTradeNo);
                    if (this.count >= 28800) {
                        clearInterval(this.timer);
                        this.timer = null;
                    } else {
                        this.count++;
                    }
                }, 3000);       
            },(err)=>{
                this.onCode()
                this.$message({message: err.msg,type: 'warning'})
            })
        },
        callback(outTradeNo) {
            let data = { outTradeNo:outTradeNo };
            checkPay(data).then((res) => {
                this.isCode = false;
                this.loading =false;
                clearInterval(this.timer);
                this.$router.push({path:'/verphone/verphonehome',query:{
                    artId:localStorage.getItem('artId')
                }})
            })
        },
        onCode (){
            this.loading =false;
            this.isCode = false;
            clearInterval(this.timer);
        },
        toCanvas(code) {
            QRCode.toDataURL(
                code,
                { errorCorrectionLevel: "L", margin: 2, width: 400 },
                (error, url) => {
                if (error) console.log(error);
                    this.codeImgUrl = url;
                    this.loading =false;
                }
            );
        },
    }
    
}
</script>
<style lang="less" scoped>
.orderbox {
    min-height: 94vh;
    background-color: #363E4B;
    padding: 64px 50px 250px;
    >div{
        border-radius: 40px;
        margin-bottom: 60px;
        background: #1D2533;
    }

    .paymentbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0px;
        font-size: 60px;
        font-weight: 800;
        color: #F88503;
        background-color: #363e4b00;
        .paymentimg {
            width: 160px;
            height: 160px;
            background: url('~@/assets/phone/iconres.png') no-repeat center center;
            background-size: 100% 100%;
            margin-bottom: 52px;
        }
    }
    .orderAddres {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 440px;
        padding: 60px;
        font-size: 52px;
        font-weight: 800;
        color: #fff;

        >div:nth-child(1){
            display: flex;
            align-items: center;
            i{
                display: block;
                width: 160px;
                height: 80px;
                background: #F88503;
                border-radius: 20px;
                font-size: 48px;
                font-weight: 800;
                color: #FFFFFF;
                text-align: center;
                line-height: 80px;
                font-style: normal;
                margin-right: 50px;
            }
        }
        >div:nth-child(2){
            font-size: 68px;
        }
        span{
            font-size: 48px;
            color: #999999;
            margin-right: 25px;
        }
        .rightIcon{
            position: absolute;
            top: 50%;
            right: 60px;
            transform: translateY(-50%);
            color: #999999;
        }
    }

    .orderinfo {
        display: flex;
        align-items: center;
        min-height: 520px;
        padding: 36px 40px;
        .orderinfoimg {
            width: 540px;
            height: 450px;
            background: #fff;
            margin-right: 60px;
            border-radius: 30px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .orderinfo_Comtent {
            width: 50%;
            .orderinfo_Comtent_Title {
                font-size: 56px;
                font-weight: 800;
                color: #FFFFFF;
                margin-bottom: 50px;
            }
            .orderinfo_gg {
                display: flex;
                flex-wrap: wrap;
                span {
                    padding: 10px 34px;
                    background-color: #303A4B;
                    margin-right: 30px;
                    margin-top: 30px;
                    border-radius: 15px;
                    font-size: 44px;
                    font-weight: 500;
                    color: #999999;
                }

            }
        }

        .orderinfo_num_Price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .orderinfo_Price {
                font-size: 100px;
                font-weight: 800;
                color: #FF0021;
                span{
                    font-size: 44px;
                }
            }
            .orderinfo_resnum {
                font-size: 52px;
                color: #F88503;
            }
            .orderinfo_num {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                height: 80px;
                background: #303A4B;
                border-radius: 48px;
                border: 1px solid #435065;
                font-size: 44px;
                color: #fff;
                text-align: center;
                >div{
                    flex: 1;
                }
                >div:nth-child(2){
                    border-right: 1px solid #435065;
                    border-left: 1px solid #435065;
                    color: #F88503;
                }
            }

            .orderinfo_resnum {
                font-size: 52px;
                color: #F88503;
            }
        }
    }

    .Priceinfo{
       
        padding: 0px 40px;
        font-size: 56px;
        color: #666666;
        >div{
            display: flex;
            justify-content:space-between;
            align-items: center;
            height: 200px;
        }
        .Priceinfo_num {
            font-weight: 800;
            color: #FF0021;
        }
        .Priceinfo_numm {
            color: #fff;
        }
    }

}

.orderfrom {
    position: fixed;
    left: 0px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 240px;
    padding: 0px 50px;
    background: #1D2533;
    .orderfrom_zj {
        font-size: 50px;
        font-weight: 800;
        color: #FFFFFF;
        span{
            font-size: 70px;
            color: #FF0021;
        }
    }

    .orderfrom_yh {
        font-size: 44px;
        font-weight: 800;
        color: #CCCCCC;
    }

    .ordebtn{
        width: 440px;
        height: 176px;
        background: #F88503;
        border-radius:176px;
        font-size: 60px;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        line-height: 176px;
    }
}

.Popup_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000052;
    z-index: 9999;
    .code_box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 1000px;
        height: 1200px;
        background-color: #fff;
        border-radius: 80px;
        font-size: 56px;
        font-weight: 800;
        color: #333;

        .codeImgBox {
            width: 900px;
            height: 900px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        p{
            font-size: 48px;
            color: #cccccc;
        }
        .clearIcon {
            position: absolute;
            top: 30px;
            right: 40px;
        }
    }
}
    
</style>