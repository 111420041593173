<template>
    <div class="mybox">
        <div class="userbox" v-if="userinfo">
            账号：{{ userinfo }}
        </div>
        <div class="userbox" v-if="!userinfo" @click="gologin">
            立即登录
        </div>
        <div class="muorder">
            <div>我的订单</div>
            <div @click="goOrder('')">
                全部订单
                <i class="el-icon-arrow-right"></i>
            </div>
        </div>

        <div class="myIcon">
            <div @click="goOrder(1)">
                <div class="myIcon_img">
                    <img src="@/assets/phone/icon_dfk.png">
                </div>
                <span>待付款</span>
            </div>
            <div @click="goOrder(2)">
                <div class="myIcon_img">
                    <img src="@/assets/phone/icon_dsh.png">
                </div>
                <span>待收货</span>
            </div>
            <div @click="goOrder(3)">
                <div class="myIcon_img">
                    <img src="@/assets/phone/icon_ysh.png">
                </div>
                <span>已收货</span>
            </div>
            <div @click="goOrder(4)">
                <div class="myIcon_img">
                    <img src="@/assets/phone/icon_thh.png">
                </div>
                <span>退换货</span>
            </div>
            
        </div>

        <div class="addressbtn" @click="goAddress">
            <div>
                <i></i>
                <span>地址管理</span>
            </div>
            <i class="el-icon-arrow-right"></i>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            userinfo:null,
        }
    },
    created(){
        if(localStorage.getItem('userName')){
            this.userinfo=localStorage.getItem('userName');
        }
    },
    methods:{
        goOrder(index){
            if(!this.islogin())return this.$message({message: '尚未登录',type: 'warning'});
            let query;
            if(index){
                query = {
                    index,
                }
            }
            this.$router.push({path:'/verphone/orderlist',query})
        },
        goAddress(){
            if(!this.islogin())return this.$message({message: '尚未登录',type: 'warning'});
            this.$router.push({path:'/verphone/address'})
        },
        gologin(){
            this.$router.push({path:'/verphone/verphonelogin'})
        },
        islogin(){
            let islog = false;
            if(localStorage.getItem('usernum')){
                islog = true;
            }
            return islog;
        }
    }
    
}
</script>
<style lang="less" scoped>
.mybox {
    padding: 60px 50px;

    .userbox{
        height: 200px;
        padding: 0px 60px;
        line-height: 200px;
        background: linear-gradient(95deg, #DF9B4D 0%, #F88503 100%);
        border-radius: 20px;
        font-size: 56px;
        font-weight: 500;
        color: #333333;
    }

    .muorder{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 66px 0px;
        font-size: 68px;
        font-weight: 800;
        color: #FFFFFF;
        >div:nth-child(2){
            font-size: 48px;
            font-weight: 800;
            color: #CCCCCC;
        }
    }

    .myIcon {
        display: flex;
        >div{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 52px;
            font-weight: 800;
            color: #FFFFFF;
            .myIcon_img {
                width: 152px;
                height: 128px;
                margin-bottom: 20px;
                margin: 80px 0px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }
    }

    .addressbtn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 200px;
        margin-top: 100px;
        padding: 0px 66px;
        background: #3A3A47;
        border-radius: 20px;
        font-size: 56px;
        font-weight: 800;
        color: #FFFFFF;
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                display: block;
                width: 52px;
                height: 50px;
                background: url(~@/assets/phone/icon_address.png) no-repeat center center;
                background-size: 100% 100%;
                margin-right: 50px;
            }
        }

        i{
            color: #cccccc;
        }
    }
}
    
</style>