var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login" }, [
    _vm._m(0),
    _c("div", { staticClass: "loginInp" }, [
      _c("div", { staticClass: "inpbox" }, [
        _c("p", [_vm._v("账号")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.params.mobile,
              expression: "params.mobile",
            },
          ],
          attrs: { type: "text", placeholder: "点击此处输入账号" },
          domProps: { value: _vm.params.mobile },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.params, "mobile", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "inpbox" }, [
        _c("p", [_vm._v("验证码")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.params.verCode,
              expression: "params.verCode",
            },
          ],
          attrs: { type: "text", placeholder: "点击此处输入验证码" },
          domProps: { value: _vm.params.verCode },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.params, "verCode", $event.target.value)
            },
          },
        }),
        _c("div", { staticClass: "codenumBox", on: { click: _vm.getcode } }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.isgetcode ? _vm.iscodeunm : "获取验证码") +
              "\n            "
          ),
        ]),
      ]),
      _c("button", { on: { click: _vm.onlogin } }, [_vm._v("登录")]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loginTitle" }, [
      _c("p", [_c("span", [_vm._v("欢迎登录")])]),
      _c("p", [_vm._v("登录账号即可发现更多好物")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }