<template>
    <div>
        <div class="item_head"  @click="unfolditem">
            {{ item.title }}
            <i v-if="item.children!=0" :class="isunfold?'':'unfoldicon'"></i>
        </div>
        <template v-if="item.children!=0">
            <div class="item_cntent" :class="isunfold?'':'unfold'"> 
                    <TreeItem  
                    v-for="(items) in item.children"
                    :key="items.categoryId"
                    :item="items"
                    @onClicktree = 'onClicktree'
                    ></TreeItem>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name:'TreeItem',
    props:['item'],
    data() {
        return {
            isunfold:true,
        }
    },
    methods:{
        unfolditem(e){
            this.isunfold = !this.isunfold;
            let doc = document.querySelector('.activeitem');
            if(doc){
                doc.className='item_head'
            }
            e.target.className ='item_head activeitem';

            this.$emit('onClicktree',this.item.categoryId)
        },

        onClicktree(i){
            this.$emit('onClicktree',i)
        }
    }
    
}
</script>
<style lang="less" scoped>
    .item_head {
        position: relative;
        height: 100px;
        border-bottom: 1px solid rgba(255,255,255,0.19);

        i{
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 25px;
            height: 13px;
            background: url('~@/assets/goodsOrder/Select_icon.png') no-repeat center center;
            background-size: 100% 100%;
        }

        .unfoldicon {
            transform-origin: center center;
            transform: rotate(180deg);
        }

    }
  
    .item_cntent{
        display: none;
        background: linear-gradient(133deg, #6B6B8B 0%, #2D2D35 100%);
    }
    .unfold{
        display: block;
    }

    .activeitem {
        background: rgba(220,173,44,0.84);
    }
</style>