var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "item_head", on: { click: _vm.unfolditem } }, [
        _vm._v("\n        " + _vm._s(_vm.item.title) + "\n        "),
        _vm.item.children != 0
          ? _c("i", { class: _vm.isunfold ? "" : "unfoldicon" })
          : _vm._e(),
      ]),
      _vm.item.children != 0
        ? [
            _c(
              "div",
              {
                staticClass: "item_cntent",
                class: _vm.isunfold ? "" : "unfold",
              },
              _vm._l(_vm.item.children, function (items) {
                return _c("TreeItem", {
                  key: items.categoryId,
                  attrs: { item: items },
                  on: { onClicktree: _vm.onClicktree },
                })
              }),
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }