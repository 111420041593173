var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "addresslist" },
      [
        _vm._l(_vm.addresslist, function (item) {
          return _c(
            "div",
            {
              key: item.addressId,
              staticClass: "addressitem",
              on: {
                click: function ($event) {
                  return _vm.onactiveItem(item.addressId)
                },
              },
            },
            [
              _c("div", { staticClass: "orderAddres" }, [
                _c("div", [
                  item.isDefault == 1 ? _c("i", [_vm._v("默认")]) : _vm._e(),
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.provinceName))]),
                    _c("span", [_vm._v(_vm._s(item.cityName))]),
                    _c("span", [_vm._v(_vm._s(item.districtName))]),
                  ]),
                ]),
                _c("div", [_c("p", [_vm._v(" " + _vm._s(item.content))])]),
                _c("div", [
                  _c("p", [
                    _c("span", [_vm._v(_vm._s(item.contact))]),
                    _c("span", [_vm._v(_vm._s(item.telephone))]),
                  ]),
                ]),
                _c("div", {
                  staticClass: "defaultIcon",
                  class:
                    item.addressId == _vm.activeindex
                      ? "active_defaultIcon"
                      : "",
                }),
              ]),
            ]
          )
        }),
        _c(
          "div",
          { staticClass: "addAddressitem", on: { click: _vm.onaddAddress } },
          [
            _c("span", [_vm._v("新增地址")]),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]
        ),
      ],
      2
    ),
    _c("div", { staticClass: "addAddressBtn", on: { click: _vm.onSure } }, [
      _vm._v("\n        确定\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }