<template>
    <div class="goodsSwitemView">

        <div class="goodstop">

            <div class="goodsimg">
                <div class="imgbox" ref="itemImg">
                    <img :src="goodsinf.image" v-if="!shearImg">
                    <img :src="shearImg" v-if="shearImg">
                </div>
                <div @click="goinfo" class="imgicon">
                    <span>物品详情</span>
                    <i></i>
                </div>
            </div>

            <div class="goodsdata">
                <div class="goodstitle"><p>{{ goodsinf.title }}</p></div>
                <div class="goodsPriceNum">
                    <div>
                        <span class="goodsPrice"><i>￥</i>{{ activeSku.price }}</span>
                        <span class="goodsPriceold" v-if="activeSku.isDiscount">￥{{ activeSku.originalPrice }}</span>
                    </div>
                    <div>
                        <span>库存：{{activeSku?activeSku.number:''}}份</span>
                    </div>
                </div>
                <div class="goodsSpe">

                    <SpecificationItem
                        v-for="(item,index) in goodsinf.specLists" 
                        :key="index"
                        :item="item"
                        @speciChange="speciChange($event,index)"
                    ></SpecificationItem>
                </div>
                <div class="goodsNum">
                    <div class="goodstips">数量：</div>
                    <div class="numBtn">
                        <div @click="btnNum(-1)">-</div>
                        <div>{{ goodsnum }}</div>
                        <div @click="btnNum(+1)">+</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="goodsbottom">
            <div class="roterBtn" @click="back">
                <i></i>
            </div>
            <div class="goodsPrice">
                <span>总计：￥{{ price }}</span>
                <div @click="buyRouter">购买</div>
            </div>
        </div>
      
    </div>
</template>
<script>
import {getGoodsDetails} from "@/utils/http.js";
import SpecificationItem from "@/pages/pc/shops/components/specificationItem.vue"
import { getToken } from '@/utils/auth' // get token from cookie
import html2canvas from 'html2canvas';
import { compress } from '../../../utils/ysbase64';

export default {
    props:['goods'],
    data() {
        return {
            goodsinf:{},
            skutypeArr:[],
            activeSku:{},
            goodsnum:1,
            shearImg:''
        }
    },
    components:{
        SpecificationItem
    },
    mounted(){
        this.getGoodsInfo(()=>{
            this.getshareImg()
        })
    },
    computed:{
        price(){
            return this.goodsnum?(this.activeSku.price * this.goodsnum).toFixed(2):0;
        }
    },
    methods:{
        getGoodsInfo(callback){
            let params = {
                goodsId:this.goods.goodsId,
                dressId:this.goods.dressId
            }

            // dressId
            if(this.$route.query.artId){8
                params['artId'] = this.$route.query.artId;
            }
            getGoodsDetails(params).then((res)=>{
                if(res.status!==0)return;
                this.goodsinf = res.data.goodsInfo;
                this.defaultSku()
                callback&&callback()
            })
        },

        back(){
            if(this.$route.query.artId){
                this.$router.push({path:'/phone/phonehome',query:{artId:this.$route.query.artId}})
            }else{
                this.$router.push('/phone/phonehome')
            }
        },

        goinfo(){
            let Query = {
                goodsId:this.goods.goodsId,
                activeSku:this.activeSku.price +','+this.activeSku.originalPrice
            }
            if(this.$route.query.artId){
                Query['artId'] = this.$route.query.artId;
            }
            this.$router.push({path:'/phone/goodsinfo',query:Query})
        },

        buyRouter(){
            if(this.activeSku.number<=0) return this.$message({message: '该商品已售空',type: 'warning'});
            if(!(localStorage.getItem('usernum')||getToken()))return this.$message({message: '您还尚未登录',type: 'warning'});
            if(this.goodsnum<=0) return this.$message({message: '购买数量不能小于1',type: 'warning'});

            this.$router.push({path:'/phone/goodsorder',query:{goodsId:this.goods.goodsId, number:this.goodsnum, unique:this.activeSku.unique}})
        },

        speciChange (item,index){
            this.skutypeArr[index]=item.sku;
            
            if(this.skutypeArr.every(item=> item)){
                this.activeSku=this.goodsinf.skuLists[this.skutypeArr.join(',')];
            }
        },

        defaultSku(){
        this.goodsinf.specLists.forEach((item,index) => {
          this.skutypeArr[index] = item.lists[0].sku;
        });

        if(this.skutypeArr.every(item=> item)){
            this.activeSku=this.goodsinf.skuLists[this.skutypeArr.join(',')];
        }
        },

        btnNum(i){
            if(i>0){
                if(this.goodsnum>=this.activeSku.number)return
                this.goodsnum++;
            }else {
                if(this.goodsnum==0)return;
                this.goodsnum--;
            }

        },
        getshareImg(){
            let than=this;
            // 获取对应的DOM元素
            let element = this.$refs.itemImg;

            setTimeout(()=>{
                    html2canvas(element, {
                        width: element.scrollWidth,
                        height: element.scrollHeight,
                        useCORS: true
                    }).then((canvas) => {
                        compress(
                            canvas.toDataURL('image/png'),
                            1.5,
                            function(base64){
                                than.shearImg = base64;
                            }
                        )
                    })
            }, 0)
        },
    }
    
}
</script>
<style lang="less" scoped>
.goodsSwitemView {
    width: 100%;
    height: 100%;
    padding: 45px 55px 24px 40px;
    border-radius: 30px;
    background: linear-gradient(135deg, #365F78 0%, #384E62 100%);

    .goodstop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 444px;
        margin-bottom: 35px;

        .goodsimg {
            position: relative;
         
            .imgbox {
                width: 560px;
                height: 444px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
           
            .imgicon {
                position: absolute;
                right: 0px;
                bottom: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 180px;
                height: 60px;
                background: #140e0e90;
                border-radius: 20px 0px 0px 0px;
                font-size: 24px;
                font-weight: 800;
                color: #D8A931;
                i {
                    width: 7px;
                    height: 12px;
                    margin-left: 30px;
                    background: url('~@/assets/phone/icon01.png') no-repeat center center;
                    background-size: 100% 100%;
                }
            }
        }

        .goodsdata {
            width: 579px;
            height: 444px;
            padding-left: 25px;
            border-left: 1px solid #ffffff7e;
            .goodstitle {
                p {
                    overflow: hidden;
                    text-overflow:ellipsis;
                    white-space: nowrap;
                    width: 360px;
                    font-size: 36px;
                    font-weight: 800;
                    color: #FFFFFF;
                }
            }

            .goodsPriceNum {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                font-size: 24px;
                font-weight: 800;
                color: #999999;
                padding: 25px 0px 30px;
                border-bottom: 1px solid #ffffff7e;

                .goodsPrice {
                    font-size: 40px;
                    font-weight: bold;
                    color: #FF0000;
                    i{
                        font-size: 24px;
                    }
                }
                .goodsPriceold {
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }

            .goodsSpe {
                height: 200px;
                margin: 20px 0px;
                overflow: auto;
            }

            .goodsNum {
                display: flex;
                align-items: center;
                .goodstips {
                    font-size: 24px;
                    font-weight: 800;
                    color: #FFFFFF;
                    margin-right: 19px;
                }
                .numBtn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 181px;
                    height: 60px;
                    background: #2A355A;
                    border-radius: 9px;
                    border: 1px solid rgba(255,255,255,0.2);
                    text-align: center;
                    line-height: 60px;
                    div:nth-child(1),
                    div:nth-child(3){
                        flex: 2;
                        font-size: 28px;
                        font-weight: 800;
                        color: #D8A931;
                    }
                    div:nth-child(2){
                        flex: 3;
                        border-left: 1px solid rgba(255,255,255,0.2);
                        border-right: 1px solid rgba(255,255,255,0.2);
                        font-size: 28px;
                        font-weight: 800;
                        color: #FFFFFF;
                    }
                }
            }

        }
        
    }

    .goodsbottom {
        width: 100%;
        height: 130px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >div {
            display: flex;
            align-items: center;
            background: #23425B;
            border-radius: 21px;
        }

        .roterBtn {
            justify-content: center;
            width: 130px;
            height: 130px;
            i {
                display: block;
                width: 60px;
                height: 60px;
                background: url('../../../assets/phone/icon04.png') no-repeat center center;
                background-size: 100% 100%;
            }
        }
        .goodsPrice {
            justify-content:space-between;
            width: 1000px;
            height: 130px;
            padding: 0px 40px;
            font-size: 40px;
            font-weight: 800;
            color: #FF0000;

            >div {
                width: 200px;
                height: 80px;
                background: #D5A223;
                border-radius: 40px;
                text-align: center;
                line-height: 80px;
                color: #ffffff;
            }
        }
    }

}
    
</style>