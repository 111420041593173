<template>
  <div></div>
</template>

<script>
import { setToken, setSid } from "@/utils/auth";
export default {
  data() {
    return {
      token: "",
    };
  },
  created() {
    if (this.$route.query.token) {
      setToken(this.$route.query.token);
      setSid(this.$route.query.sid);
      this.$router.push({ path: "/shops" });
    }
    // console.log(this.$route.query.token);
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
