var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "editAddres" }, [
        _c("div", { staticClass: "addressInp" }, [
          _c("div", { staticClass: "addressInpItem" }, [
            _c("div", { staticClass: "addressInpItem_title" }, [
              _vm._v("收货人"),
            ]),
            _c("div", { staticClass: "addressInp_box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.from.contact,
                    expression: "from.contact",
                  },
                ],
                attrs: { type: "text", placeholder: "请输入收货人" },
                domProps: { value: _vm.from.contact },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.from, "contact", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "addressInpItem" }, [
            _c("div", { staticClass: "addressInpItem_title" }, [
              _vm._v("手机号"),
            ]),
            _c("div", { staticClass: "addressInp_box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.from.telephone,
                    expression: "from.telephone",
                  },
                ],
                attrs: { type: "text", placeholder: "请输入手机号" },
                domProps: { value: _vm.from.telephone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.from, "telephone", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("div", { staticClass: "addressInpItem" }, [
            _c("div", { staticClass: "addressInpItem_title" }, [
              _vm._v("所在地区"),
            ]),
            _c(
              "div",
              {
                staticClass: "addressInp_box",
                on: { click: _vm.selectaddress },
              },
              [
                _c("div", [
                  _vm.citylist.length == 0
                    ? _c("p", [_vm._v("请选择完整的地址")])
                    : _vm._e(),
                  _c(
                    "p",
                    { staticClass: "resAddress" },
                    _vm._l(_vm.citylist, function (item) {
                      return _c("span", { key: item.value }, [
                        _vm._v(_vm._s(item.label)),
                      ])
                    }),
                    0
                  ),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]),
              ]
            ),
          ]),
          _c("div", { staticClass: "addressInpItem" }, [
            _c("div", { staticClass: "addressInpItem_title" }, [
              _vm._v("详细地址"),
            ]),
            _c("div", { staticClass: "addressInp_box" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.from.content,
                    expression: "from.content",
                  },
                ],
                attrs: { type: "text", placeholder: "请输入详细地址" },
                domProps: { value: _vm.from.content },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.from, "content", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "defaultbtn", on: { click: _vm.setdefault } },
          [
            _c("div", [_vm._v("设置为默认地址")]),
            _c("div", {
              staticClass: "defaulticonNot",
              class: _vm.from.isDefault == 1 ? "defaulticon" : "",
            }),
          ]
        ),
      ]),
      _c("div", { staticClass: "saveBtn", on: { click: _vm.submit } }, [
        _vm._v("保存"),
      ]),
      _vm.ispop
        ? _c("PopSelectAddress", {
            on: { clearPop: _vm.clearPop, changSelect: _vm.changSelect },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }