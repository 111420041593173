<template>
    <!-- verphonelogin -->
    <div class="login">
        <div class="loginTitle">
            <p><span>欢迎登录</span></p>
            <p>登录账号即可发现更多好物</p>
        </div>
        <div class="loginInp">
            <div class="inpbox">
                <p>账号</p>
                <input type="text" v-model="params.mobile" placeholder="点击此处输入账号">
            </div>
            <div class="inpbox">
                <p>验证码</p>
                <input type="text" v-model="params.verCode" placeholder="点击此处输入验证码">
                <div class="codenumBox" @click="getcode">
                    {{ isgetcode?iscodeunm:'获取验证码' }}
                </div>
            </div>
            <button @click="onlogin">登录</button>
        </div>

    </div>
</template>
<script>
import {getsendLoginSms,doLogin} from "@/utils/http";
import { setToken } from '@/utils/auth' // get token from cookie

export default {
    data() {
        return {
            params:{
                // mobile:'19999999996',
                // verCode:'8888'
                mobile:'',
                verCode:''
            },
            isgetcode:false,
            iscodeunm:60,
            countdown:null,
            reg_tel:/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,

        }
    },
    beforeDestroy(){
        clearInterval(this.countdown);
    },
    methods:{
        getcode(){
            if(this.isgetcode)return;
            if(!this.reg_tel.test(this.params.mobile))return this.$message({ message: '请输入正确的电话',type: 'warning'});
            this.isgetcode = true;
            getsendLoginSms({mobile:this.params.mobile}).then((res)=>{
                this.starCountdown()
            },(err)=>{
                this.$message({message: err.msg,type: 'warning'})
            })
        },
        
        onlogin(){
            if(!this.reg_tel.test(this.params.mobile))return this.$message({ message: '请输入正确的电话',type: 'warning'});
            if(!this.params.verCode)return this.$message({message: '请输入验证码',type: 'warning'});
            doLogin(this.params).then((res)=>{
                console.log(res.telephone);

                if(res.status==0){
                    setToken(res.data.token)
                    localStorage.setItem('userName',res.data.telephone)
                    localStorage.setItem('usernum',res.data.token)
                    this.$router.push({path:'/verphone/verphonehome',query:{artId:localStorage.getItem('artId')}})
                }else{
                    this.$message({message: res.msg,type: 'warning'})
                }
            },(err)=>{
                this.$message({message: err.msg,type: 'warning'})
            })
        },

        starCountdown(){
            this.countdown = setInterval(()=>{
                if(this.iscodeunm == 0)return (this.iscodeunm = 60,this.isgetcode = false,clearInterval(this.countdown));
                this.iscodeunm--;
            },1000)
        }


    }
    
}
</script>
<style lang="less" scoped>
.login {
    width: 100vw;
    height: 94vh;
    background-color: #363E4B;
    padding: 0px 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loginTitle {
        width: 100%;
        font-size: 48px;
        font-weight: 800;
        color: #999999;
        margin-bottom: 330px;
        span{
            font-size: 100px;
            font-weight: 800;
            color: #FFFFFF;
        }
    }

    .loginInp {
        width: 100%;

        .inpbox {
            position: relative;
            margin-bottom: 110px;

            p{
                font-size: 60px;
                color: #999999;
                margin-bottom: 34px;
            }

            input {
                width: 100%;
                height: 200px;
                background: #1D2533;
                border-radius: 32px;
                border: none;
                font-size: 48px;
                color: #fff;
                text-indent: 68px;
                &:focus{
                    outline:1px solid #F88503;
                }

                &::placeholder {
                    font-size: 48px;
                    color: #666666;
                }
            }

            .codenumBox {
                position: absolute;
                right: 0px;
                bottom: 60px;
                width: 400px;
                height: 80px;
                border-left: 1px solid #666;
                font-size: 48px;
                text-align: center;
                line-height: 80px;
                color: #F88503;
            }
        }

        button {
            width: 100%;
            height: 176px;
            background: #F88503;
            border-radius: 88px;
            color: #fff;
            font-size: 60px;
            font-weight: 800;
            letter-spacing: 30px;
            border: none;
        }
    }
}
    
</style>