<template>
    <div class="goodsView">
        <div class="slidePrev" @click="slidePrevNext(0)">
            <i></i>
        </div>
        <div class="goodsSwiperView">
            <div class="swiper swiper-container">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide item"
                        v-for="(item,index) in goodslist"
                        :key="index"
                    >
                        <goodsSwitem :goods="item" v-if="index===activeIndex"></goodsSwitem>
                    </div>
                </div>
            </div>
        </div>

        <div class="slideNext" @click="slidePrevNext(1)">
            <i></i>
        </div>
    </div>
</template>
<script>
import Swiper, { Pagination, Navigation } from "swiper";
Swiper.use([Pagination, Navigation]);
import "swiper/swiper.less";
import goodsSwitem from "@/pages/phone/common/goodsSwitem.vue"
import {getGoodsListsBy} from "@/utils/http.js"
import { setToken } from '@/utils/auth' // get token from cookie

export default {
    data() {
        return {
            mySwiper:null,
            promas:{
                page: 1,
                type: 3,
                artId:null,
            },
            goodslist:[],
            activeIndex:0
        }
    },
    components:{
        goodsSwitem
    },
    created(){
        this.getUrlToken()
        this.getdatalist()
    },
    methods:{
        getUrlToken(){
            if(this.$route.query.artId){
                this.promas.artId = this.$route.query.artId;
            }
            if(this.$route.query.token){
                setToken(this.$route.query.token)
                localStorage.setItem('usernum',this.$route.query.token)
            }
        },
        getdatalist(){
            getGoodsListsBy(this.promas).then(({status,data})=>{
                if(status!==0)return;
                this.goodslist = data.goodsList.map((item)=>{
                    return {goodsId:item.goodsId,dressId:item.dressId};
                })
                this.goodslist.unshift(this.$route.query)
                this.imgLoad()
            })
        },
        imgLoad() {
            this.$nextTick(()=>{
                this.mySwiper = new Swiper(".swiper", {
                    // loop: true, // 循环模式选项
                    slidesPerView: "auto",
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    observer: true,
                    observeParents: true,
                });
            });
        },
        slidePrevNext(i){
            if(i===0){
                this.mySwiper.slidePrev();
            }else if(i===1){
                this.mySwiper.slideNext();
            }
            this.activeIndex = this.mySwiper.activeIndex;
        }
    }
    
}
</script>
<style lang="less" scoped>
.goodsView {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
   
    .slidePrev,
    .slideNext {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 580px;
        background: #55738B63;
    }
    .slidePrev {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    .slideNext {
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
    .slidePrev i,
    .slideNext i{
        display: block;
        width: 18px;
        height: 23px;
    }

    .slidePrev i {
        background: url('~@/assets/phone/icon03.png') no-repeat center center;
        background-size: 100% 100%;
    }
    .slideNext i {
        background: url('~@/assets/phone/icon03.png') no-repeat center center;
        background-size: 100% 100%;
        transform: rotate(180deg);
    }
 
    .goodsSwiperView {
        width: 1260px;
        height: 680px;
        border-radius: 30px;
        border: 3px solid rgba(255,255,255,0.2);
        overflow: hidden;

        .swiper {
            width: 100%;
            height: 100%;
            .item {
                width: 100%;
                height: 100%;
            }
        }
    }

}
.asd {
    text-align: center;
    font-size: 80px;
    font-weight: 900;
    color: red;
}
</style>