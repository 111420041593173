<template>
    <div >
        <div class="addresslist">

            <div class="addressitem" 
                v-for="(item) in addresslist" 
                :key="item.addressId"
                @click="onactiveItem(item.addressId)"
            >
                <div class="orderAddres">
                    <div>
                        <i v-if="item.isDefault==1">默认</i>
                        <p>
                            <span>{{item.provinceName}}</span>
                            <span>{{item.cityName}}</span>
                            <span>{{item.districtName}}</span>
                        </p>
                    </div>
                    <div>
                        <p> {{item.content}}</p>
                    </div>
                    <div>
                        <p><span>{{item.contact}}</span><span>{{item.telephone}}</span></p>
                    </div>
                    <div 
                        class="defaultIcon"
                        :class="item.addressId == activeindex?'active_defaultIcon':''"
                    ></div>
                </div>
            </div>

            <div class="addAddressitem" @click="onaddAddress">
                <span>新增地址</span>
                <i class="el-icon-arrow-right"></i>
            </div>

        </div>

        <div class="addAddressBtn" @click="onSure">
            确定
        </div>

      
    </div>
</template>
<script>

import { 
    getAddressList,
} from "@/utils/http.js";
export default {
    data() {
        return {
            addresslist:[],
            activeitem:null,
            activeindex:null
        }
    },
    created(){
        if(localStorage.getItem('addressId')){
            this.activeindex = localStorage.getItem('addressId')
        }
        this.getAddress()
    },
    methods:{
        onaddAddress(addressId){
            this.$router.push({path:'/verphone/editaddress',query:{addressId}})
        },
        async getAddress(){
            let {data,status} = await getAddressList();
            if(status!=0)return;
            this.addresslist=data.addressList;
        },
        onactiveItem(index){
            this.activeindex = `${index}`;
        },
        onSure(){
            if(!this.activeindex)return this.$message({message: '请选择地址',type: 'warning'});
            localStorage.setItem('addressId',this.activeindex)
            this.$router.back();
        }
    }
    
}
</script>
<style lang="less" scoped>
.addresslist {
    width: 100%;
    height: 95vh;
    padding: 70px 50px 300px;
    overflow-y: auto;
    background-color: #3A3A47;
    .addressitem {
        position: relative;
        width: 100%;
        border-radius: 40px;
        background-color: #1D2533;
        margin-bottom: 56px;
        .orderAddres {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 440px;
            padding: 60px;
            font-size: 52px;
            font-weight: 800;
            color: #fff;

            >div:nth-child(1){
                display: flex;
                align-items: center;
                p{
                    width: 90%;
                }
                i{
                    display: block;
                    width: 160px;
                    height: 80px;
                    background: #F88503;
                    border-radius: 20px;
                    font-size: 48px;
                    font-weight: 800;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                    font-style: normal;
                    margin-right: 50px;
                }
            }
            >div:nth-child(2){
                font-size: 68px;
            }
            span{
                font-size: 48px;
                color: #999999;
                margin-right: 25px;
            }
        }

        .defaultIcon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #fff;
        }

        .active_defaultIcon{
            background: url('~@/assets/phone/iconres.png') no-repeat center center;
            background-size: 100% 100%;
        }

    }

    .addAddressitem {
        text-align: center;
        height: 100px;
        line-height: 100px;
        font-size: 48px;
        color: #F88503;
    }
}

.addAddressBtn{
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 176px;
    background: #F88503;
    border-radius: 88px;
    text-align: center;
    line-height: 176px;
    font-size: 60px;
    font-weight: 800;
    color: #FFFFFF;
}

.confirmview {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000067;
    z-index: 9999;
    .confirmbox {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 900px;
        height: 550px;
        background-color: #3A3A47;
        border-radius: 40px;
        .confirmTitle {
            font-size: 64px;
            font-weight: 800;
            color: #fff;
        }

        .confirmBtn {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            div {
                width: 200px;
                height: 110px;
                text-align: center;
                line-height: 110px;
                font-size: 48px;
                border-radius: 20px;
            }
            >div:nth-child(1){
                background-color: #fff;
            }
            >div:nth-child(2){
                background-color: #F88503;
                color: #fff;
            }
        }

    }
}
    
</style>