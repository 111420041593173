<template>
    <div>
        <div class="orderView">
            <div class="orderViewLeft">
                <div class="addressList">
                    <div 
                        class="addressListItem"
                        v-for="(item,index) in addresslist"
                        :key="item.addressId"
                        :class="activeindex==index?'defaultItem':''"
                        @click="onActiveAddress(item,index)"
                    >
                        <div class="title">
                            <div class="defaultIcon" v-if="item.isDefault==1">默认</div>
                            <p>
                                <span>{{ item.provinceName }}</span>&nbsp;
                                <span>{{ item.cityName }}</span>&nbsp;
                                <span>{{ item.districtName }}</span>
                            </p>
                        </div>
                        <div class="titlename"><p>{{ item.content }}</p></div>
                        <div class="titleinp">
                            <p><span>{{ item.contact }}</span><span>{{ item.telephone }}</span></p>
                            <div></div>
                        </div>
                    </div>

                </div>
                <div class="addAddress" @click="goAddress">
                    <span>新增收货地址</span>
                    <i></i>
                </div>
            </div>
            <div class="orderViewRight">
                <div class="goback"  @click="$router.back()">
                </div>

                <div class="goodsimgtitle">
                    <div class="imgs">
                        <img :src="Oederinfo.image">
                    </div>
                    <div class="goodstitle">
                        <p>{{ Oederinfo.title }}</p>
                        <p><span><i>￥</i>{{ Oederinfo.price }}</span></p>
                    </div>
                </div>

                <div class="goodsorderinfo">
                    <div class="goodsorderinfoitem">
                        <div>规格</div>
                        <div><span>{{ Oederinfo.skuValue }}</span></div>
                    </div>
                    <div class="goodsorderinfoitem">
                        <div>数量</div>
                        <div><span>{{ Oederinfo.number }}</span></div>
                    </div>
                    <div class="goodsorderinfoitem">
                        <div>运费</div>
                        <div><span class="yufei">￥{{ Oederinfo.courierFee }}</span></div>
                    </div>
                </div>

                <div class="onlyview">
                    <div class="onlyviewje">
                        总计：￥{{ Oederinfo.disbursements }}
                    </div>
                    <div class="onlyviewBtn" @click="onPay">
                        购买
                    </div>
                </div>
            </div>
        </div>

        <div class="codeView" v-if="isCode">
            <div class="codeimgview">
                <p class="codeViewtitle">支付宝扫码</p>
                <div class="codeimg">
                    <img :src="codeImgUrl" alt="">
                </div>
                <p class="codeViewtips">支付成功后，请关闭支付窗口</p>

                <div class="clearBtn" @click="onCode">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import QRCode from "qrcode";
import { 
  buyGoods,
  checkPay,
  getAddressList,
  confirmBuyGoods
 } from "@/utils/http.js";

export default {
    data() {
        return {
            codeImgUrl:'',
            addresslist:[],
            activeaddress:{},
            activeindex:null,
            Oederinfo:{},
            isCode:false,
            count:0,
            timer:null,
        }
    },
    created(){
        this.getaddresslist()
        this.getOederinfo()
    },
    methods:{
        async getaddresslist(){
            let {data,status} = await getAddressList();
            if(status!=0)return;
            this.addresslist=data.addressList;

            this.addresslist.forEach((item,index)=>{
                if(item.isDefault==1){
                    this.activeaddress = item;
                    this.activeindex = index;
                }
            })
        },
        getOederinfo(){
            confirmBuyGoods(this.$route.query).then(({status,data})=>{
                if(status!==0)return;
                this.Oederinfo = data;
            })
        },
        onActiveAddress(itme,i){
            this.activeaddress = itme;
            this.activeindex = i;
        },
        goAddress(){
            this.$router.push({path:'/phone/addressinp'})
        },
        onPay(){
            const codeCount = 0;
            let params = {
                addressId: this.activeaddress.addressId,
                goodsId: this.Oederinfo.goodsId,
                number: this.Oederinfo.number,
                sid: this.activeaddress.districtId,
                unique: this.Oederinfo.unique
            }
            
            buyGoods(params).then(({status,data})=>{
                if(status!==0)return;
                this.toCanvas(data.html)

                this.count = codeCount;
                this.timer = setInterval(() => {
                    this.callback(data.outTradeNo);
                    if (this.count >= 28800) {
                        clearInterval(this.timer);
                        this.timer = null;
                    } else {
                        this.count++;
                    }
                }, 3000);       
            },(err)=>{
                this.$message({message: err.msg,type: 'warning'})
            })
        },
        callback(outTradeNo) {
            let data = { outTradeNo:outTradeNo };
            checkPay(data).then((res) => {
                this.isCode = false;
                clearInterval(this.timer);
                this.$router.push({path:'/phone/phonehome',query:{
                    artId:localStorage.getItem('artId')
                }})
            })
        },
        onCode (){
            this.isCode = false;
            clearInterval(this.timer);
        },
        toCanvas(code) {
            QRCode.toDataURL(
                code,
                { errorCorrectionLevel: "L", margin: 2, width: 400 },
                (error, url) => {
                if (error) console.log(error);
                    this.codeImgUrl = url;
                    this.isCode = true;
                }
            );
        },
    }
    
}
</script>
<style lang="less" scoped>
.orderView {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1402px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .orderViewLeft {
        width: 530px;
        height: 656px;
        background: linear-gradient(135deg, #446D86 0%, #384E62 100%);
        border-radius: 30px;
        border: 3px solid rgba(255,255,255,0.2);
        padding: 15px;

        .addressList {
            height: 550px;
            overflow-y: auto;
            .addressListItem {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                min-height: 219px;
                background: #23425B;
                border-radius: 19px;
                margin-bottom: 10px;
                padding: 26px;

                .title {
                    display: flex;
                    align-items: center;
                    .defaultIcon {
                        width: 80px;
                        height: 40px;
                        background: linear-gradient(265deg, #F88503 0%, #FFB561 100%);
                        border-radius: 10px;
                        text-align: center;
                        line-height: 40px;
                        font-size: 24px;
                        font-weight: 800;
                        color: #FFFFFF;
                        margin-right: 15px;
                    }

                    p{
                        font-size: 26px;
                        font-weight: 800;
                        color: #FFFFFF;
                    }
                }

                .titlename {
                    font-size: 34px;
                    font-weight: 800;
                    color: #FFFFFF;
                }
                .titleinp {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 24px;
                    font-weight: 800;
                    color: #CCCCCC;
                    p{
                        span {
                            margin-right: 20px;
                        }
                    }

                    >div {
                        width: 40px;
                        height: 40px;
                        background: #ffffff3a;
                        border-radius: 50%;
                    }
                }
            }

            .defaultItem {
                .titleinp {
                    >div {
                        background: url('../../../assets/phone/icon06.png') no-repeat center center;
                        background-size: 21px 15px;
                        background-color: #F88503;
                    }
                }
            }
        }

        .addAddress {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 24px;
            font-weight: 800;
            color: #F9890A;
            margin: 25px 0px;

            i {
                display: block;
                width: 9px;
                height: 15px;
                margin-left: 13px;
                background: url('../../../assets/phone/icon01.png') no-repeat center center;
                background-size: 100% 100%;
            }
        }
    }
    .orderViewRight {
        position: relative;
        width: 841px;
        height: 656px;
        background: linear-gradient(135deg, #446D86 0%, #384E62 100%);
        border-radius: 30px;
        border: 3px solid rgba(255,255,255,0.2);

        .goback{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 60px;
            height: 60px;
            background: url('~@/assets/phone/icon04.png') no-repeat center center;
            background-size: 100% 100%;
        }

        .goodsimgtitle {
            display: flex;
            align-items: center;
            padding: 33px;
            .imgs {
                width: 195px;
                height: 156px;
                border-radius: 14px ;
                margin-right: 31px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .goodstitle {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 156px;

                p {
                    font-size: 36px;
                    font-weight: 800;
                    color: #FFFFFF;
                    span {
                        font-size: 40px;
                        font-weight: bold;
                        color: #FF0000;
                        i {
                            font-size: 24px;
                            font-style: normal;
                        }
                    }
                }

            }

        }

        .goodsorderinfo {
            font-size: 26px;
            font-weight: bold;
            color: #CCCCCC;
            border-bottom: 1px solid #ffffff62;
            border-top: 1px solid #ffffff62;
            .goodsorderinfoitem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 25px;
                span {
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
                .yufei {
                    font-size: 30px;
                    font-weight: bold;
                    color: #FF0000;
                }
            }
        }

        .onlyview {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 33px;

            .onlyviewje {
                font-size: 36px;
                font-weight: 800;
                color: #FF0000;
            }

            .onlyviewBtn {
                width: 200px;
                height: 80px;
                background: #D5A223;
                border-radius: 40px;
                line-height: 80px;
                text-align: center;
                font-size: 34px;
                font-weight: 800;
                color: #FFFFFF;
            }
        }

    }
}
.codeView {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000090;
    z-index: 9999;
    .codeimgview {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 450px;
        height: 600px;
        background-color: #ffffff;
        border-radius: 20px;
        color: #333333;

        .codeViewtitle {
            font-size: 24px;
            font-weight: 800;
            color: #333333;
        }

        .codeimg {
            width: 400px;
            height: 400px;
            margin-top: 40px;
            margin-bottom: 25px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .codeViewtips {
            font-size: 18px;
            color: #cccccc;
        }

        .clearBtn {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 30px;
            height: 30px;
            color: #000000;
        }

    }
}

    
</style>