var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goodsSwitemView" }, [
    _c("div", { staticClass: "goodstop" }, [
      _c("div", { staticClass: "goodsimg" }, [
        _c("div", { ref: "itemImg", staticClass: "imgbox" }, [
          !_vm.shearImg
            ? _c("img", { attrs: { src: _vm.goodsinf.image } })
            : _vm._e(),
          _vm.shearImg ? _c("img", { attrs: { src: _vm.shearImg } }) : _vm._e(),
        ]),
        _c("div", { staticClass: "imgicon", on: { click: _vm.goinfo } }, [
          _c("span", [_vm._v("物品详情")]),
          _c("i"),
        ]),
      ]),
      _c("div", { staticClass: "goodsdata" }, [
        _c("div", { staticClass: "goodstitle" }, [
          _c("p", [_vm._v(_vm._s(_vm.goodsinf.title))]),
        ]),
        _c("div", { staticClass: "goodsPriceNum" }, [
          _c("div", [
            _c("span", { staticClass: "goodsPrice" }, [
              _c("i", [_vm._v("￥")]),
              _vm._v(_vm._s(_vm.activeSku.price)),
            ]),
            _vm.activeSku.isDiscount
              ? _c("span", { staticClass: "goodsPriceold" }, [
                  _vm._v("￥" + _vm._s(_vm.activeSku.originalPrice)),
                ])
              : _vm._e(),
          ]),
          _c("div", [
            _c("span", [
              _vm._v(
                "库存：" +
                  _vm._s(_vm.activeSku ? _vm.activeSku.number : "") +
                  "份"
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "goodsSpe" },
          _vm._l(_vm.goodsinf.specLists, function (item, index) {
            return _c("SpecificationItem", {
              key: index,
              attrs: { item: item },
              on: {
                speciChange: function ($event) {
                  return _vm.speciChange($event, index)
                },
              },
            })
          }),
          1
        ),
        _c("div", { staticClass: "goodsNum" }, [
          _c("div", { staticClass: "goodstips" }, [_vm._v("数量：")]),
          _c("div", { staticClass: "numBtn" }, [
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.btnNum(-1)
                  },
                },
              },
              [_vm._v("-")]
            ),
            _c("div", [_vm._v(_vm._s(_vm.goodsnum))]),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.btnNum(+1)
                  },
                },
              },
              [_vm._v("+")]
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "goodsbottom" }, [
      _c("div", { staticClass: "roterBtn", on: { click: _vm.back } }, [
        _c("i"),
      ]),
      _c("div", { staticClass: "goodsPrice" }, [
        _c("span", [_vm._v("总计：￥" + _vm._s(_vm.price))]),
        _c("div", { on: { click: _vm.buyRouter } }, [_vm._v("购买")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }