<template>
    <div class="popUpView">

        <div class="box">
            <div class="box_head">
                <span>{{ title }}</span>
                <div class="box_head_btn">
                    <div class="btn_no" @click="clearpPop">取消</div>
                    <div class="btn_yse" @click="onSureitem">确定</div>
                </div>
            </div>
            <div class="box_body">
                <el-tree :data="activeChildren" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:[
        'activeChildren',
        'title'
    ],
    data() {
      return {
        activeitem:null,
        defaultProps: {
          children: 'children',
          label: 'title'
        }
      };
    },
    methods: {
        onSureitem(){
            this.$emit('onSureitem',this.activeitem)
        },
        clearpPop(){
            this.$emit('onclearpPop')
        },
        handleNodeClick(data) {
            this.activeitem = data;
        }
    }
    
}
</script>
<style lang="less" scoped>
.popUpView {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000066;
    z-index: 999;

    .box {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        background-color: #3a3a47;
        border-radius: 80px 80px 0px 0px;
        .box_head {
            display: flex;
            justify-content: space-between;
            height: 180px;
            padding: 0px 50px;
            border-bottom: 1px solid #F88503;
            line-height: 180px;
            font-size: 56px;
            font-weight: 800;
            color: #fff;
            .box_head_btn {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                >div{
                    width: 200px;
                    height: 90px;
                    background: #fff;
                    text-align: center;
                    line-height: 90px;
                    margin-right: 50px;
                    border-radius: 20px;
                }
                .btn_yse {
                    color: #fff;
                    background: #F88503;

                }
            }
        }
        .box_body {
            width: 100%;
            height: 40vh;
            overflow-y: auto;
            /deep/.el-tree{
                background: #3a3a47;
                color: #fff;
                .el-tree-node__content:hover,
                .el-upload-list__item:hover {
                    background-color: #F88503;
                }
            }
        }
    }
}

</style>