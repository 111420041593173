// const isMobile =navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
// 解决ie白屏问题
import '@babel/polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index'

import './components/Toast/index.css';
import Toast from './components/Toast/index';
Vue.use(Toast);

import 'lib-flexible'
import request from './utils/request'

import './permission'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.less'
// import Vant from 'vant';
Vue.use(ElementUI);

import Plugins from './utils/plugins'
Vue.use(Plugins)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

Vue.prototype.$http = request
Vue.config.productionTip = false

import Logoin from "@/pages/phone/common/logoin.vue"
Vue.component('Login',Logoin)

import { pullDown } from "@/utils/directive"
Vue.directive('pull',pullDown)

// 跳转后返回顶部
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

new Vue({
	router,
	store,
	el: '#app',
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
