<template >
    <div class="order_list">
        <orderItem
            v-for="item  in datalist"
            :key="item.orderSn" 
            :itemdata="item"
            :status="item.status"
            @onPay="onPay(item)"
            @delOrderData = "delOrderData(item)"
            @receiveGoods = "receiveGoods(item)"
        ></orderItem>
  
        <div class="paging">
            <el-pagination
                background
                @current-change="getGoodsListsBy"
                :current-page="page"
                layout="prev, pager, next"
                :page-count="totalPages"
            >
            </el-pagination>
        </div>
    
        <el-dialog title="支付宝扫码" :visible.sync="codeShow" width="30%" @close="onClose">
            <img class="code" :src="codeImg" />
            <div class="tips">支付成功后，请关闭支付窗口</div>
        </el-dialog>
    </div>
  </template>
  <script>
  import QRCode from "qrcode";
  import orderItem from '@/pages/phone/common/orderItem.vue'

  import {
    getOrdersList,
    delOrder,
    confirmReceipt,
    orderBuyGoods,
    checkPay
  } from '@/utils/http.js'
  
  export default {
    data(){
      return{
        datalist:[],
        page:1,
        totalPages:null,
        codeShow:false,
        codeImg:'',
        count: "",
        timer: null,
  
      }
    },
    components:{
      orderItem
    },
    created() {
      this.getdatalist()
    },
    methods:{
      async getdatalist(){
        let res = await getOrdersList({page:this.page});
        if(res.status!=0)return;
        this.datalist = res.data.list;
        this.totalPages = res.data.totalPages;
      },
  
      onPay(item){
        const codeCount = 0;
        let data = { 
          orderId:item.orderId
        };
  
        this.openFullScreen()
        orderBuyGoods(data).then((res) => {
            this.loading.close();
            if (res.status == 0) {
              this.toCanvas(res.data.html);
  
              // 将定时器名字赋值到变量中           
              this.count = codeCount;
              this.timer = setInterval(() => {
                this.callback(res.data.outTradeNo);
                if (this.count >= 28800) {
                  clearInterval(this.timer);
                  this.timer = null;
                  this.showWxCode = false;
                } else {
                  this.count++;
                }
              }, 3000);            
            }
          })
          .catch((err) => {
            this.loading.close();
            this.$message(err.msg);
          });
      },
      toCanvas(code) {
        QRCode.toDataURL(
          code,
          { errorCorrectionLevel: "L", margin: 2, width: 400 },
          (error, url) => {
            if (error) console.log(error);
            this.codeImg = url;
            this.codeShow = true;
          }
        );
      },
      callback(outTradeNo) {
        let data = { outTradeNo:outTradeNo };
        checkPay(data).then((res) => {
            this.codeShow = false;
            clearInterval(this.timer);
          })
      },
      onClose(){
        this.codeShow = false;
        clearInterval(this.timer);
      },
      openFullScreen() {
         this.loading = this.$loading({
            lock: true,
            text: '加载中，请耐心等待...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
      },
  
      getGoodsListsBy(i){
        this.page = i;
        this.getdatalist()
        window.scrollTo(0,0) 
      },
      //确认收货
      receiveGoods(item){
        confirmReceipt({orderId:item.orderId})
      },
      // 删除订单
      delOrderData(item){
        this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            delOrder({orderId:item.orderId}).then((res)=>{
              if(res.status==0){
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
                this.getdatalist()
              }
            })
          });
      }
  
    }
    
  }
  </script>
  
  <style lang="less" scoped>
  .order_list {
      ::v-deep.paging {
        width: 100%;
        margin-top: 20px;
        .el-pagination {
          text-align: center;
        }
        .el-pager li {
          font-size: 16px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-weight: normal;
          background-color: white;
        }
        .el-pager li:not(.disabled).active {
          background-color: #d71a18;
          color: #fff;
        }
        .btn-next,
        .btn-prev {
          background-color: white;
          font-size: 16px;
          width: 40px;
          height: 40px;
          line-height: 40px;
          font-weight: normal;
        }
      }
  
      /deep/.el-dialog__wrapper {
      .el-dialog {
        width: 500px !important;
        border-radius: 10px;
        .el-dialog__header {
          text-align: center;
          font-weight: 700;
        }
        .el-dialog__body {
          padding: 0;
          padding-bottom: 30px;
        }
      }
      .code {
        width: 500px;
        height: 500px;
      }
      .tips {
        text-align: center;
      }
    }
  
  }
  
    
  </style>