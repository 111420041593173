var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "infoView" }, [
    _c("div", { staticClass: "clearbtn", on: { click: _vm.back } }),
    _c("div", { staticClass: "info_header" }, [
      _c("div", { staticClass: "infoImg" }, [
        _c("img", { attrs: { src: _vm.datainfo.image } }),
      ]),
      _c("div", { staticClass: "infotitlepric" }, [
        _c("div", [_c("p", [_vm._v(_vm._s(_vm.datainfo.title))])]),
        _c("div", [
          _c("p", [
            _c("span", [
              _c("i", [_vm._v("￥")]),
              _vm._v(_vm._s(this.$route.query.activeSku.split(",")[0])),
            ]),
            this.$route.query.activeSku.split(",")[1]
              ? _c("span", { staticClass: "priceold" }, [
                  _c("i", [_vm._v("￥")]),
                  _vm._v(_vm._s(this.$route.query.activeSku.split(",")[1])),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _c("div", {
      staticClass: "info_main",
      domProps: { innerHTML: _vm._s(_vm.datainfo.content) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }