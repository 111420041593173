var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "listViewitem" }, [
    _c("div", { staticClass: "orderinfo", on: { click: _vm.goOrderinfo } }, [
      _c("div", { staticClass: "orderinfoimg" }, [
        _c("img", { attrs: { src: _vm.item.image, alt: "" } }),
      ]),
      _c("div", { staticClass: "orderinfo_Comtent" }, [
        _c("div", { staticClass: "orderinfo_Comtent_Title" }, [
          _c("p", [_vm._v(_vm._s(_vm.item.title))]),
        ]),
        _c("div", { staticClass: "orderinfo_gg" }, [
          _c("span", [_vm._v(_vm._s(_vm.item.skuValue))]),
        ]),
        _c("div", { staticClass: "orderinfo_num_Price" }, [
          _c("div", { staticClass: "orderinfo_Price" }, [
            _c("span", [_vm._v("￥")]),
            _vm._v(_vm._s(_vm.item.totalPayment)),
          ]),
          _c("div", { staticClass: "orderinfo_num" }, [
            _vm._v("x" + _vm._s(_vm.item.goodsNumber)),
          ]),
        ]),
      ]),
      _c("i", {
        staticClass: "delOrderbox",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.delOrderData.apply(null, arguments)
          },
        },
      }),
    ]),
    _c("div", { staticClass: "orderinfobtn" }, [
      _c("div", { staticClass: "orderinfobtn_text" }, [
        _vm.countDownDate != 0
          ? _c("span", { staticClass: "datetext" }, [
              _vm._v(_vm._s(_vm.countDownDate) + "后取消订单"),
            ])
          : _vm._e(),
        _vm.countDownDate == 0
          ? _c("span", [_vm._v(_vm._s(_vm.statustext[_vm.orderStatus]))])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "orderinfobtn_btn" }, [
        _vm.orderStatus == 0
          ? _c("div", { staticClass: "itembtn", on: { click: _vm.onPay } }, [
              _vm._v("立即支付"),
            ])
          : _vm._e(),
        _vm.orderStatus == 2
          ? _c(
              "div",
              { staticClass: "itembtn", on: { click: _vm.receiveGoods } },
              [_vm._v("确认收货")]
            )
          : _vm._e(),
        _vm.orderStatus == 3
          ? _c("div", { on: { click: _vm.goApplyfor } }, [_vm._v("退换货")])
          : _vm._e(),
        _vm.orderStatus == 4
          ? _c("div", { on: { click: _vm.goExpressDelivery } }, [
              _vm._v("退换货申请"),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }