var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "goodsView" }, [
    _c(
      "div",
      {
        staticClass: "slidePrev",
        on: {
          click: function ($event) {
            return _vm.slidePrevNext(0)
          },
        },
      },
      [_c("i")]
    ),
    _c("div", { staticClass: "goodsSwiperView" }, [
      _c("div", { staticClass: "swiper swiper-container" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          _vm._l(_vm.goodslist, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "swiper-slide item" },
              [
                index === _vm.activeIndex
                  ? _c("goodsSwitem", { attrs: { goods: item } })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "slideNext",
        on: {
          click: function ($event) {
            return _vm.slidePrevNext(1)
          },
        },
      },
      [_c("i")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }