<template>
    <div class="bigbox w">
      <div class="menu">
        <ul>
          <li :class="item.active==1?'activeItem':''" v-for="item in menuArr" :key="item.path">
            <router-link :to="item.path">
              <span>{{ item.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>

      <div class="body_main">
        <router-view></router-view>
      </div>

      <div class="goback"  @click="goback">
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        menuArr:[
          {
            name:'订单管理',
            path:'/phone/personagecentre/orderlist',
            active:0
          },{
            name:'地址管理',
            path:'/phone/personagecentre/addresslist',
            active:0
          }
        ]
      }
    },
    methods:{
      goback(){
        this.$router.push({path:'/phone/phonehome',query:{artId:this.$route.query.artId}})
      }
    },
    watch:{
      $route:{
        handler:function(newval){
          this.menuArr=this.menuArr.map((item)=>{
            if(item.path==newval.path){
              item.active=1;
              return item;
            }else {
              item.active=0;
              return item;
            }
          })
        },
        immediate: true
      }
    }
  }
  </script>
  <style lang="less" scoped>
  .w {
    margin: auto;
    margin-top: 50px;
  }
  .bigbox {
    display: flex;
    justify-content: space-between;
  }
  .menu {
    margin-right: 29px;
    ul {
      li {
        margin-bottom: 17px;
        a {
            display: block;
            width: 300px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 34px;
            font-weight: 800;
            border-radius: 10px;
            color: #FFFFFF;
            background: linear-gradient(133deg, #6B6B8B 0%, #2D2D35 100%);
            border: 1px solid rgba(255,255,255,0.19);
        }

       
      }
      .activeItem {
        a{
            background: #DFA923;
            border: 1px solid #DFA923;
        }
      }
      
    }
  
  }
  .body_main {
    width: 1088px;
  }

  .goback{
        position: fixed;
        left: 40px;
        bottom: 40px;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        background: url('~@/assets/phone/icon04.png') no-repeat center center;
        background-size: 100% 100%;
        z-index: 999;
    }
    
  </style>