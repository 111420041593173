<template >
    <div class="popup_box" @click.stop="clearpop">

        <div class="select_box" @click.stop>
            <div class="select_box_head">
                <div 
                    :class="isSelectNum==0?'isactive':''"
                >
                    {{provinceObj?provinceObj.label:'请选择地区'}}
                </div>
                <div 
                    v-if="provinceObj"
                    :class="isSelectNum==1?'isactive':''"
                >
                    {{cityObj?cityObj.label:'请选择地区'}}
                </div>
                <div 
                    v-if="cityObj"
                    :class="isSelectNum==2?'isactive':''"
                >
                    {{districtObj?districtObj.label:'请选择地区'}}
                </div>

                <div class="clearbtn" @click.stop="clearpop">取消</div>
            </div>

            <div class="citybox">
                <div 
                    class="city_item" 
                    v-for="(item,index) in selectlist"
                    :key="item.value"
                    :class="index==activeindex?'activecity_item':''"
                    @click="onActive(item,index)"
                >
                    <span>{{ item.label }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    regionData,
} from "element-china-area-data";
export default {
    data() {
        return {
            regionData,
            activeindex:null,
            selectlist:[],
            isSelectNum:0,
            provinceObj:null,//省
            cityObj:null,//市
            districtObj:null,//区
            resultArr:[]
        }
    },
    created(){
        this.selectlist = this.regionData;
    },
    methods:{
        clearpop(){
            this.$emit('clearPop')
        },
        onActive(item){
            this.selectlist = item.children;
            this.resultArr.push(item)
            if(this.isSelectNum==0){
                this.isSelectNum=1;
                this.provinceObj = item;
            }else if(this.isSelectNum==1){
                this.isSelectNum=2;
                this.cityObj = item;
            }else if(this.isSelectNum==2){
                this.districtObj = item;
                this.$emit('changSelect',this.resultArr)
            }
        },
        
    }
    
}
</script>
<style lang="less" scoped>
.popup_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #00000066;
    z-index: 9999;

    .select_box {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 1200px;
        background-color: #f5f5f5;
        border-radius:  80px 80px 0px 0px;

        .select_box_head {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 200px;
            padding: 0px 70px;
            font-size: 48px;
            font-weight: 800;
            line-height: 200px;
            border-bottom: 1px solid #cccccc94;
            >div {
                margin-right: 50px;
                height: 200px;
            }
            .isactive{
                position: relative;
                color: #F88503;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 150px;
                    height: 10px;
                    background-color: #F88503;
                    
                }

            }

            .clearbtn {
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
                width: 180px;
                height: 100px;
                background-color: #F88503;
                border-radius: 20px;
                text-align: center;
                line-height: 100px;
                color: #fff;
                
            }
        }

        .citybox {
            width: 100%;
            height: 1000px;
            overflow-y: auto;
            .city_item {
                height: 150px;
                padding: 0px 80px;
                line-height: 150px;
                font-size: 48px;
                font-weight: 800;
                color: #333;
            }
            .activecity_item{
                background: #f8860361;
            }
        }
    }

    
}
    
</style>