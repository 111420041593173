import VueRouter from 'vue-router'
import Vue from 'vue'

import HasHeader from '@/layouts/index'
import Empty from '@/layouts/empty'

import Pc from './modules/pc'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const redirect = '/home';
const routes=[
	Pc,
	{ path: '/404', name: 'Notfound', component: () => import('@/pages/common/notfound') },
  { path: '*', name: 'Notfound', component: () => import('@/pages/common/notfound') },
]

const router = new VueRouter({
	routes
})

 
export default router