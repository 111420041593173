<template>
    <div 
        class="goodsitem" 
        @click="onGoodsitem"
    >
        <div class="goodsitemimg" ref="itemImg">
            <img :src="item.image" v-if="!shearImg">
            <img :src="shearImg" v-if="shearImg">
        </div>
        <div class="canvasbox" ref="canvasbox">
        </div>
        <div class="goodsname">
            <p>{{ item.title }}</p>
        </div>
        <div class="goodsPrice">
            <div class="goodsPricetrue">
                <p><span>￥</span>{{item.price}}</p>
            </div>
            <div class="goodsPricefalse" v-if="item.isDiscount">
                <p><span>￥</span>{{ item.originalPrice }}</p>
            </div>
        </div>
       
    </div>
</template>
<script>
import html2canvas from 'html2canvas';
import { compress } from '../../../utils/ysbase64';

export default {
    props:['item'],
    data() {
        return {
        shearImg:''
            
        }
    },
    mounted(){
        this.getshareImg();
    },
    methods: {
        onGoodsitem(){
            let Querydata = {
                goodsId:this.item.goodsId,
                dressId:this.item.dressId
            }
            if(this.$route.query.artId){
                Querydata['artId']=this.$route.query.artId
            }
            this.$router.push({path:'/phone/goodsSwiper',query:Querydata})
        },

        getshareImg(){
            let than=this;
            // 获取对应的DOM元素
            let element = this.$refs.itemImg;
            let canvasbox = this.$refs.canvasbox;
            const copyDom = element.cloneNode(true);

            copyDom.style.width = element.scrollWidth + 'px'
            copyDom.style.height = element.scrollHeight + 'px'

            canvasbox.appendChild(copyDom)

            setTimeout(()=>{
                    html2canvas(copyDom, {
                        width: element.scrollWidth,
                        height: element.scrollHeight,
                        useCORS: true
                    }).then((canvas) => {
                        canvasbox.removeChild(copyDom) // 用完要删除
                        console.log(than.shearImg);
                        compress(
                            canvas.toDataURL('image/png'),
                            1.5,
                            function(base64){
                                than.shearImg = base64;
                            }
                        )
                    })
            }, 0)
        },
        
    },
    
}
</script>
<style lang="less" scoped>
.goodsitem {
    position: relative;
    width: 340px;
    .goodsitemimg {
        height: 270px;
        border-radius: 14px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .goodsname {
        width: 100%;
        font-size: 30px;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 30px;
        margin: 15px 0px;
        p {
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
    .goodsPrice {
        display: flex;
        align-items: baseline;
        .goodsPricetrue {
            font-size: 40px;
            font-weight: 800;
            color: #FF0000;
            margin-right: 10px;
        }
        .goodsPricefalse {
            font-size: 24px;
            font-weight: 800;
            color: #666666;
            p {
                text-decoration: line-through;
            }
        }
    }
    .canvasbox{
        position: absolute;
        top: 0px;
    }
}


    
</style>