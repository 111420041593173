<template>
    <div>
        <div class="navbartitle" v-if="!$route.meta.istitlehide">
            <div class="navbartitleIcon" @click="back" v-if="!$route.meta.isTitleBack">
                <i class="el-icon-arrow-left"></i>
            </div>
            <span>{{ $route.meta.title }}</span>
            <div></div>
        </div>
        <div class="perch"></div>

        <router-view></router-view>

        <div class="navbarBox" v-if="$route.meta.isNavBar">
            <div 
                class="navbaiItem" 
                v-for="(item,index) in navbarlist" 
                :key="index"
                :class="$route.path==item.path?'activeItem':''"
                @click="gorouter(item.path)"
            >
                <img v-show="!($route.path==item.path)" :src="item.imgurl">
                <img v-show="$route.path==item.path" :src="item.activeimg">

                <span>{{ item.name }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>
import home from "../assets/NavBar/home.png";
import home_icon from "@/assets/NavBar/home_icon.png";
import my from "@/assets/NavBar/my.png";
import my_icon from "@/assets/NavBar/my_icon.png";

export default {
    data () {
        return {
            navbarlist:[
                {
                    name:'首页',
                    path:'/verphone/verphonehome',
                    imgurl:home_icon,
                    activeimg:home,
                },
                {
                    name:'我的',
                    path:'/verphone/my',
                    imgurl:my_icon,
                    activeimg:my,
                }
            ]
        }
    },
    methods:{
        gorouter(path){
            this.$router.push({path:path})
        },
        back(){
            this.$router.back()
        }
    
    }
}

</script>

<style lang='less' scoped>
.navbartitle {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 150px;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #3A3A47;
    font-size: 68px;
    font-weight: 800;
    color: #fff;
    z-index: 999;
}
.perch {
    width: 100%;
    height: 150px;
    background-color: #363E4B;
}
.navbarBox {
    position: fixed;
    bottom: 0px;
    display: flex;
    width: 100%;
    height: 200px;
    background: #3A3A47;
    .navbaiItem {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: bold;
        color: #999999;
        
        img {
            height: 65px;
            margin-bottom: 24px;
        }
    }

    .activeItem{
        color: #F88503;
    }
}

</style>
  