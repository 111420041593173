<template>
    <div class="listViewitem">
        <div class="orderinfo" @click="goOrderinfo">
            <div class="orderinfoimg">
                <img :src="item.image" alt="">
            </div>
            <div class="orderinfo_Comtent">
                <div class="orderinfo_Comtent_Title">
                    <p>{{ item.title }}</p>
                </div>
                <div class="orderinfo_gg">
                    <span>{{ item.skuValue }}</span>
                </div>
                <div class="orderinfo_num_Price">
                    <div class="orderinfo_Price"><span>￥</span>{{ item.totalPayment }}</div>
                    <div class="orderinfo_num">x{{ item.goodsNumber }}</div>
                </div>
            </div>
            <i class="delOrderbox" @click.stop="delOrderData"></i>
        </div>
        <div class="orderinfobtn">
            <div class="orderinfobtn_text">
                <span class="datetext" v-if="countDownDate!=0">{{countDownDate}}后取消订单</span>
                <span  v-if="countDownDate==0">{{ statustext[orderStatus] }}</span>
            </div>
            <div class="orderinfobtn_btn">
                <div v-if="orderStatus==0" @click="onPay" class="itembtn">立即支付</div>
                <div v-if="orderStatus==2" @click="receiveGoods" class="itembtn">确认收货</div>
                <div v-if="orderStatus==3" @click="goApplyfor">退换货</div>
                <div v-if="orderStatus==4" @click="goExpressDelivery">退换货申请</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:['item'],
    data() {
        return {
            statustext:{
                0:'待支付',
                1:'订单取消',
                2:'待收货',
                3:'已收货',
                4:'退换货',
                5:'审核中',
                6:'已退货',
            },
            orderStatus:null,
            countDownDate:0,
            Dateitem:null,
        }
    },
    created(){
        this.orderType()
        this.getPaidDate()
    },
    methods:{
        goExpressDelivery(){
            let query = {
                orderId:this.item.orderId,
            };
            // 退换货申请
            this.$router.push({path:'/verphone/returnGoods',query})
        },
        goApplyfor(){
            // 退换货
            let query = {
                orderId:this.item.orderId,
            };
            this.$router.push({path:'/verphone/returnnum',query})
        },
        receiveGoods(){
            // 确认收货
            this.orderStatus=3;
            this.$emit('receiveGoods')
        },
        delOrderData(){
            this.$emit('delOrderData')
        },
        onPay(){
            // 立即支付
            this.$emit('onPay')
        },
        goOrderinfo(){
            // 查看订单详情
            let query = {
                orderId:this.item.orderId,
                orderStatus:this.orderStatus,
            };
            
            this.$router.push({path:'/verphone/completeOrder',query})
        },
        getPaidDate(){
            this.countDownDate = this.item.countDown;
            if(this.countDownDate==0)return;
            this.Dateitem = setInterval(()=>{
                this.countDownDate-=1;
            },1000)
        },
        orderType(){
            // 待支付  待收货 已收货 退换货
            if(this.item.status==0){
                // 待支付
                this.orderStatus=0;
            }else if(this.item.status==1){
                // 已支付
                switch (this.item.isReceive) {
                case 0:
                    // 未收货
                    this.orderStatus=2;
                    break;
                case 1:
                    // 已收货
                    this.orderStatus=3;
                    break;
                case 3:
                    // 审核
                    this.orderStatus=5;
                    break;
                case 4:
                    // 退还货
                    this.orderStatus=4;
                    break;
                case 5:
                    // 已退货
                    this.orderStatus=6;
                    break;
                default:
                    break;
                }
                
            }else if(this.item.status==2){
                // 取消订单
                this.orderStatus=1;
            }

        },
    },
    watch:{
        countDownDate:function(newval){
            // 倒计时为0，取消订单
            if(newval==0){
                this.orderStatus = '1';
                clearInterval(this.Dateitem);
            }
        }
    }
    
}
</script>
<style lang="less" scoped> 
.listViewitem{
    background-color: #1D2533;
    border-radius: 40px;
    margin-bottom: 40px;
    .orderinfo {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 520px;
        padding: 36px 40px;
       
        .orderinfoimg {
            width: 540px;
            height: 450px;
            background: #fff;
            margin-right: 60px;
            border-radius: 30px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cove;
            }
        }
        .orderinfo_Comtent {
            width: 50%;
            .orderinfo_Comtent_Title {
                font-size: 56px;
                font-weight: 800;
                color: #FFFFFF;
                margin-bottom: 50px;
            }
            .orderinfo_gg {
                display: flex;
                flex-wrap: wrap;
                span {
                    padding: 10px 34px;
                    background-color: #303A4B;
                    margin-right: 30px;
                    margin-top: 30px;
                    border-radius: 15px;
                    font-size: 44px;
                    font-weight: 500;
                    color: #999999;
                }

            }
        }

        .orderinfo_num_Price {
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .orderinfo_Price {
                font-size: 100px;
                font-weight: 800;
                color: #FF0021;
                span{
                    font-size: 44px;
                }
            }
            .orderinfo_num {
                font-size: 48px;
                font-weight: 800;
                color: #F88503;
            }
        }

        .delOrderbox{
            position: absolute;
            top: 50px;
            right: 50px;
            width: 50px;
            height: 70px;
            background: url('~@/assets/phone/icon07.png') no-repeat center center;
            background-size: 100% 100%;
        }
    }
    .orderinfobtn {
        height: 200px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 50px;
        font-size: 48px;
        font-weight: 800;
        color: #FFFFFF;
        border-top: 1px solid #cccccc54;
        .datetext {
            color: #FF0021;
        }

        .orderinfobtn_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            >div{
                padding: 24px 36px;
                background-color: #3A3A47;
                border-radius: 60px;
                margin-right: 40px;
            }
            >div:last-child{
                margin-right: 0px;
            }

            .itembtn {
                background: #F98E14;
            }
        }
    }
}

    
</style>