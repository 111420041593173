<template>
    <div >
        <div class="addresslist">

            <div class="addressitem" 
                v-for="item in addresslist" 
                :key="item.addressId"
            >
                <div class="orderAddres">
                    <div>
                        <i v-if="item.isDefault==1">默认</i>
                        <p>
                            <span>{{item.provinceName}}</span>
                            <span>{{item.cityName}}</span>
                            <span>{{item.districtName}}</span>
                        </p>
                    </div>
                    <div>
                        <p> {{item.content}}</p>
                    </div>
                    <div>
                        <p><span>{{item.contact}}</span><span>{{item.telephone}}</span></p>
                    </div>
                </div>

                <div class="addressBtnbox">
                    <div class="defaultInp" @click="setdefault(item)">
                        <div 
                            class="defaultIcon"
                            :class="item.isDefault==1?'activeicon':''"
                        ></div>
                        <div>设为默认</div>
                    </div>

                    <div class="addressBtn">
                        <div @click="deleteAddress(item)">删除</div>
                        <div class="editbtn" @click="onaddAddress(item.addressId)">修改</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="addAddressBtn" @click="onaddAddress('')">
            新增地址
        </div>

        <div class="confirmview" v-if="isconfirm">
            <div class="confirmbox">
                <div class="confirmTitle">
                    删除后不可恢复,是否继续?
                </div>
                <div class="confirmBtn">
                    <div @click="onCancel">取消</div>
                    <div @click="onRes">确认</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { 
    getAddressList,
    setDefaultAddress,
    delAddress,
} from "@/utils/http.js";
export default {
    data() {
        return {
            addresslist:[],
            isconfirm:false,
            activeitem:null,
        }
    },
    created(){
        this.getAddress()
    },
    methods:{
        onCancel(){
            this.isconfirm = false;
            this.$message({
                type: 'info',
                message: '已取消删除'
            });  
        },
        onRes(){
            delAddress({addressId:this.activeitem.addressId}).then((res)=>{
                if(res.status==0){
                    this.isconfirm = false;
                    this.getAddress()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                    this.$message.error('删除失败!');
                }
            })
        },
        deleteAddress(item){
            this.isconfirm = true;
            this.activeitem = item;
        },
        onaddAddress(addressId){
            let query;
            if(addressId){
                query={
                    addressId
                }
            }
            this.$router.push({path:'/verphone/editaddress',query})
        },
        async getAddress(){
            let {data,status} = await getAddressList();
            if(status!=0)return;
            this.addresslist=data.addressList;
        },
        async setdefault(obj){
            this.addresslist = this.addresslist.map((item)=>{
                if(item.addressId==obj.addressId){
                    return {...item,isDefault:1}
                }else{
                    return {...item,isDefault:0}
                }
            })
    
            let data = await setDefaultAddress({...obj,isDefault:1});
            if(data.status==0){
                this.$message({
                    message: '设置成功',
                    type: 'success'
                });
            }else{
                this.$message.error('设置失败');
            }
        },
  
    }
    
}
</script>
<style lang="less" scoped>
.addresslist {
    width: 100%;
    height: 95vh;
    padding: 70px 50px 300px;
    overflow-y: auto;
    background-color: #3A3A47;
    .addressitem {
        width: 100%;
        border-radius: 40px;
        background-color: #1D2533;
        margin-bottom: 56px;
        .orderAddres {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 440px;
            padding: 60px;
            border-bottom: 1px solid #cccccc54;
            font-size: 52px;
            font-weight: 800;
            color: #fff;

            >div:nth-child(1){
                display: flex;
                align-items: center;
                p{
                    width: 90%;
                }
                i{
                    display: block;
                    width: 160px;
                    height: 80px;
                    background: #F88503;
                    border-radius: 20px;
                    font-size: 48px;
                    font-weight: 800;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 80px;
                    font-style: normal;
                    margin-right: 50px;
                }
            }
            >div:nth-child(2){
                font-size: 68px;
            }
            span{
                font-size: 48px;
                color: #999999;
                margin-right: 25px;
            }
        }

        .addressBtnbox {
            width: 100%;
            height: 196px;
            padding: 0px 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .defaultInp {
                display: flex;
                align-items: center;
                font-size: 52px;
                font-weight: 800;
                color: #FFFFFF;
                .defaultIcon {
                    width: 72px;
                    height: 72px;
                    background: #3A3A47;
                    border-radius: 50%;
                    margin-right: 38px;
                }
                .activeicon{
                    background: url(~@/assets/phone/iconres.png) no-repeat center center;
                    background-size: 100% 100%;
                }

            }

            .addressBtn {
                display: flex;
                align-items: center;
                >div {
                    width: 240px;
                    height: 120px;
                    background: #3A3A47;
                    border-radius: 60px;
                    font-size: 52px;
                    font-weight: 800;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 120px;
                    margin-left: 60px;
                }
                .editbtn{
                    background-color: #D5A223;
                }
                
            }


        }
    }
}

.addAddressBtn{
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 176px;
    background: #F88503;
    border-radius: 88px;
    text-align: center;
    line-height: 176px;
    font-size: 60px;
    font-weight: 800;
    color: #FFFFFF;
}

.confirmview {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000067;
    z-index: 9999;
    .confirmbox {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 900px;
        height: 550px;
        background-color: #3A3A47;
        border-radius: 40px;
        .confirmTitle {
            font-size: 64px;
            font-weight: 800;
            color: #fff;
        }

        .confirmBtn {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            div {
                width: 200px;
                height: 110px;
                text-align: center;
                line-height: 110px;
                font-size: 48px;
                border-radius: 20px;
            }
            >div:nth-child(1){
                background-color: #fff;
            }
            >div:nth-child(2){
                background-color: #F88503;
                color: #fff;
            }
        }

    }
}
    
</style>