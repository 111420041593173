var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar_box" }, [
    _c(
      "div",
      { staticClass: "navdarlist" },
      _vm._l(_vm.navbarlist, function (item) {
        return _c(
          "div",
          { key: item.categoryId, staticClass: "item" },
          [
            _c("treeitem", {
              attrs: { item: item },
              on: { onClicktree: _vm.onClicktree },
            }),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "Additionalitem", on: { click: _vm.goCentre } }, [
      _c("div", { staticClass: "icon" }, [
        _c("i"),
        _c("span", [_vm._v(_vm._s(_vm.islog ? "个人中心" : "登录"))]),
      ]),
      _vm._m(0),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "go_icon" }, [_c("i")])
  },
]
render._withStripped = true

export { render, staticRenderFns }