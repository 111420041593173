var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "return_Goods_Box" }, [
    _c("div", { staticClass: "goodsinfo_box" }, [
      _c("div", { staticClass: "orderinfoimg" }, [
        _c("img", { attrs: { src: _vm.goodsinfo.image, alt: "" } }),
      ]),
      _c("div", { staticClass: "orderinfo_Comtent" }, [
        _c("div", { staticClass: "orderinfo_Comtent_Title" }, [
          _c("p", [_vm._v(_vm._s(_vm.goodsinfo.title))]),
        ]),
        _c("div", { staticClass: "orderinfo_gg" }, [
          _c("span", [_vm._v(_vm._s(_vm.goodsinfo.skuValue))]),
        ]),
        _c("div", { staticClass: "orderinfo_num_Price" }, [
          _c("div", { staticClass: "orderinfo_Price" }, [
            _c("span", [_vm._v("￥")]),
            _vm._v(_vm._s(_vm.goodsinfo.price)),
          ]),
          _c("div", { staticClass: "orderinfo_num" }, [
            _vm._v("x" + _vm._s(_vm.goodsinfo.goodsNumber)),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "returnInp" }, [
      _c(
        "div",
        { staticClass: "return_reason_inp", on: { click: _vm.changselect } },
        [
          _c("div", [_vm._v("退货原因")]),
          _c("div", [
            !_vm.returnReason
              ? _c("p", { staticClass: "Reasontext" }, [
                  _vm._v("请选择退款原因"),
                ])
              : _vm._e(),
            _c("p", [_vm._v(_vm._s(_vm.returnReasontext))]),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]),
        ]
      ),
      _c("div", { staticClass: "return_text" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.returnDesc,
              expression: "returnDesc",
            },
          ],
          attrs: { placeholder: "点击此处输入详细的退款原因" },
          domProps: { value: _vm.returnDesc },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.returnDesc = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "updataInp" },
      [
        _c("div", [_vm._v("上传凭证图片")]),
        _c("updatainp", { ref: "updatainp" }),
      ],
      1
    ),
    _c("div", { staticClass: "submitBtn", on: { click: _vm.submitfrom } }, [
      _vm._v("\n        提交申请\n    "),
    ]),
    _vm.ispop
      ? _c("div", { staticClass: "popup_box", on: { click: _vm.clearPop } }, [
          _c(
            "div",
            {
              staticClass: "select_box",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "citybox" },
                _vm._l(_vm.keydata, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: item.value,
                      staticClass: "city_item",
                      class: index == _vm.activeitem ? "activecity_item" : "",
                      on: {
                        click: function ($event) {
                          return _vm.onSelect(item, index)
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(item.label))])]
                  )
                }),
                0
              ),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "select_box_head" }, [
      _c("div", [_vm._v("请选择退换货原因")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }