var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addresinp_view" }, [
    _c("div", { staticClass: "addresinp_view_title" }, [
      _c("span", [_vm._v("申请退换货")]),
      _c("div", {
        staticClass: "backbtn",
        on: {
          click: function ($event) {
            return _vm.$router.back()
          },
        },
      }),
    ]),
    _c("div", { staticClass: "addresinp_view_form" }, [
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c(
          "div",
          { staticClass: "inputbox inpwidth" },
          [
            _c("div", { staticClass: "xxdz" }, [_vm._v("退换货原因：")]),
            _c("selectInp", {
              attrs: { keydata: _vm.keydata, placeholder: "请选择退换货原因" },
              on: { change: _vm.selChange },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c(
          "div",
          { staticClass: "inputbox" },
          [
            _c("div", { staticClass: "xxdz" }, [_vm._v("上传图片：")]),
            _c("updatainp", { ref: "updatainp" }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap" }, [
        _c("div", { staticClass: "inputbox" }, [
          _c("div", { staticClass: "xxdz" }, [_vm._v("详情描述：")]),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.returnDesc,
                expression: "returnDesc",
              },
            ],
            attrs: { placeholder: "点击此处输入详细描述" },
            domProps: { value: _vm.returnDesc },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.returnDesc = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap center" }, [
        _c("button", { on: { click: _vm.submitfrom } }, [_vm._v("提交申请")]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }