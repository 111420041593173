<template>
    <div class="addresinp_view">
      <div class="addresinp_view_title">
        <span>新增收货地址</span>
        <div class="goback"  @click="$router.back()">
        </div>
      </div>
      <div class="addresinp_view_form">
  
        <div class="addresinp_view_row gap">
          <div class="inputbox">
            <div>收获人姓名：</div>
            <input v-model.trim="from.contact" type="text" placeholder="请在此处输入您的姓名(必填)">
          </div>
          
          <div class="inputbox">
            <div>收货人电话：</div>
            <input v-model.number="from.telephone" type="text" placeholder="请在此处输入您的电话(必填)">
          </div>
        </div>
  
        <div class="addresinp_view_row gap">
          <div class="inputbox">
            <div class="xxdz">收获地址：</div>
            <selectInp 
              placeholder="省/直辖市/自治区"
              :labelname="provinceName" 
              :keydata = "regionData"
              @change = "changeOption"
            ></selectInp>
            <selectInp 
              placeholder="地级市"
              :labelname="cityName" 
              :keydata = "cityData"
              @change = "changeCity"
            ></selectInp>
            <selectInp 
              placeholder="县/区"
              :labelname="districtName" 
              :keydata = "districtData"
              @change = "changeDistrict"
            ></selectInp>
          </div>
          
        </div>
  
        <div class="addresinp_view_row"> 
          <div class="inputbox">
            <div class="xxdz">详细地址：</div>
            <input v-model="from.content" type="text" placeholder="点击此处输入详细地址">
          </div>
        </div>
        
        <div class="addresinp_view_row gap">
          <button @click="setaddAddressd"> {{ addressId?'确认':'确认' }} </button>
        </div>
  
      </div>

    </div>
  </template>
  <script>
  import selectInp from "@/pages/pc/goodsOrder/common/selectInp.vue";
  import { 
    addAddress,
    getaddressInfo
   } from "@/utils/http.js";
   import {
    regionData,
  } from "element-china-area-data";
  export default {
    data() {
      return {
        from:{
          addressId:'0', //地址ID 新增操作为 0 不为0 则为更新操作
          contact:'', //联系人
          telephone:'',//手机号
          content:'',//地址详情
          provinceId:'',//省编号
          cityId:'',//市编号
          districtId:'',//区编号
          isDefault:'0'//是否为 默认地址 1是 0否
        },
        regionData,
        cityData:[],
        districtData:[],
        addressId:null,
        provinceName:'',
        cityName:'',
        districtName:'',
        reg_tel:/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
      }
    },
    components:{
      selectInp
    },
    created() {
      this.addressId=this.$route.query.addressId;
      if(this.addressId){
        this.getAddressinfo()
      }
    },
    methods: {
      // 添加地址
      async setaddAddressd(){
        if(!this.from.contact)return this.$message({ message: '请输入联系人',type: 'warning'});
        if(!this.from.districtId) return this.$message({ message: '请选择完整的地址',type: 'warning'});
        if(!this.reg_tel.test(this.from.telephone))return this.$message({ message: '请输入正确的电话',type: 'warning'});

        let data = await addAddress(this.from)
        if(data.status==0){
          this.$message({
            message: `${this.addressId?'修改成功':'添加成功'}`,
            type: 'success'
          });
          setTimeout(()=>{
            this.$router.back()
          },1500)
        }else{
          this.$message.error(`${this.addressId?'修改失败':'添加失败'}`);
        }

      },
      // 省
      changeOption(data){
        this.from.provinceId=`${data.value}0000`
        this.cityData=[];
        this.districtData = [];
        this.from.cityId='';
        this.from.districtId='';
        this.cityName = '';
        this.districtName = '';
        this.cityData=data.children;
      },
      // 市
      changeCity(data){
        this.from.cityId=`${data.value}00`
        this.districtData = [];
        this.districtName = '';
        this.from.districtId='';
        this.districtData = data.children;
      },
      // 区县
      changeDistrict(data){
        this.from.districtId=`${data.value}`
      },
      // 修改地址
      // 获取地址详情
      async getAddressinfo(){
        let {data,status} = await getaddressInfo({addressId:this.addressId});
        if(status!==0)return;
  
        for (const key in this.from) {
          this.from[key]=data.addressInfo[key];
        }
  
        this.provinceName = data.addressInfo.provinceName;
        this.cityName = data.addressInfo.cityName;
        this.districtName = data.addressInfo.districtName;
  
        let provinceIdstr =( `${this.from.provinceId}`).slice(0,2)
        let cityIdstr = `${this.from.cityId}`.slice(0,4)

        this.cityData = this.regionData.filter(item=>item.value == provinceIdstr)[0].children;
        this.districtData = this.cityData.filter(item=>item.value == cityIdstr)[0].children;
      }
  
  
    },
    
  }
  </script>
  <style lang="less" scoped>
  .addresinp_view {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1410px;
    height: 656px;
    border-radius: 30px;
    border: 3px solid rgba(255,255,255,0.6);
    overflow: hidden;

    .addresinp_view_title {
        position: relative;
        height: 100px;
        background: #3F4C67;
        font-size: 30px;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;     
        line-height: 100px; 

        .goback{
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
            width: 60px;
            height: 60px;
            background: url('~@/assets/phone/icon04.png') no-repeat center center;
            background-size: 100% 100%;
        }
    }
  
    .addresinp_view_form {
        height: 86%;
        padding: 49px 39px 30px;
        background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
        .addresinp_view_row {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 40px;
            .inputbox {
                flex: 1;
                display: flex;
                height: 100px;
                justify-content: center;
                align-items: center;
                >div {
                    margin-right: 18px;
                }
                >div:last-child {
                    margin-right: 0px;
                }
                .xxdz {
                    margin-right: 30px;
                }
        
                input {
                    flex: 1;
                    height: 100%;
                    background: #3A3A3A;
                    border-radius: 8px;
                    border: 1px solid #666666;
                    text-indent: 20px;
                    font-size: 28px;
                    font-weight: 500;
                    color: #FFFFFF;
                    &::placeholder{
                        text-indent: 20px;
                        font-size: 28px;
                        color: #666666;
                    }
                }

                /deep/.select_inp {
                    height: 100px;
                    line-height: 100px;
                    .placeholdertext,
                    .dataValue {
                        font-size: 28px;
                    }
                    
                }
    
            }
  
            button {
                width: 200px;
                height: 80px;
                background: #D5A223;
                border-radius: 40px;
                font-size: 34px;
                font-weight: 800;
                color: #FFFFFF;
                border: none;
            }
    
      }
  
      .gap {
        .inputbox {
          margin-right: 50px;
        }
        .inputbox:last-child {
          margin-right: 0px;
        }
  
      }
    }
  }
  .backBtn {
    display: flex;
    align-items: center;
    .back {
        width: 150px;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 22px;
        color: #fff;
        margin-bottom: 20px;
        margin-right: 30px;
        cursor: pointer;
        .el-icon-back {
          font-size: 32px;
          color: #fff;
          line-height: 1;
          margin-right: 5px;
        }
      }
  }
  </style>