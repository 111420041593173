<template>
    <div class="orderlist">
        <div class="order_typeNavbar">
            <div 
                v-for="(item,index) in orderTypeList" 
                :key="index"
                :class="index==activeindex?'activetype':''"
                @click="onactiveType(item,index)"
            >
                {{ item.name }}
            </div>
        </div>
        <div class="notlistText" v-if="datalist.length==0">
            <span>暂无订单~~~</span>
        </div>
        <div class="listView" v-pull="getdatalist"> 
            <OrderListItem
                v-for="item in datalist"
                :key="item.orderId"
                :item="item"
                @onPay="onPay(item)"
                @receiveGoods = 'receiveGoods(item)'
                @delOrderData = 'delOrderData(item)'
            ></OrderListItem>
        </div>

        <div class="confirmview" v-if="isconfirm">
            <div class="confirmbox">
                <div class="confirmTitle">
                    删除后不可恢复,是否继续?
                </div>
                <div class="confirmBtn">
                    <div @click="onCancel">取消</div>
                    <div @click="onRes">确认</div>
                </div>
            </div>
        </div>

        <div 
            class="Popup_box" 
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.3)"
            v-if="isCode"
        >

            <div class="code_box" v-if="codeImgUrl">
                <div>支付宝扫码</div>
                <div class="codeImgBox">
                    <img :src="codeImgUrl">
                </div>
                <div><p>支付成功后，请关闭支付窗口</p></div>
                <div class="clearIcon" @click="onCode">
                    <i class="el-icon-close"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcode";

import {
    getOrdersList,
    delOrder,
    confirmReceipt,
    orderBuyGoods,
    checkPay
} from '@/utils/http.js';

import OrderListItem from "@/pages/verticalphone/common/orderlistitem";
export default {
    data() {
        return {
            isReceive:null,// 收货状态   0：未收货  1：已收货   3：申请退货  4：退货申请成功  5：退换货填快递单号  6：已退货
            isSend:null, // 发货状态   0：未发货  1：已发货
            orderTypeList:[
                {
                    name:'全部',
                    value:null,
                },
                {
                    name:'待付款',
                    value:'0',
                    params:'status'
                },
                {
                    name:'待收货',
                    value:'0',
                    params:'isReceive'
                },
                {
                    name:'已收货',
                    value:'1',
                    params:'isReceive'
                },
                {
                    name:'退换货',
                    value:'5',
                    params:'isReceive'
                }
            ],
            params:{
                page:0,
            },
            activeindex:0,//导航下标
            totalPages:0,
            datalist:[],
            loading:false,
            codeImgUrl:'',
            count:0,
            timer:null,
            isCode:false,
            isconfirm:false,
            activeDelOrder:null,
        }
    },
    components:{
        OrderListItem
    },
    created(){
        if(this.$route.query.index){
            this.onactiveType(this.orderTypeList[this.$route.query.index],this.$route.query.index)
        }else{
            this.getdatalist()
        }
    },
    methods:{
        onactiveType(item,i){
            this.activeindex = i;
            this.datalist = [];

            let params = {
                page:0
            };

            if(item.value){
                if(item.params == 'isReceive' ){
                    params['status'] = '1';
                }
                params[item.params] = item.value;
               
                this.params = params;
            }else{
                this.params = params;
            }

            this.getdatalist()
        },
        onCancel(){
            this.isconfirm = false;
        },
        onRes(){
            delOrder({orderId: this.activeDelOrder.orderId}).then((res)=>{
                if(res.status==0){
                    // orderId
                    this.datalist = this.datalist.filter(items=>items.orderId!=this.activeDelOrder.orderId)
                    this.onCancel()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                    this.$message.error('删除失败!');
                }
            })
        },
        // 删除订单
        delOrderData(item){
            this.isconfirm = true;
            this.activeDelOrder = item;
        },
         //确认收货
        receiveGoods(item){
            confirmReceipt({orderId:item.orderId})
        },
        async getdatalist(){
            if(this.params.page>this.totalPages)return;
            // status
            this.params.page++;
            let res = await getOrdersList(this.params);
            if(res.status!=0)return;
            this.datalist = [...this.datalist,...res.data.list];
            this.totalPages = res.data.totalPages;
        },
        onPay(item){
            this.isCode =true;
            this.loading =true;
            const codeCount = 0;
            let data = { 
                orderId:item.orderId
            };

            orderBuyGoods(data).then(({status,data})=>{
                if(status!==0)return;
                this.toCanvas(data.html)

                this.count = codeCount;
                this.timer = setInterval(() => {
                    this.callback(data.outTradeNo);
                    if (this.count >= 28800) {
                        clearInterval(this.timer);
                        this.timer = null;
                    } else {
                        this.count++;
                    }
                }, 3000);       
            },(err)=>{
                this.onCode()
                this.$message({message: err.msg,type: 'warning'})
            })
        },
        callback(outTradeNo) {
            let data = { outTradeNo:outTradeNo };
            checkPay(data).then((res) => {
                this.isCode = false;
                this.loading =false;
                clearInterval(this.timer);
                location.reload();
            })
        },
        onCode (){
            this.loading =false;
            this.isCode = false;
            clearInterval(this.timer);
        },
        toCanvas(code) {
            QRCode.toDataURL(
                code,
                { errorCorrectionLevel: "L", margin: 2, width: 400 },
                (error, url) => {
                if (error) console.log(error);
                    this.codeImgUrl = url;
                    this.loading =false;
                }
            );
        },
    }
    
}
</script>
<style lang="less" scoped>
.orderlist {
    height: 95vh;
    background: #3A3A47;
    .order_typeNavbar {
        display: flex;
        height: 200px;
        padding: 0px 50px;
        font-size: 56px;
        font-weight: 800;
        line-height: 200px;
        color: #FFFFFF;
        border-bottom: 1px solid #cccccc50;
        >div{
            position: relative;
            flex: 1;
            text-align: center;
        }

        .activetype {
            color: #F88503;
            &::after{
                content: "";
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 112px;
                height: 8px;
                background: #F88503;
                border-radius: 4px;
            }

        }
    }
    .notlistText {
        height: 200px;
        font-size: 52px;
        font-weight: 800;
        line-height: 200px;
        text-align: center;
        color: #ffffff;
    }
    .listView {
        width: 100%;
        height: 91%;
        padding: 60px 50px;
        overflow-y: auto;
    }
}


.Popup_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: #00000052;
    z-index: 9999;
    .code_box{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 1000px;
        height: 1200px;
        background-color: #fff;
        border-radius: 80px;
        font-size: 56px;
        font-weight: 800;
        color: #333;

        .codeImgBox {
            width: 900px;
            height: 900px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        p{
            font-size: 48px;
            color: #cccccc;
        }
        .clearIcon {
            position: absolute;
            top: 30px;
            right: 40px;
        }
    }
}


.confirmview {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100vh;
    background-color: #00000067;
    z-index: 9999;
    .confirmbox {
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 900px;
        height: 550px;
        background-color: #3A3A47;
        border-radius: 40px;
        .confirmTitle {
            font-size: 64px;
            font-weight: 800;
            color: #fff;
        }

        .confirmBtn {
            width: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;
            div {
                width: 200px;
                height: 110px;
                text-align: center;
                line-height: 110px;
                font-size: 48px;
                border-radius: 20px;
            }
            >div:nth-child(1){
                background-color: #fff;
            }
            >div:nth-child(2){
                background-color: #F88503;
                color: #fff;
            }
        }

    }
}
    
</style>