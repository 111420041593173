export const pullDown = {
    bind(el,binding){
        let time = null;
        let callback = binding.value;
        el.addEventListener('scroll',(e)=>{
            let scrollTop = e.target.scrollTop; //距顶部
            let clientHeight = e.target.clientHeight; //可视区高度
            let scrollHeight = e.target.scrollHeight-50; //滚动条总高度
            let h = scrollTop + clientHeight; //距顶部 + 可视区高度
           
            if(h>=scrollHeight){
                if(time){
                    clearTimeout(time)
                }
                time = setTimeout(()=>{
                    callback&&callback();
                },0)
            }

        })
    },
    update(){
    }
}