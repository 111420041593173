<template>
    <div class="infoView">
        <div class="clearbtn" @click="back"></div>
        <div class="info_header">
            <div class="infoImg">
                <img :src="datainfo.image" >
            </div>
            <div class="infotitlepric">
                <div>
                    <p>{{ datainfo.title }}</p>
                </div>
                <div>
                    <p>
                        <span><i>￥</i>{{ this.$route.query.activeSku.split(',')[0] }}</span>
                        <span
                            class="priceold"
                            v-if="this.$route.query.activeSku.split(',')[1]"
                        ><i>￥</i>{{ this.$route.query.activeSku.split(',')[1] }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="info_main" v-html="datainfo.content">
        </div>
    </div>
</template>
<script>
import {getGoodsDetails} from "@/utils/http.js";

export default {
    data() {
        return {
            datainfo:{}
        }
    },
    created(){
        this.getdata()
    },
    methods:{
        async getdata(){
            let {status,data} = await getGoodsDetails({goodsId:this.$route.query.goodsId,artId:this.$route.query.artId})
            if(status!==0)return;
            this.datainfo = data.goodsInfo;
        },
        back(){
            this.$router.back()
        }
    }
    
}
</script>
<style lang="less" scoped>
.infoView {
    position: relative;
    width: 1410px;
    min-height: 720px;
    margin: 35px auto;
    padding: 33px 40px;
    background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
    border-radius: 30px;
    border: 3px solid rgba(255,255,255,0.2);
    .clearbtn {
        position: absolute;
        top: 71px;
        right: 40px;
        width: 60px;
        height: 60px;
        background: url('~@/assets/phone/icon04.png') no-repeat center center;
        background-size: 100% 100%;
    }
    .info_header {
        display: flex;
        align-items: center;
        padding-bottom: 29px;
        margin-bottom: 30px;
        border-bottom: 1px solid #ffffff6c;
        .infoImg {
            width: 170px;
            height: 136px;
            margin-right: 32px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .infotitlepric {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 1050px;
            min-height: 136px;
            p{
                font-size: 36px;
                font-weight: 800;
                color: #FFFFFF;
                span{
                    font-size: 40px;
                    font-weight: bold;
                    color: #FF0000;
                    margin-right: 31px;
                    i{
                        font-size: 24px;
                        font-style: normal;
                    }
                }
                .priceold {
                    font-size: 24px;
                    font-weight: 400;
                    color: #999999;
                    text-decoration: line-through;
                }
            }
        }
    }
    .info_main {
        height: 500px;
        overflow-y: auto;
        // 滚动条宽度
        &::-webkit-scrollbar {
            width: 6px;
        }
        // 滚动条轨道
        &::-webkit-scrollbar-track {
            background: #ccc;
            border-radius: 2px;
        }
        // 小滑块
        &::-webkit-scrollbar-thumb {
            background: #2B2B2B;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #2B2B2B;
        }
    }

    /deep/.info_main {
        color: #ffffff;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
    
</style>