var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "goodsbox" }, [
      _c("div", { staticClass: "goodsimg" }, [
        _c("img", { attrs: { src: _vm.datainfo.image } }),
      ]),
      _c("div", { staticClass: "goodstitle" }, [
        _c("div", { staticClass: "goodsPriceNum" }, [
          _c("div", { staticClass: "goodsPrice" }, [
            _c("i", [_vm._v("￥")]),
            _vm._v(
              "\n                    " +
                _vm._s(_vm.activeSuk.price) +
                "\n                    "
            ),
            _vm.datainfo.isDiscount
              ? _c("span", [
                  _vm._v(" ￥" + _vm._s(_vm.activeSuk.originalPrice)),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "goodsNum" }, [
            _vm._v("库存：" + _vm._s(_vm.activeSuk.number)),
          ]),
        ]),
        _c("div", { staticClass: "goodsname" }, [
          _c("p", [_vm._v(_vm._s(_vm.datainfo.title))]),
        ]),
      ]),
      _c("div", { staticClass: "goodsSkuAddress" }, [
        _c("div", { staticClass: "goodsSkuAddressBox" }, [
          _c("div", { staticClass: "skuitem", on: { click: _vm.onSkuPopup } }, [
            _c("div", [
              _c("div", { staticClass: "skutitle" }, [_vm._v("规格")]),
              _c(
                "div",
                { staticClass: "skuname" },
                _vm._l(_vm.activeSuk.skuName, function (item, index) {
                  return _c("span", { key: item + index }, [
                    _vm._v(_vm._s(item)),
                  ])
                }),
                0
              ),
            ]),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]),
          _c("div", { staticClass: "skuitem", on: { click: _vm.goAddress } }, [
            _c(
              "div",
              [
                _c("div", { staticClass: "skutitle" }, [_vm._v("地址")]),
                _vm._l(_vm.addresslist, function (item) {
                  return _c(
                    "div",
                    { key: item.addressId, staticClass: "skuname" },
                    [
                      _c("p", [
                        _c("span", [_vm._v(_vm._s(item.provinceName))]),
                        _c("span", [_vm._v(_vm._s(item.cityName))]),
                        _c("span", [_vm._v(_vm._s(item.districtName))]),
                        _c("span", [_vm._v(_vm._s(item.content))]),
                      ]),
                    ]
                  )
                }),
                _vm.addresslist.length == 0
                  ? _c("div", [_c("span", [_vm._v("请选择地址")])])
                  : _vm._e(),
              ],
              2
            ),
            _c("i", { staticClass: "el-icon-arrow-right" }),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "goodscontent" }, [
        _c("div", { staticClass: "goodscontentTitle" }, [_vm._v("商品介绍")]),
        _c("div", {
          staticClass: "goodscontentView",
          domProps: { innerHTML: _vm._s(_vm.datainfo.content) },
        }),
      ]),
      _c("div", { staticClass: "Buybtn", on: { click: _vm.goBuy } }, [
        _vm._v("立即购买"),
      ]),
    ]),
    _vm.isPop || _vm.isPopNum
      ? _c("div", { staticClass: "popupBox", on: { click: _vm.claerPop } }, [
          _c(
            "div",
            {
              staticClass: "popSkubox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            },
            [
              _vm.isPop
                ? _c("div", [
                    _c("div", { staticClass: "popSkuboxHead" }, [
                      _c("div", { staticClass: "goodsSkuimg" }, [
                        _c("img", { attrs: { src: _vm.datainfo.image } }),
                      ]),
                      _c("div", { staticClass: "popSkunum" }, [
                        _c("div", { staticClass: "popPrice" }, [
                          _c("p", [
                            _c("i", [_vm._v("￥")]),
                            _vm._v(
                              "\n                                " +
                                _vm._s(_vm.activeSuk.price) +
                                "\n                                "
                            ),
                            _vm.datainfo.isDiscount
                              ? _c("span", [
                                  _vm._v(
                                    " ￥" + _vm._s(_vm.activeSuk.originalPrice)
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _c("div", [
                          _c("p", [
                            _vm._v("库存：" + _vm._s(_vm.activeSuk.number)),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "skubox" },
                      _vm._l(_vm.datainfo.specLists, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "skuitem" },
                          [
                            _c("div", { staticClass: "skuitemtitel" }, [
                              _vm._v(_vm._s(item.specName)),
                            ]),
                            _c(
                              "div",
                              { staticClass: "skuitemobj" },
                              _vm._l(item.lists, function (items, jndex) {
                                return _c(
                                  "div",
                                  {
                                    key: items + jndex,
                                    class:
                                      _vm.activeindexArr[jndex] ==
                                      jndex + "," + index
                                        ? "activeitem"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.speciChange(
                                          items,
                                          jndex,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(items.itemName))]
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "skubtn", on: { click: _vm.onskures } },
                      [_vm._v("\n                    确认\n                ")]
                    ),
                  ])
                : _vm._e(),
              _vm.isPopNum
                ? _c("div", [
                    _c("div", { staticClass: "num_box_title" }, [
                      _vm._v("数量"),
                    ]),
                    _c("div", { staticClass: "num_box" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changNum(-1)
                            },
                          },
                        },
                        [_vm._v("-")]
                      ),
                      _c("div", [_vm._v(_vm._s(_vm.num))]),
                      _c(
                        "div",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changNum(1)
                            },
                          },
                        },
                        [_vm._v("+")]
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "skubtn", on: { click: _vm.onskuresNum } },
                      [_vm._v("\n                    确认\n                ")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }