var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loginView" }, [
    _c("div", { staticClass: "loginInp" }, [
      _c("div", { staticClass: "title" }, [_vm._v("登录")]),
      _c("div", { staticClass: "inpbox" }, [
        _c("p", [_vm._v("手机号")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.params.mobile,
              expression: "params.mobile",
            },
          ],
          attrs: { type: "text", placeholder: "点击此处输入手机号" },
          domProps: { value: _vm.params.mobile },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.params, "mobile", $event.target.value)
            },
          },
        }),
      ]),
      _c("div", { staticClass: "inpbox" }, [
        _c("p", [_vm._v("验证码")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.params.verCode,
              expression: "params.verCode",
            },
          ],
          attrs: { type: "text", placeholder: "点击此处输入验证码" },
          domProps: { value: _vm.params.verCode },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.params, "verCode", $event.target.value)
            },
          },
        }),
        _c("div", { staticClass: "yzmbtn", on: { click: _vm.getverCode } }, [
          _vm._v(
            "\n                " +
              _vm._s(
                _vm.codeDate > 60 ? "获取验证码" : _vm.codeDate + "秒后在此获取"
              ) +
              "\n            "
          ),
        ]),
      ]),
      _c("div", { staticClass: "btn", on: { click: _vm.loginuser } }, [
        _vm._v("登录"),
      ]),
      _c("div", { staticClass: "clearbtn", on: { click: _vm.clearinp } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }