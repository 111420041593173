var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "orderbox" }, [
      _c("div", { staticClass: "paymentbox" }, [
        _c("div", { staticClass: "paymentimg" }),
        _c("div", [
          _vm._v(_vm._s(_vm.statustext[this.$route.query.orderStatus])),
        ]),
      ]),
      _c("div", { staticClass: "orderAddres" }, [
        _c("div", [_c("p", [_vm._v(_vm._s(_vm.goodsinfo.address))])]),
        _c("div", [
          _c("p", [_c("span", [_vm._v(_vm._s(_vm.goodsinfo.createTime))])]),
        ]),
      ]),
      _c("div", { staticClass: "orderinfo" }, [
        _c("div", { staticClass: "orderinfoimg" }, [
          _c("img", { attrs: { src: _vm.goodsinfo.image, alt: "" } }),
        ]),
        _c("div", { staticClass: "orderinfo_Comtent" }, [
          _c("div", { staticClass: "orderinfo_Comtent_Title" }, [
            _c("p", [_vm._v(_vm._s(_vm.goodsinfo.title))]),
          ]),
          _c("div", { staticClass: "orderinfo_gg" }, [
            _c("span", [_vm._v(_vm._s(_vm.goodsinfo.skuValue))]),
          ]),
          _c("div", { staticClass: "orderinfo_num_Price" }, [
            _c("div", { staticClass: "orderinfo_Price" }, [
              _c("span", [_vm._v("￥")]),
              _vm._v(_vm._s(_vm.goodsinfo.price)),
            ]),
            _c("div", { staticClass: "orderinfo_resnum" }, [
              _vm._v("x" + _vm._s(_vm.goodsinfo.goodsNumber)),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "Priceinfo" }, [
        _c("div", [
          _c("span", [_vm._v("订单编号")]),
          _c("span", { staticClass: "Priceinfo_numm" }, [
            _vm._v(_vm._s(_vm.goodsinfo.orderSn)),
          ]),
        ]),
        _c("div", [
          _c("span", [_vm._v("下单时间")]),
          _c("span", { staticClass: "Priceinfo_numm" }, [
            _vm._v(_vm._s(_vm._f("datetime")(_vm.goodsinfo.createTime))),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "Priceinfo" }, [
        _c("div", [
          _c("span", [_vm._v("商品总额")]),
          _c("span", { staticClass: "Priceinfo_num" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.totalPrice)),
          ]),
        ]),
        _c("div", [
          _c("span", [_vm._v("运费")]),
          _c("span", { staticClass: "Priceinfo_num" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.courierFee)),
          ]),
        ]),
        _c("div", [
          _c("span", [_vm._v("实付款")]),
          _c("span", { staticClass: "Priceinfo_num" }, [
            _vm._v("￥" + _vm._s(_vm.goodsinfo.totalPayment)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }