var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "return_Goods_Box" }, [
    _c("div", { staticClass: "goodsinfo_box" }, [
      _c("div", { staticClass: "orderinfoimg" }, [
        _c("img", { attrs: { src: _vm.goodsinfo.image, alt: "" } }),
      ]),
      _c("div", { staticClass: "orderinfo_Comtent" }, [
        _c("div", { staticClass: "orderinfo_Comtent_Title" }, [
          _c("p", [_vm._v(_vm._s(_vm.goodsinfo.title))]),
        ]),
        _c("div", { staticClass: "orderinfo_gg" }, [
          _c("span", [_vm._v(_vm._s(_vm.goodsinfo.skuValue))]),
        ]),
        _c("div", { staticClass: "orderinfo_num_Price" }, [
          _c("div", { staticClass: "orderinfo_Price" }, [
            _c("span", [_vm._v("￥")]),
            _vm._v(_vm._s(_vm.goodsinfo.price)),
          ]),
          _c("div", { staticClass: "orderinfo_num" }, [
            _vm._v("x" + _vm._s(_vm.goodsinfo.goodsNumber)),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "Express_delivery_info" }, [
      _c("div", { staticClass: "Express_delivery_title" }, [
        _vm._v("请将需要退换的货物邮递到此地址"),
      ]),
      _c("div", { staticClass: "contentBox" }, [
        _c("div", { staticClass: "content_row" }, [
          _c("div", { staticClass: "content_item" }, [
            _c("p", { staticClass: "content_title" }, [_vm._v("收货人：")]),
            _c("p", { staticClass: "content_name" }, [
              _vm._v(_vm._s(_vm.userAddressInfo.contact)),
            ]),
          ]),
          _c("div", { staticClass: "content_item" }, [
            _c("p", { staticClass: "content_title" }, [_vm._v("电话：")]),
            _c("p", { staticClass: "content_name" }, [
              _vm._v(_vm._s(_vm.userAddressInfo.telephone)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "content_row" }, [
          _c("div", { staticClass: "content_item" }, [
            _c("p", { staticClass: "content_title" }, [_vm._v("收货地址：")]),
            _c("p", { staticClass: "content_name" }, [
              _vm._v(_vm._s(_vm.userAddressInfo.addressContent)),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "return_num" }, [
      _c("div", [_vm._v("快递单号")]),
      _c("div", { staticClass: "return_num_inp" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.expressNumber,
              expression: "expressNumber",
            },
          ],
          attrs: { type: "text", placeholder: "点击输入快递单号" },
          domProps: { value: _vm.expressNumber },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.expressNumber = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _c("div", { staticClass: "submitBtn", on: { click: _vm.submitfrom } }, [
      _vm._v("\n        提交\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }