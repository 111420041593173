var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "order_item" }, [
    _c("div", { staticClass: "order_item_head" }, [
      _c("div", { staticClass: "order_item_head_title" }, [
        _c("span", [
          _vm._v(_vm._s(_vm._f("datetime")(_vm.itemdata.createTime))),
        ]),
        _c("span", [_vm._v("订单编号：" + _vm._s(_vm.itemdata.orderSn))]),
      ]),
      _c("div", { staticClass: "order_item_head_icon" }, [
        _c("span", { staticClass: "bigredtext" }, [
          _vm._v(_vm._s(_vm.statustext[_vm.orderStatus])),
        ]),
        _c("i", { on: { click: _vm.delOrderData } }),
      ]),
    ]),
    _c("div", { staticClass: "order_item_body" }, [
      _c("div", { staticClass: "order_item_body_info" }, [
        _c("div", { staticClass: "order_item_body_infodata" }, [
          _c("div", { staticClass: "info_img" }, [
            _c("img", { attrs: { src: _vm.itemdata.image, alt: "" } }),
          ]),
          _c("div", { staticClass: "info_text" }, [
            _c("p", [_vm._v(_vm._s(_vm.itemdata.title))]),
            _c("p", [_c("span", [_vm._v(_vm._s(_vm.itemdata.skuValue))])]),
            _c("div", [
              _c("span", { staticClass: "redtext" }, [
                _vm._v("￥" + _vm._s(_vm.itemdata.price)),
              ]),
              _c("span", [_vm._v("数量：" + _vm._s(_vm.itemdata.goodsNumber))]),
            ]),
          ]),
        ]),
      ]),
      _vm.orderStatus == "0"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c("p", [
              _c("span", { staticClass: "text_date" }, [
                _vm._v(_vm._s(_vm.countDownNum)),
              ]),
              _vm._v(" 秒后取消订单"),
            ]),
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "1"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "2"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "3"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "4"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c("p", [_vm._v("退换申请已通过，请提交快递单号")]),
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "5"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c("p", [_vm._v("退换货审核中")]),
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
      _vm.orderStatus == "6"
        ? _c("div", { staticClass: "order_item_body_btn" }, [
            _c("p", [_vm._v("已退货")]),
            _c(
              "p",
              { staticClass: "text_res", on: { click: _vm.goOrderinfo } },
              [_vm._v("订单详情")]
            ),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "order_item_foot" }, [
      _c("span", [_vm._v("总计：￥" + _vm._s(_vm.itemdata.totalPayment))]),
      _vm.orderStatus == "0"
        ? _c(
            "div",
            { staticClass: "order_item_foot_btn", on: { click: _vm.onPay } },
            [_vm._v("立即支付")]
          )
        : _vm._e(),
      _vm.orderStatus == "2"
        ? _c(
            "div",
            {
              staticClass: "order_item_foot_btn",
              on: { click: _vm.receiveGoods },
            },
            [_vm._v("确认收货")]
          )
        : _vm._e(),
      _vm.orderStatus == "3"
        ? _c(
            "div",
            {
              staticClass: "order_item_foot_btn",
              on: { click: _vm.goApplyfor },
            },
            [_vm._v("退换货")]
          )
        : _vm._e(),
      _vm.orderStatus == "4"
        ? _c(
            "div",
            {
              staticClass: "order_item_foot_btn",
              on: { click: _vm.goExpressDelivery },
            },
            [_vm._v("填写单号")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }