var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addresinp_view" }, [
    _c("div", { staticClass: "addresinp_view_title" }, [
      _c("span", [_vm._v("退换货")]),
      _c("div", {
        staticClass: "backbtn",
        on: {
          click: function ($event) {
            return _vm.$router.back()
          },
        },
      }),
    ]),
    _c("div", { staticClass: "addresinp_view_form" }, [
      _vm._m(0),
      _c("div", { staticClass: "addresinp_view_row center" }, [
        _c("div", { staticClass: "addresinfo" }, [
          _c("div", { staticClass: "addresinfo_row" }, [
            _c("div", [
              _vm._m(1),
              _c("p", [_vm._v(_vm._s(_vm.userAddressInfo.contact))]),
            ]),
            _c("div", [
              _vm._m(2),
              _c("p", [_vm._v(_vm._s(_vm.userAddressInfo.telephone))]),
            ]),
            _c("div", [
              _vm._m(3),
              _c("p", [_vm._v(_vm._s(_vm.userAddressInfo.addressContent))]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "addresinp_view_row center" }, [
        _c("div", { staticClass: "oddNumber" }, [
          _c("div", [_vm._v("快递单号：")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.expressNumber,
                expression: "expressNumber",
              },
            ],
            attrs: { type: "text", placeholder: "点击此处输入快递单号" },
            domProps: { value: _vm.expressNumber },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.expressNumber = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "addresinp_view_row gap center" }, [
        _c("button", { on: { click: _vm.submitfrom } }, [_vm._v("提交申请")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "addresinp_view_row center" }, [
      _c("div", [_c("p", [_vm._v("请将需要退换的货物邮递到此地址")])]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("收货人s：")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("电话：")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("收货地址：")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }