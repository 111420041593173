var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mybox" }, [
    _vm.userinfo
      ? _c("div", { staticClass: "userbox" }, [
          _vm._v("\n        账号：" + _vm._s(_vm.userinfo) + "\n    "),
        ])
      : _vm._e(),
    !_vm.userinfo
      ? _c("div", { staticClass: "userbox", on: { click: _vm.gologin } }, [
          _vm._v("\n        立即登录\n    "),
        ])
      : _vm._e(),
    _c("div", { staticClass: "muorder" }, [
      _c("div", [_vm._v("我的订单")]),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.goOrder("")
            },
          },
        },
        [
          _vm._v("\n            全部订单\n            "),
          _c("i", { staticClass: "el-icon-arrow-right" }),
        ]
      ),
    ]),
    _c("div", { staticClass: "myIcon" }, [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.goOrder(1)
            },
          },
        },
        [_vm._m(0), _c("span", [_vm._v("待付款")])]
      ),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.goOrder(2)
            },
          },
        },
        [_vm._m(1), _c("span", [_vm._v("待收货")])]
      ),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.goOrder(3)
            },
          },
        },
        [_vm._m(2), _c("span", [_vm._v("已收货")])]
      ),
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.goOrder(4)
            },
          },
        },
        [_vm._m(3), _c("span", [_vm._v("退换货")])]
      ),
    ]),
    _c("div", { staticClass: "addressbtn", on: { click: _vm.goAddress } }, [
      _vm._m(4),
      _c("i", { staticClass: "el-icon-arrow-right" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "myIcon_img" }, [
      _c("img", { attrs: { src: require("@/assets/phone/icon_dfk.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "myIcon_img" }, [
      _c("img", { attrs: { src: require("@/assets/phone/icon_dsh.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "myIcon_img" }, [
      _c("img", { attrs: { src: require("@/assets/phone/icon_ysh.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "myIcon_img" }, [
      _c("img", { attrs: { src: require("@/assets/phone/icon_thh.png") } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i"), _c("span", [_vm._v("地址管理")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }