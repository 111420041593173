<template>
<div class="order_item">

  <div class="order_item_head">
    <div class="order_item_head_title">
      <span>{{ itemdata.createTime | datetime }}</span>
      <span>订单编号：{{ itemdata.orderSn }}</span>
    </div>
    <div class="order_item_head_icon">
      <span class="bigredtext">{{ statustext[orderStatus] }}</span>
      <i @click="delOrderData"></i>
    </div>
  </div>

  <div class="order_item_body">
    <div class="order_item_body_info">

      <div class="order_item_body_infodata">
        <div class="info_img">
          <img :src="itemdata.image" alt="">
        </div>
        <div class="info_text">
          <p>{{ itemdata.title }}</p>
          <p><span>{{ itemdata.skuValue }}</span></p>
          <div>
            <span class="redtext">￥{{ itemdata.price }}</span>
            <span>数量：{{ itemdata.goodsNumber }}</span>
          </div>
        </div>
      </div>

    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='0'">
      <p ><span class="text_date">{{ countDownNum }}</span> 秒后取消订单</p>
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='1'">
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='2'">
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='3'">
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='4'">
      <p>退换申请已通过，请提交快递单号</p>
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='5'">
      <p>退换货审核中</p>
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>

    <div class="order_item_body_btn" v-if="orderStatus=='6'">
      <p>已退货</p>
      <p class="text_res" @click="goOrderinfo">订单详情</p>
    </div>
  </div>

  <div class="order_item_foot">
    <span>总计：￥{{ itemdata.totalPayment }}</span>
    <div class="order_item_foot_btn" v-if="orderStatus=='0'" @click="onPay">立即支付</div>
    <div class="order_item_foot_btn" v-if="orderStatus=='2'" @click="receiveGoods">确认收货</div>
    <div class="order_item_foot_btn" v-if="orderStatus=='3'" @click="goApplyfor">退换货</div>
    <div class="order_item_foot_btn" v-if="orderStatus=='4'"  @click="goExpressDelivery">填写单号</div>
  </div>

</div>
  
</template>
<script>
export default {
  props:['status','itemdata'],
  data() {
    return {
      statustext:{
        0:'待支付',
        1:'订单取消',
        2:'待收货',
        3:'已收货',
        4:'退换货',
        5:'审核中',
        6:'已退货',
      },
      countDownDate:0,
      Dateitem:null,
      // 待支付  待收货 已收货 退换货
      orderStatus:null,
    }
  },
  created(){
    this.getPaidDate();
    this.orderType();
  },
  computed:{
    countDownNum(){
      return this.countDownDate;
    }
  },
  beforeDestroy(){
    clearInterval(this.Dateitem)
  },
  filters:{
    datetime:function(time){

      let date = new Date(time*1000);
      let Y = date.getFullYear() + '.';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      
      return Y + M + D + h + m ;
    }
  },
  methods: {
    orderType(){
      // 待支付  待收货 已收货 退换货
      if(this.itemdata.status==0){
        // 待支付
        this.orderStatus=0;
      }else if(this.itemdata.status==1){
        // 已支付
        switch (this.itemdata.isReceive) {
          case 0:
            // 未收货
            this.orderStatus=2;
            break;
          case 1:
            // 已收货
            this.orderStatus=3;
            break;
          case 3:
            // 审核
            this.orderStatus=5;
            break;
          case 4:
            // 退还货
            this.orderStatus=4;
            break;
          case 5:
            // 已退货
            this.orderStatus=6;
            break;
          default:
            break;
        }
        
      }else if(this.itemdata.status==2){
        // 取消订单
        this.orderStatus=1;
      }

    },

    getPaidDate(){
      this.countDownDate = this.itemdata.countDown;
      if(this.countDownDate==0)return;
      this.Dateitem = setInterval(()=>{
        this.countDownDate-=1;
      },1000)
    },
    onPay(){
      this.$emit('onPay')
    },
    delOrderData(){
      this.$emit('delOrderData')
    },
    receiveGoods(){
      this.orderStatus=3;
      this.$emit('receiveGoods')
    },
    goExchange(){
      this.$router.push({path:'/order/returnGoods'})
    },
    goOrderinfo(){
      // 订单详情
      this.$router.push({path:'/phone/personagecentre/orderinfo',query:{orderId:this.itemdata.orderId,orderStatus:this.orderStatus}})
    },
    goApplyfor(){
      // 退换货
      this.$router.push({path:'/phone/returnGoods',query:{orderId:this.itemdata.orderId}})
    },
    goExpressDelivery(){
      this.$router.push({path:'/phone/expressDelivery',query:{orderId:this.itemdata.orderId}})
    },
  },
  watch:{
    countDownDate:function(newval){
      // 倒计时为0，取消订单
      if(newval==1){
        this.orderStatus = '1';
        clearInterval(this.Dateitem);
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .order_item{
    width: 100%;
    background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
    border-radius: 30px;
    margin-bottom: 20px;
    border: 3px solid rgba(255,255,255,0.2);
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;

    .order_item_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 63px;
      padding: 0px 30px;
      .order_item_head_title {
        span {
          margin-right: 50px;
        }
      }
      .order_item_head_icon {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-right: 30px;
        }

        i {
          width: 14px;
          height: 16px;
          background: url('~@/assets/goodsOrder/deleteIcon.png') no-repeat center center;
          background-size: 100% 100%;
          cursor: pointer;
        }

      }

    }

    .order_item_body {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-top: 1px solid #cccccc7d;
      border-bottom: 1px solid #cccccc7d;
      .order_item_body_info {
        flex: 1;
        border-right: 1px solid #cccccc7d;
        .order_item_body_infodata {
          display: flex;
          align-items: center;
          padding: 19px 23px;
          .info_img {
            width: 200px;
            height: 160px;
            border-radius: 14px;
            margin-right: 21px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .info_text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 160px;
            font-size: 30px;
            font-weight: 800;
            line-height: 36px;
            color: #FFFFFF;
            p{
              span {
                font-size: 22px;
                font-weight: 500;
                color: #CCCCCC;
                line-height: 32px;
                padding: 5px 10px;
                background: #2B475A;
                border-radius: 5px;
              }
            }

            div {
              width: 500px;
              font-size: 22px;
              font-weight: 500;
              color: #FFFFFF;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .redtext{
                font-size: 30px;
                font-weight: 800;
                color: #FF0000;
              }

            }
            
          }
        }
      }
      .order_item_body_btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 247px;
        height: 100%;
        font-size: 14px;
        p {
          margin: 10px 0px;
        }

        button {
          width: 150px;
          height: 50px;
          background: #D5A223;
          border-radius: 6px ;
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
        button:hover {
          background: #f7b203;

        }
        .text_date {
          color: #FF0000;
        }
        .text_res {
          font-size: 24px;
          font-weight: 500;
          color: #D5A223;
        }
      }
    }

    .order_item_foot{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 23px 0px 34px;
      height: 103px;
      font-size: 30px;
      font-weight: 800;
      color: #FF0000;
      .order_item_foot_btn{
        width: 160px;
        height: 60px;
        background: #D5A223;
        border-radius: 40px;
        font-size: 28px;
        font-weight: 800;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
      }
    }
  }

  .bigredtext{
    font-size: 16px;
    font-weight: 800;
    color: #FF0000;
  }
</style>