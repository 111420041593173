<template>
    <div>
        <Login v-if="islogin" @isloginuser = "isloginuser"></Login>

        <div>
            <div class="container">
                <div class="navbar">
                    <NavBar @gologin = "gologin" ref="NavBar" :artId='promas.artId' @Clicktree="onClicktree"></NavBar>
                </div>

                <div class="container_main">
                    
                    <div class="goodslist">
                        <goodsitem
                            v-for="item in goodslist" 
                            :key="item.goodsId"
                            :item="item"
                        ></goodsitem>
                    </div>

                    <div v-if="goodslist.length==0" class="notzhanpin">
                        <span>
                            暂无展品~~~
                        </span>
                    </div>

                    <div class="paging" v-if="totalPage && goodslist.length">
                        <el-pagination
                            background
                            @current-change="changPage"
                            :current-page="promas.page"
                            layout="prev, pager, next"
                            :page-count="totalPage"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { 
  getBannerGoods,
  getGoodsListsBy
} from "@/utils/http.js";
import NavBar from "@/pages/phone/common/navbar.vue";
import Swiper, { Pagination, Navigation } from "swiper";
import goodsitem from "@/pages/phone/common/goodsitem.vue";
Swiper.use([Pagination, Navigation]);
import "swiper/swiper.less";
import { setToken } from '@/utils/auth' // get token from cookie

export default {
    data() {
        return {
            banner:[],
            goodslist:[],
            promas:{
                page: 1,
                type: 3,
                artId:null,
                categoryId:null
            },
            totalPage:0,
            islogin:false,
        }
    },
    components:{
        NavBar,
        goodsitem
    },
    created(){
        if(this.$route.query.artId){
            this.promas.artId = this.$route.query.artId;
            localStorage.setItem('artId',this.$route.query.artId);
        }

        if(localStorage.getItem('artId')){
            this.promas.artId = localStorage.getItem('artId');
        }

        this.getUrlToken();
        this.getgoodslist();
    },
    methods:{
        onClicktree(id){
            this.promas.page = 1;
            this.promas.type = 3;
            this.promas.categoryId = id;
            this.getgoodslist()
        },

        gologin(){
            this.islogin = true;
        },
        isloginuser(i){
            this.islogin = false;
            if(i==1){
                this.$refs.NavBar.islog = true;
            }
        },
        getUrlToken(){
            if(this.$route.query.token){
                setToken(this.$route.query.token)
                localStorage.setItem('usernum',this.$route.query.token)
            }
        },
        getBanner(){
            getBannerGoods().then((res) => {
                const banner = res.data;
                this.banner = banner;
                this.imgLoad();
            });
        },
        imgLoad() {
            this.$nextTick(()=>{
                new Swiper(".swiper", {
                    loop: true, // 循环模式选项
                    slidesPerView: "auto",
                    pagination: {
                        el: ".swiper-pagination",
                    },
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },
                    observer: true,
                    observeParents: true,
                });
            });
        },
        async getgoodslist(){
            // artId
            let {status,data} = await getGoodsListsBy(this.promas);
                if(status!==0)return;
                this.goodslist = data.goodsList;
                this.totalPage = data.totalPage;
        },
        changPage(i){
            this.promas.page = i;
            this.getgoodslist()
        }
    }
    
}
</script>
<style lang="less" scoped>

.container{
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1416px;
    margin: auto;
    padding-top: 40px;
    .navbar {
        width: 300px;
    }

    .container_main {
        width: 1079px;
        /deep/.swiperView {
            width: 100%;
            height: 200px;
            border-radius: 20px;
            margin-bottom: 35px;

            .swiper {
                width: 100%;
                height: 100%;
                .item {
                    position: relative;
                    .imgs {
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .swiper-pagination {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    width: 100%;
                    padding: 20px 0;
                    justify-content: center;
                    z-index: 99;
                    .swiper-pagination-bullet {
                        display: flex;
                        width: 12px;
                        height: 12px;
                        margin: 0 15px;
                        background-color: #f00;
                        border-radius: 12px;
                        z-index: 99;
                        box-shadow: 1px 1px 3px #666;
                    }
                    .swiper-pagination-bullet-active {
                        background-color: #fff;
                    }
                }
            }
        }

        .notzhanpin {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 28px;
            color: #ffffff;
        }

        .goodslist {
            display: grid;
            grid-template-columns: 340px 340px 340px;
            gap: 30px;
            

        }

        /deep/.paging {
            width: 100%;
            margin-top: 20px;
            .el-pagination {
                text-align: center;
            }
            .el-pager li {
                font-size: 16px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-weight: normal;
                background-color: white;
            }
            .el-pager li:not(.disabled).active {
                background-color: #d71a18;
                color: #fff;
            }
            .btn-next,
            .btn-prev {
                background-color: white;
                font-size: 16px;
                width: 40px;
                height: 40px;
                line-height: 40px;
                font-weight: normal;
            }
        }
    }
}
</style>