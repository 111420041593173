<template>
    <div class="return_Goods_Box">

        <div class="goodsinfo_box">
            <div class="orderinfoimg">
                <img :src="goodsinfo.image" alt="">
            </div>
            <div class="orderinfo_Comtent">
                <div class="orderinfo_Comtent_Title"><p>{{ goodsinfo.title }}</p></div>
                <div class="orderinfo_gg">
                    <span>{{ goodsinfo.skuValue }}</span>
                </div>
                <div class="orderinfo_num_Price">
                    <div class="orderinfo_Price"><span>￥</span>{{ goodsinfo.price }}</div>
                    <div class="orderinfo_num"  >x{{ goodsinfo.goodsNumber }}</div>
                </div>
            </div>
        </div>

        <div class="returnInp">
            <div class="return_reason_inp" @click="changselect">
                <div>退货原因</div>
                <div>
                    <p v-if="!returnReason" class="Reasontext">请选择退款原因</p>
                    <p>{{ returnReasontext }}</p>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>

            <div class="return_text">
                <textarea placeholder="点击此处输入详细的退款原因" v-model="returnDesc"></textarea>
            </div>

        </div>

        <div class="updataInp">
            <div>上传凭证图片</div>
            <updatainp ref="updatainp"></updatainp>
        </div>

        <div class="submitBtn" @click="submitfrom">
            提交申请
        </div>

        <div class="popup_box" v-if="ispop" @click="clearPop">

            <div class="select_box" @click.stop>
                <div class="select_box_head">
                    <div>请选择退换货原因</div>
                </div>

                <div class="citybox">
                    <div 
                        class="city_item" 
                        v-for="(item,index) in keydata"
                        :key="item.value"
                        @click="onSelect(item,index)"
                        :class="index==activeitem?'activecity_item':''"
                    >
                        <span>{{ item.label}}</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import {
  getOrderDetails,
  returnGoods
} from '@/utils/http.js';
import updatainp from "@/pages/pc/goodsOrder/common/updatainp.vue"

export default {
    data() {
        return {
            keydata:[
                {label:'七天无理由',value:1},
                {label:'商品出现异常',value:2},
                {label:'与商品描述不符',value:3},
                {label:'包裹/收货异常',value:4},
                {label:'其他',value:5},
            ],
            goodsinfo:{},
            ispop:false,
            activeitem:null,
            selectTime:null,
            returnReason:'',
            returnDesc:'',
        }
    },
    components:{
        updatainp
    },
    created(){
        this.getdatainfo()
    },
    methods:{
        async getdatainfo(){
            let res = await getOrderDetails({orderId:this.$route.query.orderId});
            if(res.status!=0)return;
            this.goodsinfo = res.data;
        },
        changselect(){
            this.ispop = true;
        },
        clearPop(){
            this.ispop = false;
        },
        onSelect(item,index){
            this.activeitem = index;
            clearTimeout(this.selectTime);
            this.selectTime = setTimeout(()=>{
                this.ispop = false;
                this.activeitem = null;
                this.returnReason = item.value
                this.returnReasontext = item.label

            },500);
        },
        async submitfrom(){
            let param = {
                orderId:this.$route.query.orderId,
                returnReason:this.returnReason,
                returnDesc:this.returnDesc,
                returnImage:this.$refs.updatainp.urlimgArr.join(',')
            }
            
            if(!this.returnReason)return this.$message({message: '请选择退货原因',type: 'warning'});;
            let res = await returnGoods(param);
            if(res.status==0){
                this.$message({
                message: '提交成功',
                type: 'success'
                });
                setTimeout(()=>{
                    this.$router.back()
                },1000)
            }else{
                this.$message({
                message: '提交失败',
                type: 'warning'
                });
            }
        },
    }
    
}
</script>
<style lang="less" scoped>
.return_Goods_Box {
    width: 100%;
    height: 94vh;
    background: #3a3a47;
    padding: 60px 50px;

    .goodsinfo_box{
        background-color: #1D2533;
        border-radius: 40px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        min-height: 520px;
        padding: 36px 40px;
            .orderinfoimg {
                width: 540px;
                height: 450px;
                background: #fff;
                margin-right: 60px;
                border-radius: 30px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cove;
                }
            }
            .orderinfo_Comtent {
                width: 50%;
                .orderinfo_Comtent_Title {
                    font-size: 56px;
                    font-weight: 800;
                    color: #FFFFFF;
                    margin-bottom: 50px;
                }
                .orderinfo_gg {
                    display: flex;
                    flex-wrap: wrap;
                    span {
                        padding: 10px 34px;
                        background-color: #303A4B;
                        margin-right: 30px;
                        margin-top: 30px;
                        border-radius: 15px;
                        font-size: 44px;
                        font-weight: 500;
                        color: #999999;
                    }

                }
            }

            .orderinfo_num_Price {
                display: flex;
                justify-content: space-between;
                align-items: baseline;

                .orderinfo_Price {
                    font-size: 100px;
                    font-weight: 800;
                    color: #FF0021;
                    span{
                        font-size: 44px;
                    }
                }
                .orderinfo_num {
                    font-size: 48px;
                    font-weight: 800;
                    color: #fff;
                }
            }
    }

    .returnInp {
        background: #1D2533;
        border-radius: 40px;

       
        .return_reason_inp {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 200px;
            padding: 0px 60px;
            border-bottom: 1px solid #cccccc54;
            font-size: 56px;
            font-weight: 800;
            color: #666666;
            >div:nth-child(2){
                display: flex;
                align-items: center;
                p{
                    color: #fff;
                }
                i{
                    margin-left: 20px;
                    font-weight: 800;
                }
                .Reasontext {
                    color: #666666;
                }
            }
            
            
        }

        .return_text {
            height: 600px;
            textarea {
                width: 100%;
                height: 100%;
                font-size: 56px;
                font-weight: 800;
                color: #ffffff;
                background-color: #00000000;
                border: none;
                outline: none;
                padding: 48px 30px;
                &::placeholder{
                    font-size: 56px;
                    font-weight: 800;
                    color: #666666;
                }
            }
        }
    }

    .updataInp {
        font-size: 68px;
        font-weight: 800;
        color: #FFFFFF;
        >div:nth-child(1){
            margin: 50px 0px;
        }
        .updataInpbox {
            width: 420px;
            height: 420px;
            background: url(~@/assets/phone/updata_icon.png) no-repeat center center;
            background-size: 100% 100%;
        }

        /deep/.fileimg{
            width: 420px;
            height: 420px;
            i{
                width: 100px;
                height: 100px;
            }
        }
        /deep/.fileicon{
            width: 420px;
            height: 420px;
            background: url(~@/assets/phone/updata_icon.png) no-repeat center center;
            background-size: 100% 100%;
            i{
                display: none;
            }
        }

    }

    .submitBtn {
        margin: 100px auto;
        height: 176px;
        background: #F88503;
        border-radius: 88px;
        text-align: center;
        line-height: 176px;
        font-size: 60px;
        font-weight: 800;
        color: #FFFFFF;
    }
}
.popup_box {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: #00000066;
    z-index: 9999;

    .select_box {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 800px;
        background-color: #f5f5f5;
        border-radius:  80px 80px 0px 0px;

        .select_box_head {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            min-height: 200px;
            padding: 0px 70px;
            font-size: 48px;
            font-weight: 800;
            line-height: 200px;
            border-bottom: 1px solid #cccccc94;
            >div {
                margin-right: 50px;
                height: 200px;
            }
            .isactive{
                position: relative;
                color: #F88503;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 150px;
                    height: 10px;
                    background-color: #F88503;
                    
                }

            }

            .clearbtn {
                position: absolute;
                right: 50px;
                top: 50%;
                transform: translateY(-50%);
                width: 180px;
                height: 100px;
                background-color: #F88503;
                border-radius: 20px;
                text-align: center;
                line-height: 100px;
                color: #fff;
                
            }
        }

        .citybox {
            width: 100%;
            height: 600px;
            overflow-y: auto;
            .city_item {
                height: 150px;
                padding: 0px 80px;
                line-height: 150px;
                font-size: 48px;
                font-weight: 800;
                color: #333;
            }
            .activecity_item{
                background: #f8860361;
            }
        }
    }

    
}


</style>