<template>
    <div>
        <div class="goodsbox">
            <div class="goodsimg">
                <img :src="datainfo.image">
            </div>

            <div class="goodstitle">
                <div class="goodsPriceNum">
                    <div class="goodsPrice">
                        <i>￥</i>
                        {{ activeSuk.price }}
                        <span v-if="datainfo.isDiscount"> ￥{{ activeSuk.originalPrice }}</span>
                    </div>
                    <div class="goodsNum">库存：{{ activeSuk.number }}</div>
                </div>
                <div class="goodsname">
                    <p>{{datainfo.title}}</p>
                </div>

            </div>

            <div class="goodsSkuAddress">
                <div class="goodsSkuAddressBox">

                    <div 
                        class="skuitem" 
                        @click="onSkuPopup"
                    >
                        <div>
                            <div class="skutitle">规格</div>
                            <div class="skuname">
                                <span 
                                    v-for="(item,index) in activeSuk.skuName"
                                    :key="item+index"
                                >{{item}}</span>
                            </div>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>

                    <div class="skuitem" @click="goAddress">
                        <div>
                            <div class="skutitle">地址</div>
                            <div 
                                class="skuname" 
                                v-for="(item) in addresslist"
                                :key="item.addressId"
                            >
                                <p>

                                    <span>{{item.provinceName}}</span>
                                    <span>{{item.cityName}}</span>
                                    <span>{{item.districtName}}</span>
                                    <span>{{item.content}}</span>
                                </p>
                            </div>
                            <div v-if="addresslist.length==0">
                                <span>请选择地址</span>
                            </div>
                        </div>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>

            <div class="goodscontent">
                <div class="goodscontentTitle">商品介绍</div>
                <div class="goodscontentView" v-html="datainfo.content"></div>
            </div>
            <div class="Buybtn" @click="goBuy">立即购买</div>
        </div>

        <div class="popupBox" v-if="isPop||isPopNum" @click="claerPop">

            <div class="popSkubox" @click.stop>
                <div v-if="isPop">
                    <div class="popSkuboxHead">
                        <div class="goodsSkuimg">
                            <img :src="datainfo.image">
                        </div>

                        <div class="popSkunum">
                            <div class="popPrice"> 
                                <p>
                                    <i>￥</i>
                                    {{ activeSuk.price }}
                                    <span v-if="datainfo.isDiscount"> ￥{{ activeSuk.originalPrice }}</span>
                                </p>
                            </div>
                            <div>
                                <p>库存：{{ activeSuk.number }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="skubox">
                        <div 
                            class="skuitem"
                            v-for="(item,index) in datainfo.specLists"
                            :key="index"
                        >
                            <div class="skuitemtitel">{{item.specName}}</div>
                            <div class="skuitemobj">
                                <!-- activeitem -->
                                <div 
                                    v-for="(items,jndex) in item.lists"
                                    :key="items+jndex"
                                    @click="speciChange(items,jndex,index)"
                                    :class="activeindexArr[jndex]==`${jndex},${index}`?'activeitem':''"
                                >{{items.itemName}}</div>
                            </div>
                        </div>

                    </div>

                    <div class="skubtn" @click="onskures">
                        确认
                    </div>
                </div>

                <div v-if="isPopNum">
                    <div class="num_box_title">数量</div>
                    <div class="num_box">
                        <div @click="changNum(-1)">-</div>
                        <div>{{ num }}</div>
                        <div @click="changNum(1)">+</div>
                    </div>
                    <div class="skubtn" @click="onskuresNum">
                        确认
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>
<script>
import { 
    getGoodsDetails,
    getAddressList,
 } from "@/utils/http.js";

export default {
    data() {
        return {
            datainfo:{},
            activeSuk:{},
            isPop:false,
            isPopNum:false,
            num:1,
            skutypeArr:[],
            skutypeArrName:[],
            activeindexArr:{},
            addresslist:[],
        }
    },
    beforeDestroy(){
        this.isScroll(0)
    },
    created(){
        this.getgoodsinfo();
        this.getAddressList();
    },
    methods:{
        async getAddressList(){
            let {data,status} = await getAddressList();
            if(status!=0)return;

            if(localStorage.getItem('addressId')){
                this.addresslist = data.addressList.filter(item=>item.addressId==localStorage.getItem('addressId'));
            }else{
                this.addresslist = data.addressList.filter(item=>item.isDefault==1);
            }
        },
        goAddress(){
            if(!this.islogin())return this.$message({message: '尚未登录',type: 'warning'});
            this.$router.push({path:'/verphone/selectAddress',query:this.$route.query})
        },
        islogin(){
            let islog = false;
            if(localStorage.getItem('usernum')){
                islog = true;
            }
            return islog;
        },
        async getgoodsinfo(){
            let params = {
                goodsId:this.$route.query.goodsId,
                artId:this.$route.query.artId,
                dressId:this.$route.query.dressId
            }
            let {status,data} = await getGoodsDetails(params)
            if(status!==0)return;
            this.datainfo = data.goodsInfo;
            
            for(let i=0;i<this.datainfo.specLists.length;i++){
                this.$set(this.activeindexArr,i,`${i},0`)
            }

            this.defaultSku()
        },
        onSkuPopup(){
            this.isPop = true;
            this.isScroll(1)
        },

        isScroll(i){
            if(i==1){
                document.querySelector('body').style.overflow='hidden'
            }else{
                document.querySelector('body').style.overflow='inherit'
            }
        },

        onskures(){
            this.isPop = false;
        },
        
        defaultSku(){
            let skuName = [];
            this.datainfo.specLists.forEach((item,index) => {
                skuName.push(item.lists[0].itemName);
                this.skutypeArr[index] = item.lists[0].sku;
            });

            if(this.skutypeArr.every(item=> item)){
                this.activeSuk=this.datainfo.skuLists[this.skutypeArr.join(',')];
                this.activeSuk['skuName'] = skuName;
            }
        },

        speciChange (item,index,jndex){
            this.activeindexArr[index]=`${index},${jndex}`;
            this.skutypeArr[index]=item.sku;
            this.skutypeArrName[index] = item.itemName;

            if(this.skutypeArr.every(item=> item)){
                this.activeSuk = this.datainfo.skuLists[this.skutypeArr.join(',')]
                this.activeSuk['skuName'] = this.skutypeArrName;
            }
        },

        goBuy(){
            if(!this.islogin())return this.$message({message: '尚未登录',type: 'warning'});
            if(this.activeSuk.number<=0) return this.$message({message: '该商品已售空',type: 'warning'});
            this.isPopNum = true;
        },
        islogin(){
            let islog = false;
            if(localStorage.getItem('usernum')){
                islog = true;
            }
            return islog;
        },

        changNum(i){
            this.num+=i;
            if(this.num==0){
                this.num = 1;
            }
            if(this.num>=this.activeSuk.number){
                this.num=this.activeSuk.number;
            }
            
        },

        onskuresNum(){
            this.isPopNum = false;
            let query = {
                unique:this.activeSuk.unique,
                goodsId:this.datainfo.goodsId,
                number:this.num,
            }
            this.$router.push({path:'/verphone/goodsOrder',query})
        },

        claerPop(){
            this.isPop = false;
            this.isPopNum = false;
        }
    }
    
}
</script>
<style lang="less" scoped>
img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.goodsbox {
    .goodsimg{
        width: 100%;
        height: 1190px;
        background: #fff;
    }

    .goodstitle {
        min-height: 370px;
        padding: 20px 50px;
        .goodsPriceNum {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            .goodsPrice {
                font-size: 120px;
                font-weight: 800;
                color: #FF0000;
                i{
                    font-size: 48px;
                    font-style: normal;
                }
                span{
                    font-size: 48px;
                    font-weight: 800;
                    color: #999999;
                    text-decoration: line-through;
                }
            }
            .goodsNum {
                font-size: 48px;
                font-weight: 800;
                color: #999999;
            }
        }
        .goodsname {
            font-size: 60px;
            font-weight: 800;
            color: #FFFFFF;
        }
    }

    .goodsSkuAddress {
        padding: 60px 50px;
        background-color: #363E4B;
        .goodsSkuAddressBox{
            border-radius: 40px;
            background-color: #1D2533;
            padding: 20px 0px;

            .skuitem {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 200px;
                padding: 0px 60px;
                font-size: 48px;
                font-weight: 800;
                color: #666666;
                >div{
                    display: flex;
                    .skutitle {
                        margin-right: 60px;
                    }
                    .skuname{
                        width: 900px;
                        color: #fff;
                        p{
                            overflow: hidden;
                            text-overflow:ellipsis;
                            white-space: nowrap;
                        }
                        span {
                            margin-right: 20px;
                        }
                    }
                }
               
            }
        }
    }

    .goodscontent {
        color: #fff;
        .goodscontentTitle {
            height: 200px;
            background: #1D2533;
            font-size: 60px;
            font-weight: 800;
            color: #fff;
            text-align: center;
            line-height: 200px;
        }
        .goodscontentView {
            padding: 0px 50px;
        }
    }

    .Buybtn{
        width: 80%;
        height: 176px;
        margin: 50px auto;
        background: #F88503;
        border-radius: 88px;
        font-size: 60px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 176px;
    }
}

.popupBox {
    position: fixed;
    top: 150px;
    left: 0px;
    width: 100%;
    height: 95vh;
    background-color: #00000058;

    .popSkubox{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        min-height: 1000px;
        background: #3A3A47;
        border-radius: 80px 80px 0px 0px;
        .popSkuboxHead{
            display: flex;
            align-items: center;
            padding: 50px;
            border-bottom: 1px solid #cccccc28;
            .goodsSkuimg {
                width: 360px;
                height: 300px;
                border-radius: 40px;
                margin-right: 70px;
            }

            .popSkunum {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 280px;
                font-size: 52px;
                font-weight: 800;
                color: #999999;
                .popPrice {
                    font-size: 100px;
                    font-weight: 800;
                    color: #FF0000; 
                }
                i{
                    font-size: 52px;
                    font-style: normal;
                }
                span {
                    font-size: 52px;
                    font-weight: 800;
                    color: #666666;
                    text-decoration: line-through;
                }
            }
        }

        .skubox {
            padding: 24px 50px;
            .skuitem {
                .skuitemtitel {
                    margin: 48px 0px;
                    font-size: 60px;
                    font-weight: 800;
                    color: #FFFFFF;
                }

                .skuitemobj {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 60px;
                    >div{
                        width: 404px;
                        height: 140px;
                        background: #585858;
                        border-radius: 70px;
                        text-align: center;
                        line-height: 140px;
                        font-size: 52px;
                        font-weight: 800;
                        color: #FFFFFF;
                    }
                    .activeitem{
                        background-color: #F88503;
                    }
                }

            }

        }

        .num_box_title {
            width: 80%;
            margin: 100px auto;
            font-size: 80px;
            font-weight: 800;
            color: #fff;
        }
        .num_box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80%;
            height: 200px;
            border-radius: 100px;
            background-color: #cccccc;
            margin: 100px auto;
            >div {
                flex: 1;
                text-align: center;
                font-size: 80px;
                font-weight: 800;
            }
            >div:nth-child(2){
                border-left: 1px solid #ffffff;
                border-right: 1px solid #ffffff;
                color: #F88503;
            }

        }

        .skubtn {
            width: 80%;
            height: 176px;
            background: #F88503;
            border-radius: 88px;
            font-size: 60px;
            color: #FFFFFF;
            text-align: center;
            line-height: 176px;
            margin: 40px auto 0px;

        }
    }
}
    
</style>