<template>
    <div class="box" @click.stop="asd">
        <Menu @onMenuid = 'onMenuid' ref="menu"></Menu>

        <div 
            class="goodslistbox" 
            v-pull="pulldata"
        >

            <div class="goodslistview">
                <div 
                    class="goodsitem" 
                    v-for="item in goodslist"
                    :key="item.goodsId"
                    @click="goGoodsinfo(item)"
                >
                    <div class="goodsitemimg">
                        <img :src="item.image">
                    </div>
                    <div class="goodstitle">
                        <p>{{ item.title }}</p>
                    </div>
                    <div class="goodsPrice">
                        <p><i>￥</i>{{ item.price }} <span v-if="item.isDiscount"> ￥{{ item.originalPrice }}</span></p>
                    </div>
                </div>

              
            </div>

            <div class="notdata" v-if="goodslist.length==0">
                <span>暂无商品~~</span>
            </div>
        </div>
    </div>
</template>
<script>
import Menu from '@/pages/verticalphone/common/nanMenu/menu'

import { 
  getGoodsListsBy
} from "@/utils/http.js";
export default {
    data() {
        return {
            promas:{
                page: 1,
                type: 3,
                artId:null,
                categoryId:null
            },
            goodslist:[],
            totalPage:null,
            ispull:true,
        }
    },
    components:{
        Menu
    },
    created(){
        if(this.$route.query.artId){
            this.promas.artId = this.$route.query.artId;
            localStorage.setItem('artId',this.$route.query.artId);
        }
        if(localStorage.getItem('artId')){
            this.promas.artId = localStorage.getItem('artId');
        }
        this.getgoodslist()
    },
    methods:{
        asd(){
            this.$refs.menu.isMenu = false;
        },
        pulldata(){
            if(this.promas.page>=this.totalPage)return;
            if(!this.ispull)return;
            this.ispull = false;
            this.promas.page++;
            this.getgoodslist()
        },

        goGoodsinfo(item){
            let query = {
                goodsId:item.goodsId,
                dressId:item.dressId,
                artId:item.artId,
            }
            this.$router.push({path:'/verphone/goodsinfo',query})
        },
        onMenuid(id){
            this.promas.categoryId = id;
            this.promas.page = 1;
            this.goodslist=[]
            this.getgoodslist()
        },
        async getgoodslist(){
            let {status,data} = await getGoodsListsBy(this.promas);
            if(status!==0)return;
            this.goodslist = [...this.goodslist,...data.goodsList];
            this.totalPage = data.totalPage;
            this.ispull = true;
        }
    }
    
}
</script>
<style lang="less" scoped>
.goodslistbox {
    width: 100%;
    height: 86vh;
    padding: 70px 50px 200px;
    overflow-y: auto;
    .notdata{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 56px;
        font-weight: 800;
        color: #fff;
    }

    .goodslistview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:40px;
        .goodsitem {
            color: #fff;
            .goodsitemimg{
                height: 540px;
                border-radius: 35px;
                background-color: #fff;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .goodstitle {
                font-size: 60px;
                font-weight: 800;
                color: #FFFFFF;
                line-height: 64px;
                margin: 40px 0px;
            }

            .goodsPrice{
                font-size: 80px;
                font-weight: 800;
                color: #FF0000;
                i{
                    font-size: 48px;
                    font-style: normal;
                }
               
                span {
                    font-size: 48px;
                    font-weight: 800;
                    color: #666666;
                    text-decoration: line-through;
                }
            }
        }
    }
}
    
</style>