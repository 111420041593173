var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "popup_box",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.clearpop.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "select_box",
          on: {
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _c("div", { staticClass: "select_box_head" }, [
            _c("div", { class: _vm.isSelectNum == 0 ? "isactive" : "" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm.provinceObj ? _vm.provinceObj.label : "请选择地区"
                  ) +
                  "\n            "
              ),
            ]),
            _vm.provinceObj
              ? _c("div", { class: _vm.isSelectNum == 1 ? "isactive" : "" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.cityObj ? _vm.cityObj.label : "请选择地区") +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            _vm.cityObj
              ? _c("div", { class: _vm.isSelectNum == 2 ? "isactive" : "" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.districtObj ? _vm.districtObj.label : "请选择地区"
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "clearbtn",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearpop.apply(null, arguments)
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "citybox" },
            _vm._l(_vm.selectlist, function (item, index) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "city_item",
                  class: index == _vm.activeindex ? "activecity_item" : "",
                  on: {
                    click: function ($event) {
                      return _vm.onActive(item, index)
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(item.label))])]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }