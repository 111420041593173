<template>
    <div class="menuComponents">
        <div class="menubox">
            <div class="menuView">
                <div class="menu_content">
                    <!-- activeitem -->
                    <MenuItem
                        v-for="(item,index) in menulist" 
                        :key="item.categoryId"
                        :item = "item"
                        :active = 'index==activeIndex'
                        @activeClcik = 'activeClcik($event,index)'
                        @onShowSonMeun = 'onShowSonMeun'
                    ></MenuItem>
                </div>
            </div>
        </div>

        <PopUpBox 
            v-if="ispop"
            :activeChildren = 'activeChildren'
            :title = 'activeTitle'
            @onclearpPop = 'onclearpPop'
            @onSureitem = 'onSureitem'
        ></PopUpBox>

    </div>
</template>
<script>
import MenuItem from '@/pages/verticalphone/common/nanMenu/menuItem'
import PopUpBox from '@/pages/verticalphone/common/nanMenu/popUpBox'
import {getExhibitCategoryLists} from '@/utils/http';

export default {
    data() {
        return {
            menulist:[],
            artId:null,
            activeIndex:0,
            activeChildren:[],
            Menutime:null,
            MenuX:0,
            ispop:false,
            activeTitle:''
        }
    },
    components:{
        MenuItem,
        PopUpBox
    },
    created(){
        if(this.$route.query.artId){
            this.artId = this.$route.query.artId;
            localStorage.setItem('artId',this.$route.query.artId);
            
        }
        if(localStorage.getItem('artId')){
            this.artId = localStorage.getItem('artId');
        }
        this.getmenu()
    },
    methods:{
        
        onMenu(id){
            this.$emit('onMenuid',id);
        },
        async getmenu(){
            let {status,data} = await getExhibitCategoryLists({artId:this.artId});
            if(status!==0)return;
            this.menulist = data;
            let firstobj = {
                categoryId:null,
                categoryPid:null,
                children:[],
                title:'推荐商品',
            }
            this.menulist = [firstobj,...this.menulist]
        },
        activeClcik(id,index){
            this.activeIndex = index;
            this.onMenu(id)
        },
        onShowSonMeun(item){
            this.ispop = true;
            this.activeChildren = item.children;
            this.activeTitle = item.title;
        },
        onclearpPop(){
            this.ispop = false;
        },
        onSureitem(arr){
            if(!arr)return this.$message({message:'请选择', type:'warning'});
            this.ispop = false;
            this.onMenu(arr.categoryId)
        }
    }
    
}
</script>
<style lang="less" scoped>
.menuComponents {
    position: relative;
    border-top: 1px solid;
    .menubox {
        height: 150px;
        overflow: hidden;
        margin-top: 50px;
        .menuView {
            overflow-x: auto;
            .menu_content {
                padding: 0px 50px;
                white-space: nowrap;
                height: 300px;
            }
        }
    }
}
    
</style>
