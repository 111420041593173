<template>
    <div>

        <div class="orderbox">

            <div class="paymentbox"  >
                <div class="paymentimg"></div>
                <div>{{ statustext[this.$route.query.orderStatus] }}</div>
            </div>

            <div class="orderAddres">
                <div>
                    <p>{{ goodsinfo.address }}</p>
                </div>
                <div>
                    <p><span>{{ goodsinfo.createTime }}</span></p>
                </div>
            </div>

            <div class="orderinfo">
                <div class="orderinfoimg">
                    <img :src="goodsinfo.image" alt="">
                </div>
                <div class="orderinfo_Comtent">
                    <div class="orderinfo_Comtent_Title"><p>{{ goodsinfo.title }}</p></div>
                    <div class="orderinfo_gg">
                        <span>{{ goodsinfo.skuValue }}</span>
                    </div>

                    <div class="orderinfo_num_Price">
                        <div class="orderinfo_Price"><span>￥</span>{{ goodsinfo.price }}</div>
                        <div class="orderinfo_resnum"  >x{{ goodsinfo.goodsNumber }}</div>
                    </div>
                </div>
            </div>

            <div class="Priceinfo"  >
                <div>
                    <span>订单编号</span>
                    <span class="Priceinfo_numm">{{ goodsinfo.orderSn }}</span>
                </div>
                <div>
                    <span>下单时间</span>
                    <span class="Priceinfo_numm">{{ goodsinfo.createTime | datetime }}</span>
                </div>
            </div>

            <div class="Priceinfo">
                <div>
                    <span>商品总额</span>
                    <span class="Priceinfo_num">￥{{ goodsinfo.totalPrice }}</span>
                </div>
                <div>
                    <span>运费</span>
                    <span class="Priceinfo_num">￥{{ goodsinfo.courierFee }}</span>
                </div>
                <div>
                    <span>实付款</span>
                    <span class="Priceinfo_num">￥{{ goodsinfo.totalPayment }}</span>
                </div>
            </div>
        </div>

        <!-- <div class="orderfrom"  >
            <div class="orderfrom_zj">
                删除订单
            </div>
            <div class="ordebtn" >确认收货</div>
        </div> -->
       
    </div>

</template>
<script> 
import {
  getOrderDetails
} from '@/utils/http.js'

export default {
  // orderId
  data() {
    return {
        statustext:{
            0:'待支付',
            1:'订单取消',
            2:'待收货',
            3:'已收货',
            4:'退换货',
        },
        orderStatus:null,
        goodsinfo:{}
    }
  },
  filters:{
    datetime:function(time){

      let date = new Date(time*1000);
      let Y = date.getFullYear() + '.';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      
      return Y + M + D + h + m ;
    }
  },
  created(){
    this.getdatainfo();
    this.orderStatus=this.$route.query.orderStatus;
  },
  methods:{
    async getdatainfo(){
      let res = await getOrderDetails({orderId:this.$route.query.orderId});
      if(res.status!=0)return;
      this.goodsinfo = res.data;
      console.log( this.goodsinfo);
    }
  }
}
</script>
<style lang="less" scoped>
.orderbox {
    min-height: 94vh;
    background-color: #363E4B;
    padding: 64px 50px 250px;
    >div{
        border-radius: 40px;
        margin-bottom: 60px;
        background: #1D2533;
    }

    .paymentbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0px;
        font-size: 60px;
        font-weight: 800;
        color: #F88503;
        background-color: #363e4b00;
        .paymentimg {
            width: 160px;
            height: 160px;
            background: url('~@/assets/phone/iconres.png') no-repeat center center;
            background-size: 100% 100%;
            margin-bottom: 52px;
        }
    }
    .orderAddres {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 440px;
        padding: 60px;
        font-size: 52px;
        font-weight: 800;
        color: #fff;
        >div:nth-child(1){
            font-size: 68px;
        }
        span{
            font-size: 48px;
            color: #999999;
            margin-right: 25px;
        }
       
    }

    .orderinfo {
        display: flex;
        align-items: center;
        min-height: 520px;
        padding: 36px 40px;
        .orderinfoimg {
            width: 540px;
            height: 450px;
            background: #fff;
            margin-right: 60px;
            border-radius: 30px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cove;
            }
        }
        .orderinfo_Comtent {
            width: 50%;
            .orderinfo_Comtent_Title {
                font-size: 56px;
                font-weight: 800;
                color: #FFFFFF;
                margin-bottom: 50px;
            }
            .orderinfo_gg {
                display: flex;
                flex-wrap: wrap;
                span {
                    padding: 10px 34px;
                    background-color: #303A4B;
                    margin-right: 30px;
                    margin-top: 30px;
                    border-radius: 15px;
                    font-size: 44px;
                    font-weight: 500;
                    color: #999999;
                }

            }
        }

        .orderinfo_num_Price {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .orderinfo_Price {
                font-size: 100px;
                font-weight: 800;
                color: #FF0021;
                span{
                    font-size: 44px;
                }
            }
            .orderinfo_num {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 300px;
                height: 80px;
                background: #303A4B;
                border-radius: 48px;
                border: 1px solid #435065;
                font-size: 44px;
                color: #fff;
                text-align: center;
                >div{
                    flex: 1;
                }
                >div:nth-child(2){
                    border-right: 1px solid #435065;
                    border-left: 1px solid #435065;
                    color: #F88503;
                }
            }

            .orderinfo_resnum {
                font-size: 52px;
                color: #F88503;
            }
        }
    }

    .Priceinfo{
       
        padding: 0px 40px;
        font-size: 56px;
        color: #666666;
        >div{
            display: flex;
            justify-content:space-between;
            align-items: center;
            height: 200px;
        }
        .Priceinfo_num {
            font-weight: 800;
            color: #FF0021;
        }
        .Priceinfo_numm {
            color: #fff;
        }
    }

}

.orderfrom {
    position: fixed;
    left: 0px;
    bottom: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 240px;
    padding: 0px 50px;
    background: #1D2533;
    .orderfrom_zj {
        font-size: 50px;
        font-weight: 800;
        color: #FFFFFF;
        span{
            font-size: 70px;
            color: #FF0021;
        }
    }

    .orderfrom_yh {
        font-size: 44px;
        font-weight: 800;
        color: #CCCCCC;
    }

    .ordebtn{
        width: 440px;
        height: 176px;
        background: #F88503;
        border-radius:176px;
        font-size: 60px;
        font-weight: 800;
        color: #FFFFFF;
        text-align: center;
        line-height: 176px;
    }
}
    
</style>