<template>
    <div class="navbar_box">

        <div class="navdarlist">
            <div 
                class="item" 
                v-for="(item) in navbarlist"
                :key="item.categoryId"
            >
                <treeitem 
                    :item="item"
                    @onClicktree = 'onClicktree'
                ></treeitem>
            </div>
        </div>
        <div class="Additionalitem" @click="goCentre" >
            <div class="icon">
                <i></i>
                <span>{{islog?'个人中心':'登录'}}</span>
            </div>
            <div class="go_icon">
                <i></i>
            </div>
        </div>
    </div>
</template>
<script>
import { getToken } from '@/utils/auth';// get token from cookie
import {getExhibitCategoryLists} from '@/utils/http';
import treeitem from "@/pages/phone/common/treeitem"
export default {
    props:['artId'],
    data() {
        return {
            navbarlist:[],
            activeindex:0,
            islog:false,
        }
    },
    components:{
        treeitem
    },
    created(){
        this.islogin();
        this.getType();
    },
    methods:{
        onClicktree(id){
            this.$emit('Clicktree',id)
        },
        async getType(){
            let {status,data} = await getExhibitCategoryLists({artId:this.artId});
            if(status!==0)return;
            this.navbarlist = data;
        },
        islogin(){
            let token = getToken();
            let usertoken = localStorage.getItem('usernum'); 
            if(token||usertoken){
                this.islog = true;
            }
        },
        onToggleNav(i){
            this.activeindex = i;
        },
        goCentre(){
            if(this.islog){
                this.$router.push({path:'/phone/personagecentre',query:{artId:this.$route.query.artId}})
            }else{
                this.$emit('gologin')
            }
        }
    }
    
}
</script>
<style lang="less" scoped>
.navdarlist {
    width: 300px;
    height: 600px;
    overflow: auto;
    border-radius: 10px;

    .item {
        background: linear-gradient(133deg, #6B6B8B 0%, #2D2D35 100%);
        border-radius: 10px;
        border: 1px solid rgba(255,255,255,0.19);
        margin-bottom: 17px;
        text-align: center;
        line-height: 100px;
        font-size: 34px;
        font-weight: 800;
        color: #FFFFFF;
    }

    // 滚动条宽度
    &::-webkit-scrollbar {
        width: 6px;
    }
    // 滚动条轨道
    &::-webkit-scrollbar-track {
        background: #ccc;
        border-radius: 2px;
    }
    // 小滑块
    &::-webkit-scrollbar-thumb {
        background: #2B2B2B;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #2B2B2B;
    }
}
.Additionalitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 100px;
    margin-top: 30px;
    padding: 0px 25px;
    font-size: 28px;
    font-weight: 800;
    color: #D5A223;
    background: url('~@/assets/phone/bg01.png') no-repeat center center;
    background-size: 100% 100%;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.19);
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            width: 23px;
            height: 25px;
            background: url('~@/assets/phone/icon02.png') no-repeat center center;
            background-size: 100% 100%;
            margin-right: 10px;
        }
    }
    .go_icon {
        width: 9px;
        height: 15px;
        background: url('~@/assets/phone/icon01.png') no-repeat center center;
        background-size: 100% 100%;
    }
}

    
</style>