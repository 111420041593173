var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.asd.apply(null, arguments)
        },
      },
    },
    [
      _c("Menu", { ref: "menu", on: { onMenuid: _vm.onMenuid } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "pull",
              rawName: "v-pull",
              value: _vm.pulldata,
              expression: "pulldata",
            },
          ],
          staticClass: "goodslistbox",
        },
        [
          _c(
            "div",
            { staticClass: "goodslistview" },
            _vm._l(_vm.goodslist, function (item) {
              return _c(
                "div",
                {
                  key: item.goodsId,
                  staticClass: "goodsitem",
                  on: {
                    click: function ($event) {
                      return _vm.goGoodsinfo(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "goodsitemimg" }, [
                    _c("img", { attrs: { src: item.image } }),
                  ]),
                  _c("div", { staticClass: "goodstitle" }, [
                    _c("p", [_vm._v(_vm._s(item.title))]),
                  ]),
                  _c("div", { staticClass: "goodsPrice" }, [
                    _c("p", [
                      _c("i", [_vm._v("￥")]),
                      _vm._v(_vm._s(item.price) + " "),
                      item.isDiscount
                        ? _c("span", [
                            _vm._v(" ￥" + _vm._s(item.originalPrice)),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm.goodslist.length == 0
            ? _c("div", { staticClass: "notdata" }, [
                _c("span", [_vm._v("暂无商品~~")]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }