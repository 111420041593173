var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menuComponents" },
    [
      _c("div", { staticClass: "menubox" }, [
        _c("div", { staticClass: "menuView" }, [
          _c(
            "div",
            { staticClass: "menu_content" },
            _vm._l(_vm.menulist, function (item, index) {
              return _c("MenuItem", {
                key: item.categoryId,
                attrs: { item: item, active: index == _vm.activeIndex },
                on: {
                  activeClcik: function ($event) {
                    return _vm.activeClcik($event, index)
                  },
                  onShowSonMeun: _vm.onShowSonMeun,
                },
              })
            }),
            1
          ),
        ]),
      ]),
      _vm.ispop
        ? _c("PopUpBox", {
            attrs: {
              activeChildren: _vm.activeChildren,
              title: _vm.activeTitle,
            },
            on: { onclearpPop: _vm.onclearpPop, onSureitem: _vm.onSureitem },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }