var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addressList" },
    [
      _vm._l(_vm.addresslist, function (item) {
        return _c(
          "div",
          { key: item.addressId, staticClass: "addressList_item" },
          [
            _c("div", [
              _c("p", [
                _vm._v(_vm._s(item.contact) + " "),
                _c("span"),
                _vm._v(" " + _vm._s(item.telephone)),
              ]),
              _c("p", { staticClass: "addressinfo" }, [
                item.provinceName
                  ? _c("span", [_vm._v(_vm._s(item.provinceName))])
                  : _vm._e(),
                item.cityName
                  ? _c("span", [_vm._v(_vm._s(item.cityName))])
                  : _vm._e(),
                item.districtName
                  ? _c("span", [_vm._v(_vm._s(item.districtName))])
                  : _vm._e(),
                _c("span", [
                  _vm._v(_vm._s(item.content ? item.content : "尚未填写")),
                ]),
              ]),
            ]),
            _c("div", [
              _c("i", {
                class: item.isDefault == 1 ? "activeicon" : "",
                on: {
                  click: function ($event) {
                    return _vm.setdefualtAddress(item)
                  },
                },
              }),
              _c("span", [_vm._v("设为默认地址")]),
            ]),
            _c("div", [
              _c("div", {
                staticClass: "editText",
                on: {
                  click: function ($event) {
                    return _vm.editAddress(item.addressId)
                  },
                },
              }),
              _c("div", {
                staticClass: "clearText",
                on: {
                  click: function ($event) {
                    return _vm.deleteAddress(item)
                  },
                },
              }),
            ]),
          ]
        )
      }),
      _c("div", { staticClass: "addressList_btn" }, [
        _c("button", { on: { click: _vm.addaddress } }, [
          _vm._v("添加收货地址"),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }