<template>
  <div class="wrap">
    <div class="wrap-left">
      <div
        class="item"
        :class="activeTab == index ? 'active' : ''"
        v-for="(item, index) in leftTabs"
        :key="index"
        @click="onTab(index)"
      >
        {{ item }}
      </div>

      <div class="user_center" @click="gosusercenter">
        个人中心
      </div>
    </div>
    <div class="wrap-right">
      <div class="head-banner">
        <div class="swiper swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide item"
              v-for="(item, index) in banner"
              :key="'b_' + index"
            >
              <div class="imgs"><img :src="item" alt="" /></div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div class="tabs">
        <div
          class="tab"
          :class="activetype == index ? 'active' : ''"
          v-for="(item, index) in types"
          :key="'t_' + index"
          @click="onType(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in goodsList"
          :key="index"
          @click="onRoute(item)"
        >
          <img :src="item.image" alt="" class="img" />
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div class="goods-footer">
              <div class="price-new"><i>￥</i>{{ item.price }}</div>
              <div class="price-old" v-if="item.isDiscount">
                ￥{{ item.originalPrice }}
              </div>
              <div class="name">{{ item.companyName }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="paging" v-if="totalPage && goodsList.length">
        <el-pagination
          background
          @current-change="getGoodsListsBy"
          :current-page="page"
          layout="prev, pager, next"
          :page-count="totalPage"
        >
        </el-pagination>
      </div>
      
      <el-empty
        description="暂无数据 ╥﹏╥..."
        v-if="!goodsList.length"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import { 
  getBannerGoods,
  getGoodsListsBy
} from "@/utils/http.js";

import Swiper, { Pagination, Navigation } from "swiper";
Swiper.use([Pagination, Navigation]);
import "swiper/swiper.less";

export default {
  data() {
    return {
      page: 1,
      totalPage: 0,
      activeTab: 0,
      leftTabs: ["热门商品", "新人特惠", "青海联名", "杭州画院"],
      activetype: 0,
      types: ["全部"],
      goodsList: [],
      banner: [],
    };
  },
  created() {
    this.getBanner();
    this.getGoodsListsBy(this.page);
    // this.getGoodsTypes();
  },
  methods: {
    gosusercenter(){
    // 个人中心 
      this.$router.push('/order/userCentre/orderList')
    },
    onTab(i) {
      this.activeTab = i;
    },
    onType(i) {
      this.activetype = i;
      this.getGoodsListsBy(1);
    },
    imgLoad() {
      this.$nextTick(function () {
        new Swiper(".swiper", {
          //loop: true, // 循环模式选项
          slidesPerView: "auto",
          pagination: {
            el: ".swiper-pagination",
          },
          autoplay: {
            delay: 3000,
            disableOnInteraction: false,
          },
          observer: true,
          observeParents: true,
        });
      });
    },
    getBanner() {
      getBannerGoods().then((res) => {
        const banner = res.data;
        this.banner = banner;
        this.imgLoad();
      });
    },
    getGoodsListsBy(page) {
      const data = {
        page: page,
        type: this.activetype,
      };
      getGoodsListsBy(data).then((res) => {
          const { goodsList, totalPage } = res.data;
          this.goodsList = goodsList;
          this.totalPage = totalPage;
      })
      .catch((err) => {
          this.$toast(err.msg);
      });
    },
    onRoute(item) {
      // "1": "人物装扮","2": "数字藏品","3": "实物藏品","4": "房屋地产","5": "装饰模型","6": "玩家道具"
      if(item.type==3){
        this.$router.push(`/shops/physicalGoodsInfo?goodId=${item.goodsId}`);
      }else{
        this.$router.push(`/shops/detail?goodId=${item.goodsId}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  width: 100%;
  height: 100%;
  background: #1d2533;
  .wrap-left {
    min-height: 100vh;
    background: #333c58;
    position: fixed;
    left: 0;
    width: 240px;

    .user_center {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 65px;
      text-align: center;
      line-height: 65px;
      background: #2d2d35;
      color: #d5a223;
      font-size: 26px;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .wrap-right {
    flex: initial;
    padding: 40px 40px 40px 280px;
  }
  /deep/.head-banner {
    position: relative;
    width: 100%;
    height: 400px;
    margin-bottom: 40px;
    background: #1d2c44;
    overflow: hidden;
    .swiper {
      width: 100%;
      .item {
        position: relative;
        .imgs {
          width: 100%;
          img {
            display: block;
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 400px;
          }
        }
        .texts {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 1300px;
          height: 320px;
          margin-top: -160px;
          margin-left: -650px;
          .tag {
            font-size: 20px;
            font-weight: 400;
            color: #c4c4c4;
            line-height: 28px;
          }
          .banner-title {
            font-size: 50px;
            font-weight: bold;
            color: #ffffff;
            margin-top: 40px;
            margin-bottom: 140px;
          }
          .view-more {
            display: block;
            width: 150px;
            height: 50px;
            line-height: 50px;
            background: #ec5c56;
            border-radius: 4px;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
      .swiper-pagination {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        width: 100%;
        padding: 20px 0;
        justify-content: center;
        z-index: 99;
        .swiper-pagination-bullet {
          display: flex;
          width: 12px;
          height: 12px;
          margin: 0 15px;
          background-color: #f00;
          border-radius: 12px;
          z-index: 99;
          box-shadow: 1px 1px 3px #666;
        }
        .swiper-pagination-bullet-active {
          background-color: #fff;
        }
      }
    }
  }
  &-left {
    min-height: 100vh;
    flex: 0 0 250px;
    background: #333c58;
    .item {
      height: 100px;
      line-height: 100px;
      text-align: center;
      background: #2d2d35;
      color: #fff;
      font-size: 26px;
      font-weight: 700;
      border-bottom: 1px solid #494a52;
      cursor: pointer;
      &.active {
        background: #d5a223;
      }
    }
  }
  &-right {
    flex: 0 0 calc(100% - 250px);
    padding: 40px 55px;
    .tabs {
      display: flex;
      margin-bottom: 30px;
      .tab {
        display: flex;
        flex-direction: column;
        line-height: 40px;
        margin: 0 30px 0 0;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
        &.active {
          color: #d5a223;
          border-bottom: 2px solid #d5a223;
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        // width: 300px;
        flex: 0 0 calc(25% - 25px);
        margin-right: 30px;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .img {
          width: 100%;
          height: 240px;
          margin-bottom: 10px;
        }
        .info {
          padding: 10px 0 30px;
          .title {
            margin-bottom: 6px;
            font-size: 18px;
            font-weight: 700;
            overflow: hidden;
            white-space: wrap;
            text-overflow: ellipsis;
          }
        }
        .goods-footer {
          display: flex;
          align-items: center;
          width: 100%;
          .price-new {
            margin-right: 10px;
            font-size: 20px;
            color: #ff0000;
            font-weight: 700;
            i {
              font-size: 12px;
              font-style: initial;
            }
          }
          .price-old {
            color: #959595;
            font-size: 14px;
            text-decoration: line-through;
          }
          .name {
            font-size: 14px;
            color: #d5a223;
            margin-left: auto;
          }
        }
      }
    }
    ::v-deep.el-empty {
      margin-top: 120px;
    }
    ::v-deep.paging {
      width: 100%;
      margin-top: 20px;
      .el-pagination {
        text-align: center;
      }
      .el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      .el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      .btn-next,
      .btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
  }
}
</style>
