import { render, staticRenderFns } from "./returnnum.vue?vue&type=template&id=1eb43db2&scoped=true"
import script from "./returnnum.vue?vue&type=script&lang=js"
export * from "./returnnum.vue?vue&type=script&lang=js"
import style0 from "./returnnum.vue?vue&type=style&index=0&id=1eb43db2&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eb43db2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\公司项目\\银山\\元宇宙\\MetaverseMall\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1eb43db2')) {
      api.createRecord('1eb43db2', component.options)
    } else {
      api.reload('1eb43db2', component.options)
    }
    module.hot.accept("./returnnum.vue?vue&type=template&id=1eb43db2&scoped=true", function () {
      api.rerender('1eb43db2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/verticalphone/goods/returnnum.vue"
export default component.exports