var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bigbox w" }, [
    _c("div", { staticClass: "menu" }, [
      _c(
        "ul",
        _vm._l(_vm.menuArr, function (item) {
          return _c(
            "li",
            { key: item.path, class: item.active == 1 ? "activeItem" : "" },
            [
              _c("router-link", { attrs: { to: item.path } }, [
                _c("span", [_vm._v(_vm._s(item.name))]),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "body_main" }, [_c("router-view")], 1),
    _c("div", { staticClass: "goback", on: { click: _vm.goback } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }