<template>
    <div class="addresinp_view">
      <div class="addresinp_view_title">
        <span>退换货</span>
        <div class="backbtn" @click="$router.back()"></div>
      </div>
  
      <div class="addresinp_view_form">
  
        <div class="addresinp_view_row center">
          <div><p>请将需要退换的货物邮递到此地址</p></div>
        </div>
  
        <div  class="addresinp_view_row center">
          <div class="addresinfo">
            <div class="addresinfo_row">
              <div>
                <p><span>收货人s：</span></p>
                <p>{{ userAddressInfo.contact }}</p>
              </div>

              <div>
                <p><span>电话：</span></p>
                <p>{{ userAddressInfo.telephone }}</p>
              </div>
           
              <div>
                <p><span>收货地址：</span></p>
                <p>{{ userAddressInfo.addressContent }}</p>
              </div>

            </div>
          </div>
        </div>
  
        <div class="addresinp_view_row center">
          <div class="oddNumber">
            <div>快递单号：</div>
            <input type="text" placeholder="点击此处输入快递单号" v-model="expressNumber">
          </div>
  
        </div>
  
        <div class="addresinp_view_row gap center">
          <button @click="submitfrom">提交申请</button>
        </div>
  
      </div>
  
    </div>
  </template>
  <script>
  import { returnGoodsAddExpressNumber } from "@/utils/http.js"

  export default {
    data() {
      return {
        userAddressInfo:{},
        expressNumber:''
      }
    },
    created(){
      this.getGoodsAddExpressNumber()
    },
    methods: {
      submitfrom(){
          if(!this.expressNumber)return this.$message({message: '请输入快递单号',type: 'warning'});
          let data = {
            orderId:this.$route.query.orderId,
            expressNumber:this.expressNumber
          };
          returnGoodsAddExpressNumber(data).then((res)=>{
            if(res.status==0){
              this.$message({message: '提交成功',type: 'success'})
            }
          });
      },
      // 获取地址
      async getGoodsAddExpressNumber(){
        let param = {
          orderId:this.$route.query.orderId,
        };

        let res = await returnGoodsAddExpressNumber(param);
        if(res.status==0){
          this.userAddressInfo = res.data;
        }
      }

      
    },
    
  }
  </script>
  <style lang="less" scoped>
  .addresinp_view {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1410px;
    background: linear-gradient(135deg, #365F78 0%, #384E62 100%);
    border-radius: 30px;
    border: 2px solid rgba(255,255,255,0.2);
    overflow: hidden;

    .addresinp_view_title {
      position: relative;
      height: 100px;
      line-height: 100px;
      font-size: 30px;
      font-weight: 800;
      background: #3F4C67;
      color: #FFFFFF;
      text-align: center;    
      .backbtn{
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        width: 60px;
        height: 60px;
        background: url('~@/assets/phone/icon04.png') no-repeat center center;
        background-size: 100% 100%;
      }
    }
  
    .addresinp_view_form {
      padding: 49px 39px 0px;
  
      .addresinp_view_row {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 40px;
        .inputbox {
          display: flex;
          justify-content: center;
          align-items: center;
          >div {
            margin-right: 18px;
          }
          >div:last-child {
            margin-right: 0px;
          }
          .xxdz {
            width: 120px;
          }
  
          textarea {
            width: 795px;
            height: 192px;
            background: #3A3A3A;
            border-radius: 8px;
            border: 1px solid #666666;
            padding: 20px;
            resize:none;
            font-size: 14px;
            color: #fff;
            
            &::placeholder{
              font-size: 16px;
              color: #666666;
            }
            // 滚动条宽度
            &::-webkit-scrollbar {
                width: 6px;
            }
            // 滚动条轨道
            &::-webkit-scrollbar-track {
                background: #ccc;
                border-radius: 2px;
            }
            // 小滑块
            &::-webkit-scrollbar-thumb {
                background: #2B2B2B;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: #2B2B2B;
            }
          }
  
        }
        .inpwidth {
          width: 450px;
        }
  
        .addresinfo {
          width: 1190px;
          background: #2E495E;
          border-radius: 16px;
          .addresinfo_row {
            display: flex;
            justify-content: space-around;
            align-items: center;
            min-height: 160px;

            >div {
              display: flex;
              justify-content: center;
              flex-direction: column;
              font-weight: bold;
              font-size: 30px;
              color: #FFFFFF;
              p {
                margin: 5px;
                span {
                  font-size: 24px;
                  color: #999999;
                }
              }
            }
          }
        }
  
        .oddNumber {
          div {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            margin-bottom: 15px;
          }
          input {
            width: 1190px;
            height: 88px;
            background: #3A3A3A;
            border-radius: 8px;
            border: 1px solid #666666;
            font-size: 24px;
            font-weight: 500;
            text-indent: 30px;
            color: #ffffff;
            &::placeholder{
              font-size: 24px;
              font-weight: 500;
              text-indent: 30px;
              color: #666666;
            }
          }
        }
  
        button {
          width: 200px;
          height: 80px;
          background: #D5A223;
          border-radius: 40px ;
          font-size: 34px;
          font-weight: 800;
          color: #FFFFFF;
          text-align: center;
          line-height: 80px;
          border: none;
        }
  
        
      }
  
      .center {
        justify-content: center;
      }
  
      .gap {
        .inputbox {
          margin-right: 50px;
        }
        .inputbox:last-child {
          margin-right: 0px;
        }
  
      }
    }
  }
    
  </style>